import { PropsWithChildren } from "react"


export default (props: PropsWithChildren<{}>) => {

    return (
        <div style={{height: "100vh"}}>
            {props.children}
        </div>
    )

}