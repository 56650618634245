import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Env from './Env';
import axios from 'axios';
import AntdSchemeFilter from './pages1/components/AntdSchemeFilter';


const gateway = process.env.REACT_APP_API_BASE_URL
if(Env.DEBUG){
  console.log("======env======")
  console.log("gateway: ", gateway)
  console.log("======env======")
}
axios.defaults.baseURL = gateway
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <AntdSchemeFilter>
    <GoogleOAuthProvider clientId="1080680356435-mal4o9mnh2qbb4r6phc9kripl8tvc8ms.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </AntdSchemeFilter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
