import { Flex } from "antd"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import FormValidators from "../../components/form/FormValidators"
import { StepForm } from "../CaseFillingForm"
import { useContext, useEffect } from "react"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import CaseFillingApi from "../../api/CaseFilling.api"
import Env from "../../../Env"

const applications = [
    "Animal Science and Veterinary",
    "Biology and Biochemistry",
    "Business and Management",
    "Chemistry",
    "Computer Science",
    "Earth Science",
    "Ecology and Evolution",
    "Economics and Finance",
    "Electronics and Electrical Engineering",
    "Engineering and Technology",
    "Environmental Sciences",
    "Genetics",
    "Genetics and Molecular Biology",
    "Immunology",
    "Law",
    "Law and Political Science",
    "Materials Science",
    "Mathematics",
    "Mechanical and Aerospace Engineering",
    "Medicine",
    "Microbiology",
    "Molecular Biology",
    "Neuroscience",
    "Physics",
    "Plant Science and Agronomy",
    "Political Science",
    "Psychology",
    "Social Sciences and Humanities"
]

const ApplicationOptions = applications.map(it => {return {label: it, value: it}})

export default () => {



    return (
        <StepForm
            title="Field of application"
            subtitle="Please select the field of application below, ensuring that it is relevant to your client's background."
            required
        >
            <FieldOfApplication/>
        </StepForm>
    )
}

const FieldOfApplication = () => {

    const formInstance = useFormInstance({autoValidate: false})
    const {clientCase} = useContext(ClientCaseContext)
    const {record, update, setStep, isFinish, setDisable} = useContext(CaseFillingFormContext)

    useEffect(() => {
        formInstance.setInitial({'field': record?.field_application})
        if(!record?.field_application || record?.field_application === ""){
            setDisable(CaseFilingSteps.FieldOfApplication, true)
        }
    }, [])

    const doSubmit = async (form: any) => {
        const caseId = clientCase?.case_id as string
        const taskId = record?.id as string
        return CaseFillingApi.updateFieldOfApplication(caseId, taskId, form).then(async () => {
            CaseFillingApi.updateStatus(caseId, taskId, "FIELD_OF_APPLICATION")
            update(await CaseFillingApi.detail(caseId, taskId))
            setStep(CaseFilingSteps.AcademicBackground)
            setDisable(CaseFilingSteps.FieldOfApplication, undefined)
        })
    }


    return (
        <Form.Form instance={formInstance} showError={false} onSubmit={doSubmit}>
            <Form.Item 
                label="Field of application" 
                name="field" 
                required 
                rules={[{validate: FormValidators.required, msg: "Application_field is required"}]}
            >
                <Form.Select placeholder="Select one" options={ApplicationOptions} showSearch readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)} />
            </Form.Item>
            <Form.Item nowrap label="" name="">
                <Flex justify="flex-end">
                    <Form.Submit width={185} disabled={isFinish(CaseFilingSteps.ReviewAndSubmit)}>Continue</Form.Submit>
                </Flex>
            </Form.Item>
        </Form.Form>
    )
}

