import axios from "axios"

export interface PublicationDTO{
    id: string
    type: string
    title: string
    journalConferenceName:string
    year: string
    authorRanking: string
    source: string
    clientProfileInfoId: string
    userId: string
  }

  const publications = async (taskId: string) => {

    const operation = `
    query GetPublications($caseFilingTaskId: ID!) {
      getPublications(caseFilingTaskId: $caseFilingTaskId) {
        id
        type
        title
        journalConferenceName
        year
        authorRanking
        source
        clientProfileInfoId
        userId
      }
    }
    `
    const variables = {
      caseFilingTaskId: taskId
    }
  
    return (await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
    )).data?.['data']?.['getPublications'] as PublicationDTO[]
  
  }
  
  const updatePublications = async (taskId: string, request: {created?: PublicationDTO[], updated?: PublicationDTO[], deleted?: string[]}) => {
    if(request.created){
      for(let item of request.created){
        await createPublication(taskId, item)
      }
    }
    if(request.deleted){
      for(let item of request.deleted){
        await deletePublication(item)
      }
    }
    
    if(request.updated){
      for(let item of request.updated){
        await editPublication(item)
      }
    }
  }
  
  const createPublication = async (taskId: string, request: PublicationDTO) => {
    const operation = `
    mutation CreatePublications($publicationInputs: [PublicationInput!]!, $caseFilingTaskId: ID!) {
    createPublications(publicationInputs: $publicationInputs, caseFilingTaskId: $caseFilingTaskId) {
      id
      type
      title
      journalConferenceName
      year
      authorRanking
      source
      clientProfileInfoId
      userId
    }
  }
    `
    const variables = {
      "publicationInputs": [{
        "type": request.type,
        "title": request.title,
        "journalConferenceName": request.journalConferenceName,
        "year": request.year,
        "authorRanking": request.authorRanking,
      }],
      "caseFilingTaskId": taskId
    }
  
    return (await axios.post(
        `/api/cases/case_filing/graphql`,
        {
          query: operation,
          variables,
          caseFilingTaskId: taskId
        }
    )).data.data['createPublications'][0]
  
  }
  
  const deletePublication = async (id: string) => {
    const operation = `
    mutation DeletePublication($publicationId: ID!) {
      deletePublication(publicationId: $publicationId)
    }
    `
    const variables = {
      publicationId: id
    }
  
    await axios.post(
        `/api/cases/case_filing/graphql`,
        {
          query: operation,
          variables
        }
    )
  
  }
  
  const editPublication = async (request: PublicationDTO) => {
  
    const operation = `
    mutation EditPublication($publicationInput: PublicationInput!, $publicationId: ID!) {
      editPublication(publicationInput: $publicationInput, publicationId: $publicationId) {
        id
        type
        title
        journalConferenceName
        year
        authorRanking
        source
        clientProfileInfoId
        userId
      }
    }
    `
  
    const variables = {
      "publicationInput": {
        "type": request.type,
        "title": request.title,
        "journalConferenceName": request.journalConferenceName,
        "year": request.year,
        "authorRanking": request.authorRanking,
      },
      "publicationId": request.id
    }
  
    await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )
  
  }

  export default {
    publications,
    updatePublications,
    createPublication,
    deletePublication,
    editPublication
}