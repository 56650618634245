import { ReactNode, useState } from "react"

import styles from "./drawer.module.scss";
import { Drawer, DrawerProps, Flex } from "antd";


export const useDrawer = (props: DrawerProps) => {

    const [open, setOpen] = useState<boolean>(false)
    const [children, setChildren] = useState<ReactNode>()

    const customRender = (node: ReactNode) => {
        return (
            <div className={styles['drawer']}>
                <Flex align="center" className={styles["drawer__container"]}>
                    {node}
                </Flex>
            </div>
        )
    }

    const render = (
        <Drawer
            {...props}
            onClose={
                async (e) => {
                    props.onClose && await props.onClose(e)
                    setOpen(false)
                }
            }
            open={open}
            destroyOnClose={true}
            closeIcon={null}
            styles={{
                body: {padding: 0}
            }}
            drawerRender={customRender}
            
        >
            {children}
        </Drawer>
    )

    const show = (children: ReactNode) => {
        setChildren(children)
        setOpen(true)
    }
    const close = () => {
        setOpen(false)
        setChildren(undefined)
    }


    return {
        render,
        show,
        close
    }

}