import { ChatSessionType, ChatbotWholeMessage as ChatbotWholeMessage, ChatbotMessageType, ChatbotStream, ChatbotStreamMessage, ChatMessageFollowupAction } from "./ChatbotContext";
// import { message } from "antd";
import Env from "../../Env";
import axios from "axios";
// import apiClient from "../../utils/axiosConfig";
const apiClient = axios

interface ShortcutData{
    title: string
    description: string
    action: ChatMessageFollowupAction
}

const shortcuts: ShortcutData[] = [
    {
        title: "USCIS Visa Bulletin",
        description: "Up-to-date VISA Bulletin",
        action: "prompt"
      },
      {
        title: "USCIS Case Status",
        description: "Check filed case status",
        action: "prompt"
      },
      {
        title: "Quick Case Evaluation",
        description: "Quick NIW/EB1A evaluation",
        action: "gotoprofile"
      },
      {
        title: "Background Analysis",
        description: "Complete case analysis",
        action: "gotoprofile"
      },
]

export const chat = async (params: any) => {
    try {
      const response = await apiClient.post(`/api/chat`, params);
      return response.data;
    } catch (error) {
      // Handle error or throw it to be caught by the action
      throw error;
    }
  };

export interface ChatRequest{
  query?: string
  chat_session_id?: string
  resource_id?: number
  resource_type?: string
  action?: string
  chat_type?: string
}

export type ChatRequestKey = keyof ChatRequest 

const chatStream = (params: Record<string, any>) => {

  let complete = false
  let currentLines = 0
  const messages: ChatbotStreamMessage[] = []
  
  new Promise((resolve, reject) => {
    const formData = new FormData();
    for(let key in params){
      formData.append(key, (params as any)[key])
    }
    apiClient({
        url: '/api/chat/streaming',
        data: formData,
        method: 'POST',
        onDownloadProgress: progressEvent => {
           const xhr = progressEvent.event.target
           const { responseText } = xhr
          //  console.log("=====responseText======")
           if(responseText && responseText !== ""){
            const lines = responseText.split("\n")
            for(let line of lines.slice(currentLines)){
              if(line && line !==  ""){
                const message = line.slice(6)
                try{
                  //split for render
                  const msgObj = JSON.parse(message)
                  if(msgObj["content_type"] === "llm_chunk"){
                    for(let token of msgObj["content"]){
                      messages.push({...msgObj, content: token})
                    }  
                    // messages.push({...msgObj, content: msgObj["content"]})
                  }else{
                    Env.DEBUG && console.log("receive stream message: ", msgObj)
                    messages.push(msgObj)
                  }
                  
                }catch(e){
                  console.error("message解析异常, msg = ", message)
                }
              }
            }
            currentLines = (lines.length - 1)
          }
        },
      }).then(({ data }) => {
        // console.log("===data===")
        // console.log(data)
        if(data && data !== ""){
          // const message = data.slice(6)
          // messages.push(JSON.parse(message))
          console.log("completed")
          // console.log("messages: %o", messages)
          resolve(true)
        }
      }).catch(e => reject(e))
  }).then(() => complete = true)

  const stream = (onMessage: (message: ChatbotStreamMessage) => void, interval: number = 1000 / 60) => {
    let cursor = 0
    const scheduleId = setInterval(() => {
      // console.log(`cursor: ${cursor}, total: ${messages.length}`)
      if(cursor < messages.length){
        onMessage(messages[cursor++])
      }else{
        if(complete){
          clearInterval(scheduleId)
        }
      }
    }, interval)
  }

  const getMessageType = (interval: number = 1000 / 10): Promise<ChatbotMessageType> => {
    return new Promise((resolve, reject) => {
      const scheduleId = setInterval(() => {
        if(messages[0]){
          clearInterval(scheduleId)
          resolve(messages[0]['message_type'])  
        }
      }, interval)
    })    
  }

  const getWholeMessage = (interval: number = 1000 / 10): Promise<ChatbotWholeMessage> => {
    return new Promise((resolve, reject) => {
      const scheduleId = setInterval(() => {
        if(complete){
          clearInterval(scheduleId)
          const wholeMessage = messages.find(msg => msg.data_category === "whole_message")?.content as ChatbotWholeMessage
          resolve(wholeMessage)  
        }
      }, interval)
    })    
  }

  const getTitleGenerationMessage = (interval: number = 1000 / 10): Promise<ChatbotStreamMessage|undefined> => {
    return new Promise((resolve, reject) => {
      const scheduleId = setInterval(() => {
        const streamMsg = messages.find(msg => msg.data_category === "title_generation")
        if(streamMsg){
          clearInterval(scheduleId)
          resolve(streamMsg)  
        }
      }, interval)
      
    })    
  }

  return {
    getMessageType,
    stream,
    getWholeMessage,
    getTitleGenerationMessage
  }
      
}

export const html = async (url: string) => {
  try {
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    // Handle error or throw it to be caught by the action
    throw error;
  }
};

export interface ChatSessionDTO{
  title: string
  id: string
  newSession?: boolean
  newSessionToken?: string
}

/**
 * get chat history
 * @param {*} params { page: pageNo, pageSize: 30 }
 * @returns
 */
export const getSessions = async (request: {page: number, limit: number} = {page: 1, limit: 30}): Promise<ChatSessionDTO[]> => {
  const response = await apiClient.get(`/api/chats`, {
    params: { ...request },
  });
  //TODO 成功判断
  return response.data["data"]?.["chat_sessions"]??[];
};

/**
 * delete chat
 * @param {*} params { chat_session_id: 'chat_session_id' }
 * @returns
 */
export const deleteSession = async (request: {chat_session_id: string}) => {
  const response = await apiClient.delete(
    `/api/chats/${request.chat_session_id}`,
  );
  return response.data;

};

/**
 * update chat title
 * @param {*} params { chat_session_id: 'chat_session_id', title: 'title' }
 * @returns
 */
export const updateSession = async (request: {chat_session_id: string, session_title: string}) => {
  const response = await apiClient.patch(
    `/api/chats/${request.chat_session_id}`,
    request
  );
  return response.data;
};

/**
 *
 * @param {*} params {chat_session_id: 'chat_session_id', page: pageNo, pageSize: 30}
 * @returns
 */
export const getAllChatsInOneSession = async (request: {chat_session_id: string, page_size?: number, page_token?: string}): Promise<ChatbotWholeMessage> => {
  const response = await apiClient.get(
    `/api/chats/${request.chat_session_id}/messages`
  );
  return response.data?.["data"];
};

/**
 *
 * @param {*} params chat_session_id
 * @returns
 */
export const generateTitle = async (chat_session_id: string) => {
  const response = await apiClient.patch(
    `/api/chats/${chat_session_id}/generate_title`
  );
  return response.data;
};

export const msgFeedback = async (sessionId: string, msgId: string, feedback: "up"|"down") => {
  const response = await apiClient.patch(
    `/api/chats/${sessionId}/messages/${msgId}`,
    {
      feedback: feedback,
    }
  );
  return response.data;
};

export default {
    shortcuts,
    chat,
    chatStream,
    html,
    getChatHistory: getSessions,
    deleteSession,
    updateSession,
    getAllChatsInOneSession,
    generateTitle,
    msgFeedback,
}