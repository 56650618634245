import { lazy, PropsWithChildren, ReactNode, useContext, useEffect, useState } from "react"

import styles from "./Form.module.scss";
import { Affix, DatePicker, DatePickerProps, Flex, Input, Select, SelectProps, InputProps as AntdInputProps, InputNumber, InputNumberProps } from "antd";
import Icon from "../icon/Icon";
import errorSvg from "./assets/images/error.svg";
import { FormContext, FormContextData, FormItemContext, FormItemValidateRule } from "./FormContext";
import Env from "../../../Env";
import Button, { ButtonProps } from "../Button";
import dayjs from "dayjs";
import { TextAreaProps } from "antd/es/input";

export interface FormProps{
    instance: FormContextData
    trigger?: "onChange"
    onSubmit?: (values: any) => Promise<any> | void
    initial?: any
    setShowError?: (showError: boolean) => void
    showError?: boolean
}

export interface FormItemProps{
    label: string | ReactNode
    name: string
    rules?: FormItemValidateRule[]
    nowrap?: boolean
    tips?: ReactNode
    required?: boolean
    hiddenError?: boolean
    lazy?: boolean
}

export type InputProps = {

} & AntdInputProps

export type CheckboxProps = {
    // label: string
    // name: string
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

// export type SelectProps = {

// } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Form = (props: PropsWithChildren<FormProps>) => {

    const {
        values,
        validateAll,
        setInitial,
        setShowError,
        loading,
        setLoading
    } = props.instance
    const {
        showError = true
    } = props

    const submit = async () => {
        console.log("do submit")
        try{
            await validateAll();
            Env.DEBUG && console.log("form validateAll")
            if(!loading && props.onSubmit){
                setLoading(true)
                try{
                    await props.onSubmit?.(values)
                }finally{
                    setLoading(false)
                }
            }
        }catch(e){
            console.error("", e)
        }
    }

    useEffect(() => {
        props.initial && setInitial(props.initial)
        setShowError(showError)
    }, [])


    return (
        <FormContext.Provider value={{...props.instance, showError, submit}}>
            <form onKeyDown={(e) => {if(e.keyCode==13){e.stopPropagation();e.preventDefault();submit();return false}}}>
                <div>
                    {props.children}
                </div>
            </form>
        </FormContext.Provider>
    )
}

const ItemGroup = (props: PropsWithChildren<{title: ReactNode}>) => {
    
    return (
        <Flex vertical gap={16}>
            <>
                <div className={styles['form__itemgroup__title']}>
                    {
                        props.title
                    }
                </div>
                {props.children}
            </>
        </Flex>
    )
}

const Item = (props: PropsWithChildren<FormItemProps>) => {

    const {errors = {}, setRules, showError} = useContext(FormContext)

    const {
        hiddenError
    } = props

    useEffect(() => {
        if(props.rules) setRules(props.name, props.rules)
    }, [])

    return (
        <FormItemContext.Provider value={{name: props.name}}>
            {
                !props.nowrap
                ?
                <Flex vertical gap={6}>
                    <div className={styles['form__item__label']}>
                        {props.label}
                        {props.required && <span className={styles['form__item__label_required']}>*</span>}
                    </div>
                    {props.children}
                    {
                        !hiddenError && <div className={styles['form__item__error']}>{showError?errors[props.name]:''}</div>
                    }
                </Flex>
                :
                props.children
            }
        </FormItemContext.Provider>
    )
    
}

const _Input = (props: InputProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)
    const [_value, setValue] = useState<string>()

    const onChange = (value: string) => {
        // setValues(name, value)
        setValue(value)
    }

    const onBlur = () => {
        // validate(name)
        setValues(name, _value, true)
    }

    useEffect(() => {
        setValue(values[name])
    }, [values])

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <Input
            {...props} 
            className={[
                styles['form__input'], 
                errors[name]?styles['form__input_error']:'', 
                props.disabled?styles['form__input_disabled']:'', 
                props.readOnly?styles['form__input_readOnly']:''
            ].join(" ")}
            value={_value}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            status={errors[name]?"error":undefined}
            ></Input>
            {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            }
        </Flex>
    )
}

const _TextArea = (props: TextAreaProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)

    const onChange = (value: string) => {
        setValues(name, value)
    }

    const onBlur = () => {
        validate(name)
    }

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <Input.TextArea
            {...props} 
            className={[styles['form__input']].join(" ")}
            value={values[name]??""}
            onChange={(e) => onChange(e.target.value)}
            onBlur={onBlur}
            status={errors[name]?"error":undefined}
            rows={4}
            ></Input.TextArea>
            {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            }
        </Flex>
    )
}

const _InputNumber = (props: InputNumberProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)
    const {
        defaultValue
    } = props

    const onChange:InputNumberProps['onChange']  = (value) => {
        setValues(name, value)
    }

    const onBlur = () => {
        validate(name)
    }

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <InputNumber
            {...props} 
            className={[styles['form__input'], styles['form__input_number']].join(" ")}
            value={values[name]??defaultValue}
            onChange={(val) => onChange(val)}
            onBlur={onBlur}
            status={errors[name]?"error":undefined}
            ></InputNumber>
            {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            }
        </Flex>
    )
}

const Password = (props: InputProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)
    const [_value, setValue] = useState<string>()

    const onChange = (value: string) => {
        // setValues(name, value)
        setValue(value)
    }

    const onBlur = () => {
        // validate(name)
        setValues(name, _value, true)
    }

    useEffect(() => {
        setValue(values[name])
    }, [values])

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <Input.Password 
                {...props} 
                className={[styles['form__input']].join(" ")}
                value={_value}
                onChange={(e) => onChange(e.target.value)}
                onBlur={onBlur}
                status={errors[name]?"error":undefined}
            ></Input.Password>
            {/* {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            } */}
        </Flex>
    )
}

export type CustomSelectProps = {
    readOnly?: boolean
} & SelectProps

const _Select = (props: CustomSelectProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)

    const onChange = (value: string) => {
        setValues(name, value)
    }

    const onBlur = () => {
        validate(name)
    }

    if(props.readOnly){
        return <_Input readOnly />
    }

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <Select
                className={[styles['form__select']].join(" ")}
                value={values[name]??undefined}
                onChange={(e) => onChange(e)}
                onBlur={onBlur}
                status={errors[name]?'error':undefined}
                {...props} 
            ></Select>
            {/* {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            } */}
        </Flex>
    )
}

const Checkbox = (props: CheckboxProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)

    const onChange = (value: number) => {
        setValues(name, value, true)
    }

    const _props = {...props, children: undefined}

    return (
        <Flex align="flex-start" gap={8} className={styles['form__checkbox']} > 
            <input 
                {..._props}
                type="checkbox" 
                className={styles['form__checkbox__btn']}
                value={values[name]??0}
                onChange={(e) => onChange(e.target.checked?1:0)}
                
            >
            </input>
            <div className={styles['form__checkbox__label']}>{props.children}</div>
        </Flex>
    )
}

const _DatePicker = (props: DatePickerProps) => {

    const {values = {}, errors = {}, setValues, validate} = useContext(FormContext)
    const {name} = useContext(FormItemContext)

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        Env.DEBUG && console.log("datepicker onChange: ", dateString)
        setValues(name, date?dateString:undefined, true)
      };

    const onBlur = () => {
        validate(name)
    }

    if(props.readOnly){
        return <_Input readOnly/>
    }

    return (
        <Flex align="center" style={{position: "relative", flex: 1}}>
            <DatePicker
                className={[styles['form__select']].join(" ")}
                value={(values[name] && values[name] !== '')?dayjs(values[name]):undefined}
                onChange={onChange}
                // onBlur={onBlur}
                status={errors[name]?'error':undefined}
                {...props} 
            ></DatePicker>
            {/* {
                errors[name] 
                &&
                <Affix style={{ position: 'absolute', right: 14 }}>
                    <Icon src={errorSvg} size={16}></Icon>
                </Affix> 
            } */}
        </Flex>
    )
}

const Submit = (props: PropsWithChildren<ButtonProps>) => {
    
    const {
        values = {}, 
        errors = {}, 
        setValues, 
        setRules, 
        disabled, 
        submit, 
        loading, 
        setLoading
    } = useContext(FormContext)

    const _submit = async () => {
        submit()
    }

    return (
        <Button.Primary 
            {...props}
            disabled={disabled || props.disabled}
            onSubmit={_submit}
            onClick={_submit}
            loading={loading}
        >{props.children}</Button.Primary>
    )
}

export default {
    Form,
    Item,
    ItemGroup,
    Input: _Input,
    Password,
    Checkbox,
    Submit,
    Select: _Select,
    DatePicker: _DatePicker,
    TextArea: _TextArea,
    InputNumber: _InputNumber
}

