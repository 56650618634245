import { createContext, useCallback, useEffect, useState } from "react";
import Env from "../../../Env";
import { TableDataLoader, TableDataLoaderParams } from "./Table";




export interface TableContextType{
    expandIdx: number
    toggleExpandIdx: (idx: number) => void
    data: any[]
    setDataLoader: (dataloader: TableDataLoader) => void
    refresh: () => void
    loading: boolean
    setLoading: (laoding: boolean) => void
}

export const TableContext = createContext<TableContextType>({
    data: [],
    expandIdx: -1,
    toggleExpandIdx: function (idx: any): void {
        throw new Error("Function not implemented.");
    },
    setDataLoader: function (dataloader: TableDataLoader): Promise<any[]> {
        throw new Error("Function not implemented.");
    },
    refresh: function (): void {
        throw new Error("Function not implemented.");
    },
    loading: false,
    setLoading: function (laoding: boolean): void {
        throw new Error("Function not implemented.");
    }
})


export const useTableInstance = (): TableContextType => {

    const [expandIdx, setExpandIdx] = useState<number>(-1)
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    let dataLoader: TableDataLoader

    const toggleExpandIdx = (idx: number) => {
        
        setExpandIdx(current => {
            if(current == idx) return -1;
            return idx;
        })
    }

    const setDataLoader = (_dataLoader: TableDataLoader) => {
        dataLoader = async (params: TableDataLoaderParams) => {
            setLoading(true)
            try{
                return await _dataLoader(params);
            }finally{
                setLoading(false)
            }
        }
    }

    const refresh = () => {
        dataLoader({page: 1, pageSize: 100}).then(setData)
    }

    useEffect(() => {
        Env.DEBUG && console.log("expandIdx change, expandIdx: ", expandIdx);
    }, [expandIdx])

    return {
        data,
        expandIdx,
        toggleExpandIdx,
        setDataLoader,
        refresh,
        loading,
        setLoading
    }

}