import { CSSProperties, PropsWithChildren, ReactNode, useContext, useEffect, useState } from "react"
import styles from "./Table.module.scss";
import { TableContext, TableContextType } from "./TableContext";
import { Flex } from "antd";
import LoadingIcon from "../icon/LoadingIcon";
import Env from "../../../Env";

export interface TableProps<T = any>{
    instance: TableContextType
    cols: TableColumnProps[]
    emptyRender: ReactNode
    dataLoader: TableDataLoader<T>
    expandRender?: (row: any, idx: number) => ReactNode
    expandable?: boolean
    onRowClick?: (row: any, recordIdx: number) => void
    caption?: ReactNode
    showHeader?: boolean
}

export interface TableColumnProps<T = any>{
    label?: string
    name: string
    render?: (record: T, value: any, idx: number) => ReactNode
    width?: string|number
}

export interface TableDataLoaderParams{
    page: number
    pageSize: number
}

export type TableDataLoader<T = any> = (params: TableDataLoaderParams) => Promise<T[]>


export default (props: TableProps) => {
    props.instance.setDataLoader(props.dataLoader)
    return (
        <TableContext.Provider value={props.instance}>
            <InnerTable {...props} />
        </TableContext.Provider>
    )
}

export const InnerTable = (props: TableProps) => {
    let {
        cols,
        showHeader = true,
        caption
    } = props

    const tableStyles: CSSProperties = {
        width: "100%"
    }
    const columns = props.cols.length
    const {expandIdx, data, loading} = useContext(TableContext)    

    const isEmpty = () => !loading && (!data || data.length == 0)

    const theadCellRender = (col: TableColumnProps, idx: number) => {

        const columnProps = props.cols[idx]
        const {
            width
        } = columnProps
        const _styles: CSSProperties = {}
        if(width){_styles["width"] = width}

        return (
            <th key={idx} style={_styles}>{col.label}</th>
        )
    }

    const tableRowRender = (record: any, recordIdx: number) => {

        return (
            <>
                <tr onClick={() => props.onRowClick?.(record, recordIdx)}>
                    {
                        cols.map((col, idx) => tbodyCellRender(record, record[col.name], idx, recordIdx))
                    }
                </tr>
                {
                    props.expandable
                    &&
                    expandIdx == recordIdx
                    &&
                    props.expandRender?.(record, recordIdx) 
                }
            </>
        )
    }

    const tbodyCellRender = (record: any, value: any, idx: number, recordIdx: number) => {

        const columnProps = props.cols[idx]
        // const {
        //     flex = true
        // } = columnProps
        const render = columnProps.render??tbodyCellTextRender
        const styles: CSSProperties = {
            // flex: flex?"1":"0"
        }

        return (
            <td key={idx} style={styles}>{render(record, value, recordIdx)}</td>
        )
    }

    const tbodyCellTextRender: NonNullable<TableColumnProps['render']> = (record: any, value: any, idx: number) => {
        return (
            <div className="table__cell_text">{value}</div>
        )
    } 

    useEffect(() => {
        // props.dataLoader({page: 1, pageSize: 10}).then(setData)
        // Env.DEBUG && console.log("loading changed: ", loading)
    }, [])

    return (
        <table className={[styles['table'], caption?styles['table_with-caption']:''].join(" ")} style={tableStyles}>
            {
                props.caption &&
                <caption>
                 {props.caption}
                </caption>
            }
            <thead>
                {/* {
                    caption
                    &&
                    <tr>
                        <td colSpan={columns}>
                            {props.caption}
                        </td>
                    </tr>
                } */}
                {
                    showHeader
                    &&
                    <tr>
                        {cols.map((col, idx) => theadCellRender(col, idx))}
                    </tr>
                }
            </thead>
            <tbody>
                {data.map((record, idx) => tableRowRender(record, idx))}
                {
                    loading
                    &&
                    <td colSpan={columns}>
                        <LoadingIcon/>
                    </td>
                }
                {
                isEmpty()
                && 
                <tr className={styles['table__row_footer']}>
                    <td colSpan={columns}>
                        <Flex align="center" className={styles['table__row_empty']}>
                            {props.emptyRender}
                        </Flex>
                    </td>
                </tr>
            }
            </tbody>
            {/* {
                isEmpty()
                && 
                <tfoot>
                    <tr className={styles['table__row_footer']}>
                        <td colSpan={columns}>{props.emptyRender}</td>
                    </tr>
                </tfoot>
            } */}
            
        </table>
    )
}

export const TableExpendRow = (props: PropsWithChildren<{onClick?: () => void}>) => {

    return (
        <tr className={styles['table__row_expand']} onClick={props.onClick}>
            {props.children}
        </tr>
    )
    
}

export const TableExpendCell = (props: PropsWithChildren<{colSpan: number, onClick?: () => void}>) => {
    return <td colSpan={props.colSpan} onClick={props.onClick}>{props.children}</td>
}