import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"
import CaseStatus from "../clientmanagement/modules/CheckStatus"
import dayjs from "dayjs"
import { ChatMessageDTO } from "./Chat.api"


export type CaseType = "NIW" | "EB1A"

export interface ClientDTO{
    client_id: string
    client_status: string
    created_at: string
    email_address: string
    first_name: string
    last_name: string
    date_joined: string
    cases: CaseDTO[]
}

export interface CaseDTO{
    case_id: string
    case_status: string
    created_at: string
    case_type: CaseType
    leazy_id: string
    updated_at: string
    
}

export interface CaseStatusDTO{
    form_category: string
    form_name: string
    receipt_number: string
    service_center: string
}

export interface ClientResponse{
    client_info: ClientDTO
}

export interface CaseResponse{
    case_info: CaseDTO
    client_id: string
    tools: CaseTool[]
}

export type CaseToolType = "case_status"|"visa_bulletin"|"quick_evaluation"
export interface CaseTool<T = any>{
    chat_session_id: string
    metadata: T
    type: CaseToolType
} 
export interface CheckStatusMetadata{
    form_category: string
    form_name: string
    receipt_number: string
    service_center: string
}

export interface VisaTypeMetadata{
    priority_date: string
    nationality: string
    visa_type: string
}

export interface ClientCreateRequest{
    date_joined?: string
    email_address: string
    first_name: string
    last_name: string
}

export type ClientUpdateRequest = {

} & {
    client_id: string
}

export interface CaseCreateRequest{
    case_type: string
    client_id: string
}

export interface CheckStatusUpdateRequest{
    form_category: string
    form_name: string
    receipt_number: string
    service_center: string
}

export interface VisaBulletinUpdateRequest{

}

export interface QuickEvaluationMetadata{
    additional_information: string
    award_amount: number
    citation_amount: number
    highest_degree: string
    paper_amount: number
    patent_amount: number
    recommendation_letter_amount: number
    review_amount: number
    study_field: string
}


/**
 * 获取自己的用户
 * @param user_id
 */
const clients = async (request?: {page: number, limit: number}):Promise<ClientDTO[]> => {

    const {
        page = 1,
        limit = 10
    } = request??{}

    const res = (await axios.get(`/api/clients?page=${page}&limit=${limit}`)).data as HttpResponse<ClientDTO[]>
    checkCode(res)
    return res.data
}


const client = async (clientId: string) => {
    const res = (await axios.get(
        `/api/clients/${clientId}`
    )).data as HttpResponse<ClientResponse>
    checkCode(res)
    return res.data
}

const getCase = async (caseId: string) => {
    const res = (await axios.get(
        `/api/cases/${caseId}`
    )).data as HttpResponse<CaseResponse>
    checkCode(res)
    return res.data
}

const createClient = async (request: ClientCreateRequest) => {
    request.date_joined = dayjs().format('YYYY-MM-DD')
    const res = (await axios.post(
        `/api/clients`,
        request
    )).data as HttpResponse<any>

    checkCode(res)
    return res.data
}

const deleteClient = async (client_id: string) => {
    const res = (await axios.delete(
        `/api/clients/${client_id}`
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const updateClient = async (request: ClientUpdateRequest) => {
    const res = (await axios.patch(
        `/api/clients/${request.client_id}`,
        request
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const createCase = async (request: CaseCreateRequest) => {
    const res = (await axios.post(
        `/api/cases`,
        request
    )).data as HttpResponse<any>

    checkCode(res)
    return res.data
}

const deleteCase = async (case_id: string) => {
    const res = (await axios.delete(
        `/api/cases/${case_id}`
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const updateCaseStatus = async (case_id: string, request: CheckStatusUpdateRequest) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/check_status`,
        request
    )).data as HttpResponse<{messages: ChatMessageDTO[]}>

    checkCode(res)
    return res.data
}

const updateVisaBulletin = async (case_id: string, request: CheckStatusUpdateRequest) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/visa_bulletin`,
        request
    )).data as HttpResponse<{messages: ChatMessageDTO[]}>

    checkCode(res)

    return res.data
}

const updateQuickEvaluation = async (case_id: string, request: QuickEvaluationMetadata) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/quick_evaluate`,
        request
    )).data as HttpResponse<{messages: ChatMessageDTO[]}>

    checkCode(res)
    return res.data
}


export default {
    cleints: clients,
    client: client,
    getCase,
    createClient,
    deleteClient,
    updateClient,
    createCase,
    deleteCase,
    updateCaseStatus,
    updateVisaBulletin,
    updateQuickEvaluation
}
