import { Flex, message } from "antd"
import Screen from "../components/Screen"

import styles from "./ForgetPassword.module.scss";
import keySvg from "../login/assets/images/key-01.svg";


import Icon from "../components/icon/Icon";
import Link from "../login/components/Link";


export default () => {


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['forgetpassword']}>
                    <Flex align="center" vertical  gap={24}>
                        <div className={styles["forgetpassword__icon"]}>
                            <Icon src={keySvg} size={28}></Icon>
                        </div>
                        <Flex vertical  gap={12}>
                            <div className={styles['forgetpassword__title']}>Forgot password?</div>
                            <Flex vertical align="center"  gap={32} className={styles['forgetpassword__subtitle']}>
                                <div>Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</div>
                                <div>If you still need help, <Link>contact support</Link> </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Screen>
    )


}