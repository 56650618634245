

import { Flex } from "antd";
import styles from "./Divider.module.scss";

const OR = () => {
    return (
        <Flex gap={8} align="center">
            <div style={{flex: 1}} className={styles['divider__block']}> </div>
            <div className={styles['divider__label']}>OR</div>
            <div style={{flex: 1}} className={styles['divider__block']}> </div>
        </Flex>
    )
}

export default {
    OR
}