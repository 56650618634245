import { PropsWithChildren } from "react"
import styles from "./AddLink.module.scss"
import { Flex } from "antd"
import Icon from "../../components/icon/Icon"
import plusPrimarySvg from "../assets/images/plus-primary.svg"


export default (props: PropsWithChildren<{onClick?: () => void}>) => {

    return (
        <Flex align="center" gap={8} className={styles['add-link']} onClick={props.onClick}>
            <Icon src={plusPrimarySvg} size={24}></Icon>
            {props.children}
        </Flex>
    )

}