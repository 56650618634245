
import {
    SyncOutlined,
  } from '@ant-design/icons';

export default () => {
    
    return (
        <SyncOutlined spin />
    )
}