// import apiClient from "../../utils/axiosConfig"
// const axios = apiClient
// // import axios from "axios"
import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"

export type ChatbotMessageAuthor = "ai"|"human"|"system"
export type ChatbotMessageType = "welcome"|"gotoprofile"|"loading"|"text"|"qa"|"visa_bulletin"|"resource_list"|"document_link"|"rank_analysis"


export interface ChatMessageDTO {
    id?: string
    author: ChatbotMessageAuthor
    created_at?: number
    type: ChatbotMessageType
    text?: string
    payload?:ChatMessagePayload
}

export type ChatMessagePayload = string | RankAnalysePayload

export interface RankAnalysePayload{
    background: {[key: string]: any}
    description: string
    analysis_result: {[key: string]: {"measurements": Measurements}}
}
export type Measurements = {[key: string]: {"performance": string}}

export interface MessagesRequest{
    chat_session_id: string
    page_size?: number
    /**
     * page token, you can get from the response of last call. backend will use this arg to fetch next page.
     */
    page_token?: string
    
}

const messages = async (request: MessagesRequest) => {
    const res = (await axios.get(
        `/api/chats/${request.chat_session_id}/messages`
    )).data as HttpResponse<{messages: ChatMessageDTO[]}>
    checkCode(res)
    return res.data

}

export default {
    messages
}