import { CSSProperties, PropsWithChildren } from "react";

import styles from "./ProtectPage.module.scss";

interface ProtectPageProps{
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    className?: string
}

export default (props: PropsWithChildren<ProtectPageProps>) => {
    
    const {
        width = "100%",
        height = "100%"
    } = props

    return (
        <div className={[styles['page'], props.className].join(" ")} style={{width, minHeight: height}}>
            {props.children}
        </div>
    )

}