


import { Flex, Grid, Row } from "antd"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import FormValidators from "../../components/form/FormValidators"
import { StepForm } from "../CaseFillingForm"

import styles from "./GenerateEvidence.module.scss"
import { createContext, CSSProperties, PropsWithChildren, ReactNode, useContext, useEffect, useRef, useState } from "react"
import Icon from "../../components/icon/Icon"

import chervonDownPrimarySvg from "../assets/images/chevron-down-primary.svg"
import chervonUpPrimarySvg from "../assets/images/chevron-up-primary.svg"
import Env from "../../../Env"
import { CaseFillingFormContext } from "../context/CaseFillingFormContext"
import CaseFillingApi, { RecommenderDTO, RecommenderPublicationDTO, StrengthTagDetailEvidence, StrengthTagDTO, StrengthType } from "../../api/CaseFilling.api"
import LoadingIcon from "../../components/icon/LoadingIcon"
import { CaseFilingNotificationContext } from "../context/CaseFilingNotificationContext"
import { useMask } from "../../components/mask/Mask"
import xCloseSvg from "../assets/images/x-close.svg"

interface RecommenderGroupContextProps{
    heights: number[]
    setHeight: (idx: number, height: number) => void
}
const RecommenderGroupContext = createContext<RecommenderGroupContextProps>({
    heights: [],
    setHeight: function (idx: number, height: number): void {
        throw new Error("Function not implemented.")
    }
})

const InProcessText = "Please wait a moment as we take some time 🕑 to process your request. It might take a few minutes, but rest assured, we will notify you via email as soon as the results are available. We appreciate your patience and understanding. "
const ProcessedText = "The evidence collection process for your client is now complete. Please review the information we have gathered and prepare to select the application type in the next step. Additionally, we have provided a list of potential recommenders. You can review this list and generate recommendation letters in the subsequent steps."

export default () => {

    const {record, update, setStep} = useContext(CaseFillingFormContext)

    return (
        <StepForm
            title="Generate Evidence"
            subtitle={record?.evidence_status === "SUCCEEDED"?ProcessedText:InProcessText}
            required
        >
            <GenerateEvidence/>
        </StepForm>
    )
}


const GenerateEvidence = () => {

    const {record, update, setStep} = useContext(CaseFillingFormContext)
    const [tagGroups, setTagGroups] = useState<{[key: string]: StrengthTagDTO[]}>({})
    const [recommenderGroups, setRecommenderGroups] = useState<{[key: string]: RecommenderDTO[]}>({})
    const [loading, setLoading] = useState<boolean>(true)
    const {analyseSucceeded, cleanAnalyseSucceeded} = useContext(CaseFilingNotificationContext)

    useEffect(() => {
        refresh()
    }, [])

    useEffect(() => {
        if(analyseSucceeded){
            cleanAnalyseSucceeded()
            refresh()
        }
    }, [analyseSucceeded])

    const refresh = async () => {
        
        if(record){
            const caseId = record.case_id;
            const taskId = record.id;
            const _record = await CaseFillingApi.detail(caseId, taskId)
            update(_record)
            if(_record.evidence_status === "SUCCEEDED"){
                setLoading(true)
                await init(caseId, taskId);
                setLoading(false)
            }else{
                setLoading(false)
            }
        }
    }

    const init = async (caseId: string, taskId: string) => {

        const recommenders = await CaseFillingApi.recommenders(caseId, taskId);
        let _recommenderGroups: {[key: string]: RecommenderDTO[]} = {}
        recommenders.forEach(it => {
            let group = _recommenderGroups[it.recommender_type]??[]
            group.push(it)
            _recommenderGroups[it.recommender_type] = group
        })
        setRecommenderGroups(_recommenderGroups)
            
        const tags = await CaseFillingApi.tags(caseId, taskId);
        let _tagGroups: {[key: string]: StrengthTagDTO[]} = {}
        tags.forEach(it => {
            let group = _tagGroups[it.strength_type]??[]
            group.push(it)
            _tagGroups[it.strength_type] = group
        })
        setTagGroups(_tagGroups)   

        

    }

    
    

    if(loading){
        return <LoadingIcon/>
    }

    return (
        <Flex vertical gap={48}>
            
            {
                record?.evidence_status === "SUBMITTED"?
                <Inprogress/>
                :
                <Flex vertical gap={32}>
                    <StrengthTags data={tagGroups}/>
                    <Recommenders data={recommenderGroups}/>
                </Flex>
            }
            
            <Form.Item nowrap label="" name="">
                <Flex justify="flex-end">
                    <Form.Submit width={185}>Continue</Form.Submit>
                </Flex>
            </Form.Item>
        </Flex>
    )
}

const StrengthTags = (props: {data: {[key: string]: StrengthTagDTO[]}}) => {

    const {record, update, setStep} = useContext(CaseFillingFormContext)
    const {render: maskRender, show, close} = useMask()

    const tagGroups = props.data

    const tagsRender = (module: string) => {

        return (
            <TagGroup>
                {tagGroups[module]?.map(it => <Tag label={it.strength_keyword} onClick={() => showTagDetails(it)}/>)}
            </TagGroup>
        )

    }

    const showTagDetails = (tag: StrengthTagDTO) => {

        const statementText = (statement: string) => {
            return <div className={styles['tag-details__statement__content']}>{statement}</div>
        }

        const evidenceRender = (evidences: StrengthTagDetailEvidence[]) => {

            // const evidences = tag.strengths?.[0].evidence
            const paragraphs: ReactNode[] = []
            
            evidences.forEach(evidence => {
                paragraphs.push(
                        <>
                            <div className={styles['tag-details__evidence__content']}>
                                <label style={{fontWeight: 600}}>{evidence.title}</label>: {evidence.description}
                                {
                                    evidence.sources.map(source => {
                                        return <a href="#" className={styles['tag-details__evidence__source']} onClick={() => window.open(source.url, "_blank")}>{source.url_text}</a>
                                    })
                                }
                            </div>
                            
                        </>
                )
            })

            return <>{paragraphs}</>
        }

        show(<div className={styles['tag-details__wrapper']}>
            <Flex vertical className={styles['tag-details']} gap={0}>
                <Flex className={styles['tag-details__header']} justify="space-between" align="center">
                    <div className={styles['tag-details__title']}>{tag.strength_keyword}</div>
                    <Icon src={xCloseSvg} size={20} onClick={close}></Icon>
                </Flex>
                <Flex vertical gap={8} className={styles['tag-details__body']}>
                    <ol className={styles['tag-details__strengths']}>
                        {
                            tag.strengths.map(strength => (
                                <li>
                                    <Flex vertical gap={8}>
                                        <Flex vertical gap={4} className={styles['tag-details__statement']}>
                                            <div className={styles['tag-details__statement__label']}>Statement</div>
                                            {statementText(strength.statement)}
                                        </Flex>
                                        <Flex vertical gap={4} className={styles['tag-details__evidence']}>
                                            <div className={styles['tag-details__evidence__label']}>Evidence</div>
                                            {evidenceRender((strength.evidence??[] as StrengthTagDetailEvidence[]).filter(it => it.level))}
                                        </Flex>
                                    </Flex>
                                </li>
                            ))
                        }
                    </ol>
                </Flex>
            </Flex>
        </div>)
    }
    
    return (
        <GenerateEvidenceItem title="Strength Tags">
            {maskRender}
            <div className={styles['strength_tags__table__wrapper']}>
                <table className={styles['strength_tags__table']}>
                    <thead>
                        <tr>
                            <td>Analysis Engine</td>
                            <td>Evidence Tags</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Educational and Professional Background</td>
                            <td>{tagsRender("edu")}</td>
                        </tr>
                        <tr>
                            <td>Award Analysis</td>
                            <td>{tagsRender("award")}</td>
                        </tr>
                        <tr>
                            <td>Publication and Citation Analysis</td>
                            <td>{tagsRender("publication")}</td>
                        </tr>
                        <tr>
                            <td>Membership Analysis</td>
                            <td>{tagsRender("member")}</td>
                        </tr>
                        <tr>
                            <td>Media Coverage Analysis</td>
                            <td>{tagsRender("media")}</td>
                        </tr>
                        <tr>
                            <td>Journal Review Analysis</td>
                            <td>{tagsRender("review")}</td>
                        </tr>
                        <tr>
                            <td>Patent Analysis</td>
                            <td>{tagsRender("patent")}</td>
                        </tr>
                        <tr>
                            <td>Art Exhibition Analysis</td>
                            <td>{tagsRender("art")}</td>
                        </tr>
                        <tr>
                            <td>Job Title Analysis</td>
                            <td>{tagsRender("job_title")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
        </GenerateEvidenceItem>
    )
}

const TagGroup = (props: PropsWithChildren<{}>) => {
    
    return (
        <Flex wrap gap={8} className={styles['tag-group']} align="flex-start">
            {props.children}
        </Flex>
    )
}

const Tag = (props: PropsWithChildren<{label: string, onClick: () => void}>) => {
    return (
        <div className={styles['tag']} onClick={props.onClick}>
            {props.label}
        </div>
    )
}

const Recommenders = (props: {data: {[key: string]: RecommenderDTO[]}}) => {

    const {record, update, setStep} = useContext(CaseFillingFormContext)
    
    const recommenderGroups = props.data

    return (
        <GenerateEvidenceItem title="Recommenders">
            <Flex vertical gap={8}>
                <RecommenderGroup title="Dependent Recommender (Famous Co-authors)" recommenders={recommenderGroups['DEPENDENT']??[]}>
                </RecommenderGroup>
                <RecommenderGroup title="Independent Recommender (Experts who cited applicant’s publications)" recommenders={recommenderGroups['INDEPENDENT']??[]}>
                </RecommenderGroup>
                <RecommenderGroup title="Other Suggested Recommenders (Colleague, alumni or experts who work in applicant’s past schools)" recommenders={recommenderGroups['OTHER']??[]}>
                </RecommenderGroup>
            </Flex>
        </GenerateEvidenceItem>
    )
}

const RecommenderGroup = (props: PropsWithChildren<{title: string, recommenders: RecommenderDTO[]}>) => {

    const {
        title,
        children,
        recommenders
    } = props

    const [showMore, setShowMore] = useState<boolean>()
    const morePaperRef = useRef<HTMLDivElement>(null)
    const [recommenderHeights, setRecommenderHeights] = useState<number[]>([])

    // useEffect(() => {
    //     if(recommenderHeights && recommenderHeights.length >0){
    //         const rawHeight = morePaperRef.current?.getBoundingClientRect().height?? 0
    //         const height = rawHeight + recommenderHeights.reduce((o1, o2) => o1 + o2); //morePaperRef.current?.getBoundingClientRect().height
    //         Env.DEBUG && console.log(`recommender rawHeight: `, rawHeight, "recommenderHeights: ", recommenderHeights, " height: ", height)
    //     }
    // }, [recommenderHeights])
    
    const setHeight = (idx: number, height: number) => {
        setRecommenderHeights(current => {
            current[idx] = height
            return [...current]
        })
    }

    const moreRender = () => {
        return (
            <Flex justify="center" onClick={() => setShowMore(!showMore)}>
                <Icon src={showMore?chervonUpPrimarySvg:chervonDownPrimarySvg} size={24}></Icon>
            </Flex>
        )
    }

    const getMoreStyles = (): CSSProperties => {

        const rawHeight = morePaperRef.current?.getBoundingClientRect().height?? 0
        const height = rawHeight + recommenderHeights.reduce((o1, o2) => o1 + o2); //morePaperRef.current?.getBoundingClientRect().height
        Env.DEBUG && console.log(`recommender rawHeight: `, rawHeight, "recommenderHeights: ", recommenderHeights, " height: ", height)
        if(height){
            return {
                overflow: "visible",
                maxHeight: height
            }
        }

        return {}
    }


    return (
        <RecommenderGroupContext.Provider value={{heights: recommenderHeights, setHeight}}>
            <Flex vertical className={styles['recommender-group']} gap={16}>
                <div className={styles['recommender-group__title']}>{title}</div>
                <Flex vertical gap={16} className={styles['recommender-group__body']}>
                    
                    {recommenders.length > 0 && <Recommender recommender={recommenders[0]} idx={0}/>} 
                    
                    {
                        recommenders.length > 1 
                        && 
                        <>
                            <div className={styles['recommender-group__more']} style={showMore?getMoreStyles():{}}>
                                <Flex vertical gap={16} ref={morePaperRef} >
                                    {
                                        recommenders.slice(1).map((recommender, idx) => <Recommender recommender={recommender} idx={idx+1}/>)
                                    }
                                </Flex>
                            </div>
                            {moreRender()}
                        </>
                    }
                </Flex>
            </Flex>
        </RecommenderGroupContext.Provider>
    )
}

const Recommender = (props: {recommender: RecommenderDTO, idx: number}) => {

    const [showMore, setShowMore] = useState<boolean>()
    const morePaperRef = useRef<HTMLDivElement>(null)

    const {
        name,
        employer,
        publications
    } = props.recommender

    const {setHeight} = useContext(RecommenderGroupContext)


    const moreRender = () => {
        
        return (
            <div className={styles['recommender__papers__morebtn']} onClick={() => setShowMore(!showMore)}>
                {showMore?"Show Less":"Show more"}
            </div>
        )
    }

    const getMoreStyles = (): CSSProperties => {

        const height = morePaperRef.current?.getBoundingClientRect().height??0
        Env.DEBUG && console.log(`recommender[idx=${props.idx}] height: `, height)
        if(height){
            return {
                overflow: "visible",
                maxHeight: height
            }
        }

        return {
            
        }
    }

    useEffect(() => {
        const height = morePaperRef.current?.getBoundingClientRect().height??0
        Env.DEBUG && console.log(`recommender[idx=${props.idx}] height: `, height)
        setHeight(props.idx, height)
    }, [])

    return (
        <Flex gap={10} className={styles["recommender"]}>
            <div className={styles["recommender__no"]}>{props.idx + 1}.</div>
            <Flex vertical gap={10} flex={"1"}> 
                 <Flex vertical>
                    <div className={styles['recommender__name']}><span className={styles['recommender__name__label']}>Name: </span>{name}</div>
                    <div className={styles['recommender__employer']}><span className={styles['recommender__name__label']}>Employer: </span>{employer}</div>
                 </Flex>
                 <Flex vertical className={styles['recommender__papers']} gap={16}>
                    {publications.length > 0 && <RecommenderPaper publication={publications[0]}/>}
                    {
                        publications.length > 1 &&
                        <>
                            <div className={styles['recommender__papers__more']} style={showMore?getMoreStyles():{}}>
                                {
                                    <Flex vertical gap={16} ref={morePaperRef} >
                                        {
                                            publications.slice(1).map(publication => {
                                                return (
                                                    <>
                                                        <RecommenderPaperDivider/>
                                                        <RecommenderPaper publication={publication}/>
                                                    </>
                                                )
                                            })
                                        }
                                    </Flex>
                                }
                            </div>
                            {moreRender()}
                        </>
                    }
                 </Flex>
            </Flex>
        </Flex>
    )
}

const RecommenderPaper = (props: {publication: RecommenderPublicationDTO}) => {

    const {
        publish_name,
        publish_time,
        publisher
    } = props.publication


    return (
        <Flex vertical className={styles['recommender__paper']}>
            <div className={styles['recommender__paper']}><span className={styles['recommender__paper__label']}>Co-authored Paper</span>: "{publish_name}"</div>
            <div className={styles['recommender__paper']}><span className={styles['recommender__paper__label']}>Journal/Conference Name</span>: {publisher}</div>
            <div className={styles['recommender__paper']}><span className={styles['recommender__paper__label']}>Published Time</span>: {publish_time}</div>
        </Flex>
    )
}

const RecommenderPaperDivider = (props: {}) => {
    return (
        <div className={styles['recommender__paper-divider']}></div>
    )
}

const Inprogress = () => {

    return (
        <div className={styles['generate-evidence__inprogress']}>Evidence analysis in progress...</div>
    )
}


const GenerateEvidenceItem = (props: PropsWithChildren<{title: string}>) => {

    const {
        title,
        children
    } = props

    return (
        <Flex vertical gap={8} className={styles['generate-evidence__item']}>
            <div className={styles['generate-evidence__item__title']}>{title}</div>
            {children}
        </Flex>
    )
}

