import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface PatentsDTO{
  id: string
  title: string
  patentNumber: string
  year:string
  authorRanking: string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query GetPatents($caseFilingTaskId: ID!) {
    getPatents(caseFilingTaskId: $caseFilingTaskId) {
      id
      title
      patentNumber
      year
      authorRanking
      clientProfileInfoId
      userId
    }
  }

  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getPatents'] as PatentsDTO[]

}

const update = async (taskId: string, request: {created?: PatentsDTO[], updated?: PatentsDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: PatentsDTO) => {
  const operation = `
  mutation CreatePatents($patentInputs: [PatentInput!]!, $caseFilingTaskId: ID!) {
    createPatents(patentInputs: $patentInputs, caseFilingTaskId: $caseFilingTaskId) {
      id
      title
      patentNumber
      year
      authorRanking
      clientProfileInfoId
      userId
    }
  }
  `
  const variables = {
    "patentInputs": [{
      "title": request.title,
      "patentNumber": request.patentNumber,
      "year": request.year,
      "authorRanking": request.authorRanking,
    }],
    "caseFilingTaskId": taskId
  }

  return (
    await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables,
        caseFilingTaskId: taskId
      }
  )
  ).data.data['createPatents'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeletePatent($patentId: ID!) {
    deletePatent(patentId: $patentId)
  }
  `
  const variables = {
    patentId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: PatentsDTO) => {

  const operation = `
  mutation EditPatent($patentInput: PatentInput!, $patentId: ID!) {
    editPatent(patentInput: $patentInput, patentId: $patentId) {
      id
      title
      patentNumber
      year
      authorRanking
      clientProfileInfoId
      userId
    }
  }
  `

  const variables = {
    "patentInput": {
      "title": request.title,
      "patentNumber": request.patentNumber,
      "year": request.year,
      "authorRanking": request.authorRanking,
    },
    "patentId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}