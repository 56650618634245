

import { Col, Flex, Row } from "antd";
import "./Homepage.scss";
import fileCheckPng from  "./asests/file-check-02@3x.png"
import bankPng from "./asests/bank@3x.png"
import fileSearchPng from "./asests/file-search-01@3x.png"
import cubePng from "./asests/cube-02@3x.png"
import Screen from "../components/Screen";
import Button from "../components/Button";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Link from "./components/Link";
import Icon from "../components/icon/Icon";
import { useNavigate } from "react-router-dom";


const advantages = [
    {
        icon: <Icon src={fileCheckPng} size={36} padding={0}></Icon>,
        title: "Easy Case Status Check",
        desc: "Quickly and conveniently check the status of your legal cases without hassle."
    },
    {
        icon: <Icon src={fileSearchPng} size={36} padding={0}></Icon>,
        title: "Case Research & Summary",
        desc: "Gather in-depth information and create concise summaries for your legal cases, making research efficient."
    },
    {
        icon: <Icon src={bankPng} size={36} padding={0}></Icon>,
        title: "Real-world Case Bank",
        desc: "Access a comprehensive repository of real-world legal cases, providing valuable insights and references for your work."
    },
    {
        icon: <Icon src={cubePng} size={36} padding={0}></Icon>,
        title: "Simplify Daily Work",
        desc: "Streamline your daily legal tasks, making your workload more manageable and efficient."
    }
]


export default () => {

    const navigate = useNavigate()

    const gotoStart = () => {
        navigate("/workbench/chatbot")
    }
    

    return (
        <div className="homepage">
            <Header >
                <Link.Group>
                    <Link.Item onClick={() => navigate("/signup/first")}>Free trial</Link.Item>
                    <Link.Item onClick={() => navigate("/login") }>Log in</Link.Item>
                </Link.Group>
            </Header>
            <Screen>
                <Flex vertical gap={88} align="center" justify="center" className="homepage__banner">
                    <div className="homepage__banner__logo">
                        Leazy.ai: Where Law Meets <span className="homepage__banner__logo__highlight">Easy</span>
                    </div>
                    <Button.BlackBg  padding={[16, 28]} onClick={gotoStart}>Get Started</Button.BlackBg>
                </Flex>
            </Screen>
            <Flex vertical gap={64} className="homepage__capabilities">
                <Flex vertical gap={12} align="center">
                    <div className="homepage__capabilities__label">Our capabilities</div>
                    <Flex vertical gap={20} align="center">
                        <div className="homepage__capabilities__title">
                            We’re here to Simplify Your Legal Practice
                        </div>
                        <div className="homepage__capabilities__desc">
                            Discover how Leazy.AI can streamline your legal work. Check case statuses, research efficiently, access real-world cases, and make your daily legal tasks more manageable. We're here to empower your legal success.
                        </div>
                    </Flex>
                </Flex>
                <Row gutter={32}>
                    {advantages.map(advatange => (
                        <Col span={24 / advantages.length}>
                            <Flex vertical gap={20} align="center" className="homepage__capabilities__advantage">
                                <Flex align="center" justify="center" className="homepage__capabilities__advantage__icon">
                                    {advatange.icon}
                                </Flex>
                                <Flex vertical gap={8} align="center">
                                    <div className="homepage__capabilities__advantage__title">{advatange.title}</div>
                                    <div className="homepage__capabilities__advantage__desc">{advatange.desc}</div>
                                </Flex>
                            </Flex>
                        </Col>
                    ))}
                    
                </Row>
            </Flex>
            <Footer></Footer>
        </div>
    )
}