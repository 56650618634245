import axios from "axios"
import { checkCode, HttpResponse } from "../libs/http"



export interface UploadTokenGetRequest{
    name: string
    type: string
    metadata: any
    size: number
}

export interface UploadTokenDTO{
    file_id: string
    upload_args: {
        url: string
        fields: {[key: string]: string}
    }
}

export interface DownloadDTO{
    download_url: string
}

const getUploadToken = async (client_id: string, request: UploadTokenGetRequest) => {
    const res = (await axios.post(
        `/api/clients/${client_id}/files`,
        request
    )).data as HttpResponse<UploadTokenDTO>
    checkCode(res)
    return res.data
}

export default {
    getUploadToken
}