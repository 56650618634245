
import { Drawer, Flex, Popover, Row } from "antd";
import Button from "../components/Button";
import Icon from "../components/icon/Icon";
import plusSvg from "./assets/images/plus.svg";
import plusBlackSvg from "./assets/images/plus-back.svg";
import editSvg from "./assets/images/edit-01.svg";
import trashRedSvg from "./assets/images/trash-red-01.svg"
import dotSvg from "./assets/images/dots-horizontal.svg";
import Table, { TableColumnProps, TableDataLoader, TableExpendRow, TableExpendCell } from "../components/table/Table";
import foldSvg from "./assets/images/fold.svg";

import styles from "./ClientManagement.module.scss";
import { useTableInstance } from "../components/table/TableContext";
import { useMask } from "../components/mask/Mask";
import ClientProfileForm from "./ClientProfileForm";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../login/context/UserContext";
import ClientApi, { CaseDTO, ClientDTO } from "../api/Client.api";
import trash03Svg from "./assets/images/trash-03.svg";
import Env from "../../Env";
import TableClientName from "./components/TableClientName";
import Dropdown, { DropdownItem } from "../components/dropdown/Dropdown";
import ClientCaseForm from "./ClientCaseForm";
import { DeleteModal } from "../components/modal/Modal";
import ClientProfile from "./ClientProfile";
import { ClientCaseContext, useClientCaseContext } from "./context/ClientCaseContext";
import LoadingIcon from "../components/icon/LoadingIcon";
import { toHumanDate, toHumanDateTime } from "../libs/datetime";
import { BroadcastContext } from "../components/broadcast/BroadcastContext";
import ProtectPage from "../components/layout/ProtectPage";
import { useDrawer } from "../components/drawer/useDrawer";
import { useLoaderData, useNavigate, useRouteLoaderData } from "react-router-dom";
import { to } from "@react-spring/web";
import CaseFillingApi from "../api/CaseFilling.api";

const emptyRender = <>Your client list is empty. Add new client to start your work.</>

export default () => {

    const {user} = useContext(UserContext)
    const tableInstance = useTableInstance()
    const {
        expandIdx,
        toggleExpandIdx,
        refresh,
    } = tableInstance

    const clientCaseContext = useClientCaseContext()
    const {error} = useContext(BroadcastContext)
    const navigate = useNavigate()

    const {
        render: drawerRender,
        show: showDrawer,
        close: closeDrawer
    } = useDrawer({width: "calc(100vw - 80px)", mask: false, keyboard: false})

    const loaderData = useRouteLoaderData("clientmanagement") as any

    const cols: TableColumnProps[] = [
        {
            label: "Name",
            name: "name",
            render: (row, value, recordIdx) => (
                <Flex gap={12} align="center" onClick={() => toggleExpandIdx(recordIdx)}>
                    <Icon 
                        src={foldSvg} 
                        size={20} 
                        className={[styles['expandable-icon'], (expandIdx == recordIdx)?styles['expandable-icon_expand']:''].join(" ")} 
                        // onClick={() => toggleExpandIdx(recordIdx)}
                    ></Icon>
                    {<TableClientName client={row}></TableClientName>}
                </Flex>
            )
        },
        {
            label: "Profile created date",
            name: "created_at",
            width: 296,
            render(record, value, idx) {
                return toHumanDate(value)
            },
        },
        {
            label: "Email address",
            name: "email_address",
            width: 488
        },
        {
            label: "",
            name: "",
            render: (row) => {
                const dropDown = (
                    <Dropdown>
                        <DropdownItem onClick={() => showAddCaseForm(row)}>
                            <Icon src={plusBlackSvg} size={24}></Icon>
                            <div className={styles['table__client__tools__label']}>Add case</div>
                        </DropdownItem>
                        <DropdownItem onClick={() => showEditClientForm(row)}>
                            <Icon src={editSvg} size={24} ></Icon>
                            <div className={styles['table__client__tools__label']}>Edit profile</div>
                        </DropdownItem>
                        <DropdownItem onClick={() => showDeleteClientModal(row)}>
                            <Icon src={trashRedSvg} size={24}></Icon>
                            <div className={styles['table__client__tools__label_red']}>Delete profile</div>
                        </DropdownItem>
                    </Dropdown>
                )
                const pop = (
                    <Popover 
                        trigger="click" 
                        content={dropDown} 
                        placement="bottomRight" 
                        arrow={false} 
                        overlayInnerStyle={{"padding": 8}}>
                        <div><Icon src={dotSvg} size={20} padding={10} hoverable></Icon></div>
                    </Popover>
                    
                )
                return pop
            },
            width: 40
        }
    ]

    const dataLoader: TableDataLoader<ClientDTO> = () => {
        return ClientApi.cleints()
    }

    const expandRender = (row: ClientDTO, idx: number) => {
        return (
            <TableExpandContent 
                row={row} 
                onCaseDeleteClick={(_case) => showDeleteCaseModal(row, _case)}
                onCaseClick={(_case) => {
                    // showDrawer(<ClientProfile 
                    //     onCancel={closeDrawer} 
                    //     onUpdate={() => refresh()} 
                    //     onDelete={() => {
                    //             ClientApi.deleteClient(row.client_id).then(() => {
                    //                 closeDrawer();
                    //                 refresh();
                    //             }).catch(error);
                    //     }}
                    //     ></ClientProfile>)
                    navigate(`/workbench/clientmanagement/${row.client_id}/${_case.case_id}/0/0`)
                    // show(<ClientProfile 
                    //     onCancel={close} 
                    //     onUpdate={() => refresh()} 
                    //     onDelete={() => {
                    //             ClientApi.deleteClient(row.client_id).then(() => {
                    //                 close();
                    //                 refresh();
                    //             }).catch(error);
                    //     }}
                    //     ></ClientProfile>)
                }}
            ></TableExpandContent>
        )
    }

    const closeMask = () => {
        close()
    }

    const {render: maskRender, show, close} = useMask()

    const showCreateClientForm = () => {
        show((
            <ClientProfileForm type="create" onCancel={closeMask} onConfirm={(form) => {
                return ClientApi.createClient(form).then(() => {
                    close();
                    refresh()
                }).catch(error)
            }}/>
        ))
    }

    const showDeleteClientModal = (row: ClientDTO) => {
        show((
            <DeleteModal
                title="Are you sure you want to delete this profile?"
                content="Please be aware that this action is irreversible and once completed, the profile cannot be retrieved or restored."
                onCancel={closeMask}
                onConfirm={() => {
                    return ClientApi.deleteClient(row.client_id).then(() => {
                        close(); 
                        refresh();
                    }).catch(error)
                }}
            ></DeleteModal>
        ))
    }

    const showEditClientForm = (row: ClientDTO) => {
        show((
            <ClientProfileForm type="edit" onCancel={closeMask} initial={row} onConfirm={(form) => {
                const request = {...row, ...form}
                return ClientApi.updateClient(request).then(() => {
                    close();
                    refresh();
                }).catch(error)
            }}/>
        ))
    }

    const showAddCaseForm = (row: ClientDTO) => {
        show((
            <ClientCaseForm client={row} onCancel={closeMask} onConfirm={(form) => {
                const request = {...form, client_id: row.client_id}
                return ClientApi.createCase(request).then(() => {
                    close(); 
                    if(expandIdx > -1){
                        refreshCases(row.client_id)
                    } 
                }).catch((e) => {
                    error(e)
                    close()
                })
            }}/>
        ))
    }

    const showDeleteCaseModal = (row: ClientDTO, _case: CaseDTO) => {
        show(
            <DeleteModal
                title="Are you sure you want to delete this case?"
                content="Please be aware that this action is irreversible and once completed, the case cannot be retrieved or restored."
                onCancel={close}
                onConfirm={() => {
                    return ClientApi.deleteCase(_case.case_id).then(() => {
                        close()
                        refreshCases(row.client_id)
                    }).catch((e) => {
                        error(e)
                        close()
                    })
                }}

            ></DeleteModal>
        )
    }

    const onRowClick = (row: ClientDTO, recordIdx: number) => {
        // toggleExpandIdx(recordIdx)
    }

    const refreshCases = (clientId: string) => {

        const {
            setLoadingCases,
            setCases
        } = clientCaseContext

        if(expandIdx > -1){
            setLoadingCases(true)
            setCases([])
            ClientApi.client(clientId).then(info => {
                setCases(info.client_info.cases??[])
                setLoadingCases(false)
            }).catch(() => {
                setLoadingCases(false)
            })
        }
    }

    useEffect(() => {
        user && refresh()
    }, [user])

    useEffect(() => {
        if(expandIdx > -1){
            const currentClient = tableInstance.data[expandIdx] as ClientDTO
            refreshCases(currentClient.client_id)
        }
    }, [expandIdx])

    useEffect(() => {
        Env.DEBUG && console.log("loaderData changed: ", loaderData)
        const clientId = loaderData['clientId']
        const caseId = loaderData['caseId']
        if(clientId !== "client"){
            //TODO chishijie
            ClientApi.client(clientId).then(info => {
                const currentClient = info.client_info
                const cases = info.client_info.cases??[]
                const currentCase = cases.find(it => it.case_id == caseId) as CaseDTO
                clientCaseContext.updateClient(info.client_info)
                clientCaseContext.setCase(currentClient, currentCase)

                setTimeout(() => {
                    showDrawer(<ClientProfile 
                        onCancel={doCloseDrawer} 
                        onUpdate={() => refresh()} 
                        onDelete={() => {
                                ClientApi.deleteClient(clientId).then(() => {
                                    doCloseDrawer();
                                    refresh();
                                }).catch(error);
                        }}
                        ></ClientProfile>)
                }, 1000 / 30);

                
            })
        }
    }, [loaderData])


    const doCloseDrawer = () => {
        navigate(`/workbench/clientmanagement/client/home/2/0`)
        closeDrawer();
    }


    return (
        <ProtectPage className={styles["client-management"]}>
            <ClientCaseContext.Provider value={clientCaseContext}>
                <Flex vertical gap={32} >
                    <Flex justify="space-between" className={styles["client-management__header"]}>
                        <div className={styles["client-management__title"]}>
                            Client Management
                        </div>
                        <Button.Primary padding={[10, 16]} onClick={showCreateClientForm}>
                            <Flex gap={8} align="center">
                                <Icon src={plusSvg} size={20}></Icon>
                                New Profile
                            </Flex>
                        </Button.Primary>
                    </Flex>
                    <div className={styles["client-management__body"]}>
                        <Table 
                            expandable={true}
                            instance={tableInstance}
                            cols={cols} 
                            dataLoader={dataLoader} 
                            emptyRender={emptyRender}
                            expandRender={expandRender}
                            onRowClick={onRowClick}
                        />
                    </div>
                    {maskRender}
                    {/* <Drawer width={"100%"} open={showCaseProfile} onClose={() => setShowCaseProfile(false)} >
                        <ClientProfile/>
                    </Drawer> */}
                </Flex>
                {drawerRender}
            </ClientCaseContext.Provider>
        </ProtectPage>
    )

}

const TableExpandContent = (props: {row: ClientDTO, onCaseDeleteClick: (_case: CaseDTO) => void, onCaseClick: (_case: CaseDTO) => void}) => {

    const {render: maskRender, show, close} = useMask()

    const {
        setCase, 
        cases, 
        loadingCases
    } = useContext(ClientCaseContext)

    useEffect(() => {
    }, [])

    return (
        <>
            {
                loadingCases
                ?
                <TableExpendRow>
                    <TableExpendCell colSpan={4}>
                        <LoadingIcon/>
                    </TableExpendCell>
                </TableExpendRow>
                :
                cases && cases.length > 0
                ?
                cases.map(it => (
                    <TableExpendRow >
                        <TableExpendCell colSpan={1} onClick={() => {setCase(props.row, it); props.onCaseClick(it)}}>
                            <Flex vertical style={{paddingLeft: "32px"}} gap={2} className={styles["expandrow"]}>
                                <div className={styles["expandrow__label"]}>Case ID</div>
                                <div>{it.case_id}</div>
                            </Flex>
                        </TableExpendCell>
                        <TableExpendCell colSpan={1} onClick={() => {setCase(props.row, it); props.onCaseClick(it)}}>
                            <Flex vertical gap={2}>
                                <div className={styles["expandrow__label"]}>Created date</div>
                                <div>{toHumanDateTime(it.created_at)}</div>
                            </Flex>
                        </TableExpendCell>
                        <TableExpendCell colSpan={1} onClick={() => {setCase(props.row, it); props.onCaseClick(it)}}>
                            <Flex vertical gap={2}>
                                <div className={styles["expandrow__label"]}>Case type</div>
                                <div>{it.case_type}</div>
                            </Flex>
                        </TableExpendCell>
                        <TableExpendCell colSpan={1}>
                            <Icon 
                                src={trash03Svg} 
                                size={20} 
                                padding={10}
                                onClick={() => props.onCaseDeleteClick(it)}
                                hoverable
                            />
                        </TableExpendCell>
                    </TableExpendRow>
                ))
                :
                <TableExpendRow>
                    <TableExpendCell colSpan={4}>
                        <div className={styles["expandrow__empty"]}>There are no cases under this client profile. Please create and start a new case.</div>
                    </TableExpendCell>
                </TableExpendRow>
            }
            {maskRender}
        </>
    )

    
}
