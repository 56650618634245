
const KB = 1024
const MB = 1024 * 1024


export const format = (bytes: number) => {

    if(bytes >= MB){
        return `${Math.ceil(bytes / MB)}MB`
    }else if(bytes >= KB){
        return `${Math.ceil(bytes / KB)}KB`
    }
    return `${bytes}B`

}