import { createContext } from "react";

export interface Session{
    id: string
    title?: string
    newSession?: boolean
    newSessionToken?: string
}

export interface ChatSessionContextType{
    session?: Session
    setSession: (session: Session) => void
    updateSessionInfo: (id: string, title?: string) => void
}

export const ChatSessionContext = createContext<ChatSessionContextType>({
    session: undefined,
    setSession(session: Session) {
        throw new Error("unimpletement")
    },
    updateSessionInfo(id: string, title?: string){
        throw new Error("unimpletement")
    }
})