import { Flex } from "antd"
import { StepForm } from "../CaseFillingForm"
import AddLink from "../components/AddLink"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import trashPrimarySvg from "../assets/images/trash-primary.svg";
import FormValidators from "../../components/form/FormValidators"
import Icon from "../../components/icon/Icon"
import { ReactNode, useContext, useEffect, useState } from "react"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import Env from "../../../Env"
import CaseFillingApi from "../../api/CaseFilling.api"
import LoadingIcon from "../../components/icon/LoadingIcon"
import JobsGraphsqlApi, { JobDTO } from "../../api/casefiling/JobsGraphsql.api"
import dayjs from "dayjs"
import { PublicationSucceededRender } from "../context/CaseFilingNotificationContext"
import validates from "../../libs/validates"


export default () => {

    return (
        <StepForm
            title="Job and title"
            subtitle="Please review the job career information extracted by our data engine and add any missing details. Ensure that all information is included for comprehensive evidence collection."
        >
            <JobAndTitle/>
        </StepForm>
    )
}

interface Idx{
    id: string
    create?: boolean
    delete?: boolean
    form: Record<string, any>
    valid?: number
    btnValid?: boolean
}

const JobAndTitle = () => {

    const formInstance = useFormInstance({autoValidate: true})
    const {clientCase} = useContext(ClientCaseContext)
    const {record, update, setStep, isFinish, setDisable} = useContext(CaseFillingFormContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [idxs, setIdxs] = useState<Idx[]>([])

    useEffect(() => {
        if(record){
            JobsGraphsqlApi.list(record.id).then(items => {
                Env.DEBUG && console.log("items: ", items)
                
                setIdxs(items.map(it => {
                    return {
                        id: it.id,
                        create: false,
                        form: it
                    }
                }))
                // formInstance.setInitial({...formData})

                setLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        const prepareReduces = idxs.filter(it => !it.create && it.valid).map(it => it.valid as number)
        console.log("idxs changed: ", idxs, " ", prepareReduces)
        if(prepareReduces.length == 0) return;
        const validScore = prepareReduces.reduce((o1, o2) => Math.min(o1 as number, o2 as number))
        if(validScore < 0){
            setDisable(CaseFilingSteps.JobAndTitle, true)
            record && 
            isFinish(CaseFilingSteps.JobAndTitle) && 
            CaseFillingApi.updateStatus(record.case_id, record.id, "JOB_AND_TITLE", false).then(() => {
                CaseFillingApi.detail(record.case_id, record.id).then(update)
            })
        }else{
            setDisable(CaseFilingSteps.JobAndTitle, false)
        }
    }, [idxs])


    const doSubmit = async () => {
        const taskId = record?.id as string 
        const caseId = record?.case_id as string
        return CaseFillingApi.updateStatus(caseId, taskId, "JOB_AND_TITLE").then(res => {
            CaseFillingApi.detail(caseId, taskId).then(update)
            setStep(CaseFilingSteps.ReviewAndSubmit)
            setDisable(CaseFilingSteps.JobAndTitle, false)
        })
    }

    const addForm = () => {
        setIdxs(current => {
            return [
                ...current,
                {
                    id: new Date().getTime() + "",
                    create: true,
                    form: {}
                }
            ]
        })
    }

    const removeForm = (id: string) => {
        setIdxs(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {...it, delete: true}
                }
                return it
            }).filter(it => !(it.create && it.delete)) as Idx[]
            return _current
        })
    }

    const createForm = (id: string, record: Record<string, any>) => {
        setIdxs(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record},
                        create: false,
                        id: record['id']
                    }
                }
                return it
            })
            return _current
        })
    }

    const updateForm = (id: string, record: Record<string, any>) => {
        setIdxs(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record}
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateForm = (id: string, valid: number) => {
        setIdxs(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        valid
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateBtn = (id: string, btnValid: boolean) => {
        setIdxs(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        btnValid
                    }
                }
                return it
            })
            return _current
        })
    }

    const itemsFormRender = () => {

        const nodes: ReactNode[] = []
        for(let i = 0; i < idxs.length; i++){
            const idx = idxs[i];
            if(idx.delete) continue;
            const id = idx.id;
            nodes.push(
                <JobAndTitleForm 
                    key={id} 
                    id={id} 
                    onRemove={() => removeForm(id)}
                    onUpdate={(record) => updateForm(id, record)}
                    onValidate={(valid) => validateForm(id, valid)}
                    record={idx.form}
                    autoValidate={!idx.create}
                    onBtnValidate={(valid) => validateBtn(id, valid)}
                    onCreated={(record) => createForm(id, record)}
                    />
            )
        }
        return <>{nodes}</>

    }


    const valid = () => {
        const forValidateIdx = idxs.filter(it => !it.delete)
        if(!forValidateIdx || forValidateIdx.length == 0) return true
        return !forValidateIdx.find(it => !it.btnValid)
    }


    if(loading){
        return (
            <LoadingIcon/>
        )
    }
     

    return (
        <Form.Form
            instance={formInstance}
            showError={false}
            onSubmit={doSubmit}
        >
            <Flex vertical gap={48}>
                {<PublicationSucceededRender/>}
                <Flex vertical gap={16}>
                    <Flex vertical gap={32}>
                        {itemsFormRender()}
                    </Flex>
                    {!isFinish(CaseFilingSteps.ReviewAndSubmit) && <AddLink onClick={addForm}>Add more job background</AddLink>}
                </Flex>
                
                <Form.Item nowrap label="" name="">
                    <Flex justify="flex-end">
                        <Form.Submit width={185} disabled={!valid() || isFinish(CaseFilingSteps.ReviewAndSubmit)}>Continue</Form.Submit>
                    </Flex>
                </Form.Item>
            </Flex>
        </Form.Form>
    )

}

const JobAndTitleForm = (
    props: {
        id: string, 
        onRemove: () => void, 
        record: Record<string, any>, 
        onUpdate: (updated: Record<string, any>) => void, 
        onValidate: (valid: number) => void
        onBtnValidate: (valid: boolean) => void
        autoValidate: boolean
        onCreated: (updated: Record<string, any>) => void
    }
) => {

    const {update, setStep, isFinish, record: task} = useContext(CaseFillingFormContext)
    const formInstance = useFormInstance({autoValidate: props.autoValidate})
    const {
        id,
        record,
        onRemove,
        onUpdate,
        onValidate,
        onBtnValidate,
        autoValidate,
        onCreated
    } = props

    const {
        values2,
        errors2,
        disabled
    } = formInstance
    const [recordId, setRecordId] = useState<string>()

    useEffect(() => {
        record && setRecordId(record.id)
        autoValidate && formInstance.setInitial(record) //TODO auto validate
    }, [])

    useEffect(() => {
        if(values2){
            if(validates.isDeepEqual(values2, props.record)){
                console.log("值未发生变化。。。")
                return;
            }
            if(task){
                if(!recordId && !validates.isEmpty(values2)){
                    JobsGraphsqlApi.create(task?.id, values2 as any).then(it => {
                        setRecordId(it.id)
                        onCreated(it)
                    })
                }else if(recordId){
                    JobsGraphsqlApi.edit({...values2, id: recordId} as any).then(() => {
                        onUpdate(values2)
                    })
                    
                }
            }
            onUpdate(values2)
        }
    }, [values2])

    useEffect(() => {
        if(errors2){
            onValidate(validates.isEmpty(errors2)?1:-1) 
        }
    }, [errors2])

    useEffect(() => {
        onBtnValidate(!disabled)
    }, [disabled])

    const doRemove = () => {
        recordId && JobsGraphsqlApi.remove(recordId)
        onRemove()
    }

    return (
        <Form.Form instance={formInstance}>
            <Flex gap={16} vertical key={id}>
            { !isFinish(CaseFilingSteps.ReviewAndSubmit) && <Flex justify="flex-end">
                    <Icon src={trashPrimarySvg} size={24} onClick={() => doRemove()}></Icon>
                </Flex>}
                <Form.Item
                    label="Employer name"
                    name={`employerName`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: ' '}]}
                >
                    <Form.Input placeholder="Please enter the name" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>            

                <Form.Item
                    label="Job title"
                    name={`jobTitle`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: ' '}]}
                >
                    <Form.Input placeholder="Please enter the job title" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>     

                <Form.Item
                    label="Start year"
                    name={`startYear`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: ' '}]}
                >
                    <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>  

                <Form.Item
                    label="End year"
                    name={`endYear`}
                    required
                    hiddenError
                    rules={[{validate: FormValidators.required, msg: ' '}]}
                >
                    <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                </Form.Item>
            </Flex>
        </Form.Form>
        
    )

}