
import { createContext, useContext, useEffect, useState } from "react"
import { useFormInstance } from "../components/form/FormContext"
import styles from "./ReviewLawyerInformation.module.scss"
import { FormFillingTaskContext } from "./context/FormFillingTaskContext"
import FormFillingApi, { FormFillingTaskDTO } from "../api/FormFilling.api"
import Env from "../../Env"
import { StepForm } from "./FormFillingForm"
import Form from "../components/form/Form"
import LawyerApi, { LawyerDTO } from "../api/Lawyer.api"
import { Col, Flex, Row } from "antd"
import Button from "../components/Button"
import FormValidators from "../components/form/FormValidators"
import Icon from "../components/icon/Icon"
import lawyerEmptyPng from "./assets/images/lawyer-empty.png"
import plusBlackSvg from "./assets/images/plus-black.svg"
import editSvg from "./assets/images/edit-01.svg"
import trashSvg from "./assets/images/trash-01.svg"
import buildingSvg from "./assets/images/building-01.svg"
import barNumberSvg from "./assets/images/tabler-barcode.svg"
import contactSvg from "./assets/images/mdi-contact-outline.svg"
import { FormFillingFormContext, convertToStep } from "./FormFillingFormContext"
import { DeleteModal } from "../components/modal/Modal"
import { useMask } from "../components/mask/Mask"
import plusPrimarySvg from "./assets/images/plus-primary.svg"

type LawyerState = "LIST" | "CREATE" | "EDIT"

interface ReviewContextProps{
    profiles?: LawyerDTO[]
    setProfiles: (lawyers?: LawyerDTO[]) => void
    selectedId?: string
    setSelectedId: (lawyer?: string) => void
    editingLawyer?: string
    setEditingLawyer: (lawyer?: string) => void
    step: LawyerState
    setStep: (step: LawyerState) => void
}

const ReviewContext = createContext<ReviewContextProps>({
    profiles: [],
    setProfiles: function (lawyers?: LawyerDTO[] | undefined): void {
        throw new Error("Function not implemented.")
    },
    selectedId: "",
    setSelectedId: function (lawyer?: string | undefined): void {
        throw new Error("Function not implemented.")
    },
    editingLawyer: "",
    setEditingLawyer: function (lawyer?: string | undefined): void {
        throw new Error("Function not implemented.")
    },
    step: "LIST",
    setStep: function (step: LawyerState): void {
        throw new Error("Function not implemented.")
    }
})

const useReviewContext = ():ReviewContextProps => {
    const [profiles, setProfiles] = useState<LawyerDTO[]>()
    const [selectedId, setSelectedId] = useState<string>()
    const [editingLawyer, setEditingLawyer] = useState<string>()
    const [step, setStep] = useState<LawyerState>("LIST")

    return {
        profiles,
        setProfiles,
        selectedId,
        setSelectedId,
        editingLawyer,
        setEditingLawyer,
        step,
        setStep
    }
}

export default () => {

    const formInstance = useFormInstance()
    const context = useReviewContext()
    const {
        step
    } = context
    
    useEffect(() => {

    }, [])
    

    return (
        <StepForm
            title="Review lawyer's information"
            subtitle="Select the lawyers profile to use for filing this case. You can review and edit the profile as needed, or create a new one. Feel free to skip this step if no lawyer's information is needed."
        >
            <ReviewContext.Provider value={context}>
                <Flex vertical className={styles["lawyer"]} gap={32}>
                    <div className={styles["lawyer__title"]}>Lawyer’s profiles</div>
                    {
                        step === "LIST" && <LawyerList  />
                    }
                    {
                        step === "CREATE" && <LawyerForm />
                    }
                    {
                        step === "EDIT" && <LawyerForm />
                    }
                </Flex>
            </ReviewContext.Provider>
        </StepForm>
    )

}

const LawyerList = (props: {}) => {

    const {data: fillingForm, setData, setStep} = useContext(FormFillingFormContext)
    const {render: maskRender, show, close} = useMask()
    const formInstance = useFormInstance()
    const [skiping, setSkipping] = useState<boolean>()

    const {
        profiles,
        setProfiles,
        setSelectedId,
        selectedId,
        setStep: setState,
        step: state,
        setEditingLawyer
    } = useContext(ReviewContext)

    // useEffect(() => {
    //     refresh()
    // }, [])

    useEffect(() => {
        if(state === "LIST"){
            refresh()
        }
    }, [state])

    useEffect(() => {
        if(fillingForm){
            const lawyerId = fillingForm.lawyer_profile_id;
            lawyerId && setSelectedId(lawyerId);
        }
    }, [fillingForm])

    const emptyRender = () => {

        return (
            <Flex vertical align="center" gap={16} className={styles["lawyer__empty"]}>
                <Icon src={lawyerEmptyPng} size={200} height={120}></Icon>
                <div className={styles["lawyer__empty__title"]} >No Lawyer's Profile Found</div>
                <div className={styles["lawyer__empty__subtitle"]} >You have not created any lawyer profile yet. Please create a lawyer profile first to proceed. You can skip this step if no lawyer's profile needed for form filling.</div>
                <Button.WhiteBg onClick={() => setState("CREATE")}>
                    <Flex align="center" gap={8}>
                        <Icon src={plusBlackSvg} size={20}></Icon>
                        Create profile
                    </Flex>
                </Button.WhiteBg>
            </Flex>
        )
    }

    const refresh = () => {
        LawyerApi.list({page: 1, limit: 20}).then(setProfiles)
    }

    const doDelete = (lawyerId: string) => {
        show(<DeleteModal
            title="Are you sure you want to delete this lawyer profile?"
            content="Please be aware that this action is irreversible and once completed, the profile cannot be retrieved or restored."
            onConfirm={() => {
                LawyerApi.remove(lawyerId).then(() => {
                    refresh();
                    close();
                    if(lawyerId == selectedId){
                        cleanLawyer();
                    }
                })
            }}
            onCancel={close}
        ></DeleteModal>)
        
    }

    const doEdit = (lawyerId: string) => {
        setEditingLawyer(lawyerId)
        setState("EDIT")
    }

    const isSelected = (currentId: string) => {
        return currentId === selectedId
    }

    const chooseLawyer = (lawyerId: string) => {
        setSelectedId(lawyerId);
        const case_id = fillingForm?.case_id as string;
        const task_id = fillingForm?.id as string;
        FormFillingApi.chooseLawyer(case_id, task_id, lawyerId).then(res => {
            Env.DEBUG && console.log("choose lawyer success, res: ", res);
            setData({
                ...fillingForm as FormFillingTaskDTO,
                lawyer_profile_id: lawyerId
            })
        })      
    }

    const cleanLawyer = () => {
        const case_id = fillingForm?.case_id as string
        const task_id = fillingForm?.id as string
        if(selectedId){
            FormFillingApi.chooseLawyer(case_id, task_id, undefined).then(res => {
                Env.DEBUG && console.log("choose lawyer success, res: ", res)
                setData({
                    ...fillingForm as FormFillingTaskDTO,
                    lawyer_profile_id: undefined
                })
            })  
            setSelectedId(undefined)
        }
    }


    const doContinue = () => {
        if(fillingForm && selectedId){
            const case_id = fillingForm.case_id
            const task_id = fillingForm.id
            const lawyer_id = fillingForm.lawyer_profile_id as string
            return FormFillingApi.confirmLawyer(case_id, task_id, lawyer_id).then(res => {
                Env.DEBUG && console.log("confirm lawyer success, res: ", res)
                setData({
                    ...fillingForm as FormFillingTaskDTO,
                    status: "COMPLETED"
                })
                setStep(convertToStep("COMPLETED"))
            })   
        }
    }

    const skipLawyer = async () => {
        setSkipping(true)
        try{
            //先确认空选择
            const case_id = fillingForm?.case_id as string
            const task_id = fillingForm?.id as string
            await FormFillingApi.chooseLawyer(case_id, task_id)
            Env.DEBUG && console.log("choose skip lawyer success")
            setData({
                ...fillingForm as FormFillingTaskDTO,
                lawyer_profile_id: undefined
            })
            setSelectedId(undefined)
            //再进行步骤confirm
            await FormFillingApi.confirmLawyer(case_id, task_id)
            Env.DEBUG && console.log("confirm skip lawyer success")
            setData({
                ...fillingForm as FormFillingTaskDTO,
                status: "COMPLETED"
            })
            setStep(convertToStep("COMPLETED"))
        }finally{
            setSkipping(false)
        }

    }

    const list = () => {
        
        return (
            <Flex vertical gap={32}>
                {
                    (profiles??[]).map((profile, idx) => {
                        return (
                            <Flex vertical className={[styles['lawyer__item'], isSelected(profile.id)?styles['lawyer__item_selected']:''].join(" ")} gap={12} key={idx} onClick={() => chooseLawyer(profile.id)}>
                                <Flex align="center" justify="space-between">
                                    <div className={styles['lawyer__item__title']}><span className={styles['lawyer__item__name']}>{profile.given_name} {profile.family_name}, </span>{profile.org_name}, {profile.state}</div>
                                    <Flex gap={8}>
                                        <Icon src={trashSvg} size={20} padding={8} hoverable={true} onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            doDelete(profile.id)}}></Icon>
                                        <Icon src={editSvg} size={20} padding={8} hoverable={true} onClick={(e) => {
                                            e.stopPropagation(); 
                                            e.preventDefault(); 
                                            doEdit(profile.id)}}></Icon>
                                    </Flex>
                                </Flex>
                                <Flex vertical gap={6}>
                                    <Flex align="center">
                                        <Flex gap={8} align="center" style={{width: 250}}>
                                            <Icon src={buildingSvg} size={16}></Icon>
                                            <div className={styles['lawyer__item__detail__label']}>Business address</div>
                                        </Flex>
                                        <div className={styles['lawyer__item__detail__value']}>{profile.street_address}</div>
                                    </Flex>
                                    <Flex align="center">
                                        <Flex gap={8} align="center" style={{width: 250}}>
                                            <Icon src={barNumberSvg} size={16}></Icon>
                                            <div className={styles['lawyer__item__detail__label']}>Bar number</div>
                                        </Flex>
                                        <div className={styles['lawyer__item__detail__value']}>{profile.bar_number}</div>
                                    </Flex>
                                    <Flex align="center">
                                        <Flex gap={8} align="center" style={{width: 250}}>
                                            <Icon src={contactSvg} size={16}></Icon>
                                            <div className={styles['lawyer__item__detail__label']}>Contact</div>
                                        </Flex>
                                        <div className={styles['lawyer__item__detail__value']}>{profile.daytime_phone}, {profile.email_address}</div>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )
                    })
                }
                <Flex align="center" gap={12} onClick={() => setState("CREATE")} className={styles['lawyer__list__create-label']}>
                    <Icon src={plusPrimarySvg} size={24}></Icon>
                    Create a new profile
                </Flex>
            </Flex>
        )
    }

    return (
        <Form.Form 
            instance={formInstance}
            onSubmit={doContinue}
        >
            <Flex vertical>
                {
                    profiles && profiles.length > 0
                    ?
                    list()
                    :
                    emptyRender()
                }
                <Flex justify="flex-end" style={{paddingTop: 48}} gap={16}>
                    <Button.TransparentBg width={185} onClick={skipLawyer} loading={skiping}>Skip for now</Button.TransparentBg>
                    <Form.Submit width={185} disabled={!selectedId}>Continue</Form.Submit>
                </Flex>
                {maskRender}
            </Flex>
        </Form.Form>
    )

}


const LawyerForm = () => {

    const formInstance = useFormInstance()
    const {
        profiles,
        editingLawyer,
        setEditingLawyer,
        step: state,
        setStep: setState
    } = useContext(ReviewContext)

    useEffect(() => {

        if(state === "CREATE"){
            Env.DEBUG && formInstance.setInitial(
                {
                    "apt_address": "Apt 4B",
                    "bar_number": "B123456789",
                    "city": "Anytown",
                    "daytime_phone": "+1123-456-7890",
                    "email_address": "john.doe@example.com",
                    "family_name": "Doe",
                    "given_name": "test refactor",
                    "id": "01HZ7P1MW8FDMB906YYPB0HHDS",
                    "org_name": "ABC Law Firm",
                    "state": "NY",
                    "street_address": "123 Main St",
                    "zip_code": "12345",
                    "country": "USA",
                    "license_number": "B123456789",
                    "fax_number": "+1123-456-7890"
                }
            )
        }else if(state == "EDIT" && editingLawyer){
            LawyerApi.detail(editingLawyer).then(formInstance.setInitial)
        }
        
    }, [state, editingLawyer])

    const onCreate = async (form: LawyerDTO) => {

        if(state === "CREATE"){
            return LawyerApi.create(form).then(res => {
                Env.DEBUG && console.log("doCreate lawyer res: ", res);
                setState("LIST")  
            })
        }else{
            const info = (profiles??[]).find(it => it.id === editingLawyer)
            return LawyerApi.update(info?.id as string, {...info, ...form}).then(res => {
                Env.DEBUG && console.log("doUpdate lawyer res: ", res);
                setEditingLawyer(undefined)
                setState("LIST")  
            })
        }
    }

    const onCancel = async () => {
        setEditingLawyer(undefined);
        setState("LIST")  ;
    }

    return (
        <Form.Form 
            instance={formInstance}
            showError={true}
            onSubmit={onCreate}
        >
            <Flex vertical gap={32} className={styles['lawyer-form']}>
                <Flex className={styles["lawyer-form__header"]} align="center" justify="space-between">
                    <div className={styles["lawyer-form__title"]} style={{flex: 1}}>
                        {
                            state === "CREATE"
                            ?
                            "Create a new profile"
                            :
                            "Edit profile information"
                        }
                    </div>
                    <Flex gap={24}>
                        <Button.WhiteBg width={76} mini onClick={onCancel}>Cancel</Button.WhiteBg>
                        <Form.Submit width={76} mini>Save</Form.Submit>
                    </Flex>
                </Flex>
                <div className={styles["lawyer-form__body"]} style={{flex: 1}}>
                    <Row gutter={[32, 0]}>
                        <Col span={12}>
                            <Form.Item label="Attorney's family name" name="family_name" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Family name"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's given name" name="given_name" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Given name"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's business or organization name" name="org_name" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Enter business or organization name"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's USCIS online account number " name="uscis_acc_number">
                                <Form.Input placeholder="e.g., 123456789"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's state license number" name="license_number" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="e.g., CA123456"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's bar number" name="bar_number" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="e.g., CA123456 "></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's daytime phone number" name="daytime_phone" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="+1 (555) 000-0000"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's phone extension" name="phone_extension">
                                <Form.Input placeholder="Enter extension if available"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's mobile Num" name="mobile_phone">
                                <Form.Input placeholder="+1 (555) 000-0000"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Attorney's email address" name="email_address" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="example@domain.com"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Attorney's fax number" name="fax_number" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="e.g., 123-456-7890"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Attorney's address line 1(street address) " name="street_address" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Enter your street address"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Attorney's address line 2(Apt, suite, floor etc)" name="apt_address">
                                <Form.Input placeholder="Apt, suite, etc. (optional)"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Attorney's city/town" name="city" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Enter your city or town"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Zip code / Postal code" name="zip_code" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="123456"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Country" name="country" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Enter your country"></Form.Input>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="State / Province" name="state" required rules={[{validate: FormValidators.required, msg: "Please fill in information."}]}>
                                <Form.Input placeholder="Enter your state"></Form.Input>
                            </Form.Item>
                        </Col>

                    </Row>
                </div>

            </Flex>
            
        </Form.Form>
    )


}

