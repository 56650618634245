import { createContext, useState } from "react";
import { ChatMessageDTO } from "../../api/Chat.api";

export interface ChatScreenContextType{
    messages: ChatMessageDTO[]
    pin: (message: ChatMessageDTO | ChatMessageDTO[], clean?: boolean) => void
    loading?: boolean
    setLoading: (loading: boolean) => void
}


export const ChatScreenContext = createContext<ChatScreenContextType>({
    pin: function (message: ChatMessageDTO | ChatMessageDTO[], clean?: boolean): void {
        throw new Error("Function not implemented.");
    },
    messages: [],
    setLoading: function (loading: boolean): void {
        throw new Error("Function not implemented.");
    }
})


export const useChatScreenContext = (): ChatScreenContextType => {

    const [messages, setMessages] = useState<ChatMessageDTO[]>([])
    const [loading, setLoading] = useState<boolean>()

    const pin = (message: ChatMessageDTO | ChatMessageDTO[], clean: boolean = false) => {
        let msgs = message instanceof Array?message:[message]
        setMessages(current => {
            if(clean) return [...msgs]
            return [...current, ...msgs]
        })
    }

    
    
    return {
        messages,
        pin,
        loading,
        setLoading
    }
}