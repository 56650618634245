import { PropsWithChildren } from "react"
import { ChatMessageReference } from "./ChatbotContext"
import { Flex, Popover } from "antd"
import referenceLogo from './assets/images/Group@3x.png'


import "./Citation.scss";

export default (props: PropsWithChildren<{reference: ChatMessageReference}>) => {

    const {
        reference
    } = props

    return (
        <Popover placement="bottom" content={<Reference {...reference}/>}>
            <div className="chatbot__msg__citation-wrapper">
                <div className="chatbot__msg__citation">
                    {reference.index}
                </div>
            </div>
        </Popover>
    )
}

const Reference = (props: ChatMessageReference) => {

    const goToUrl = () => {
        window.open(props.link, "_blank");
    }

    return (
        <div className="chatbot__msg__reference">
            <Flex gap={8} align="center">
                <img src={referenceLogo} className="chatbot__msg__citation__reference__img"/>
                <div className="chatbot__msg__citation__reference__title" >{props.title}   </div>
            </Flex>
            <div className="chatbot__msg__citation__reference__url" onClick={goToUrl}>{props.link}</div>
        </div>
    )

}