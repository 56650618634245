import { PropsWithChildren } from "react";

import styles from "./Header.module.scss"
import { Flex } from "antd";
import logoPng from "../asests/logo@3x.png";
import Icon from "../../components/icon/Icon";

export default (props: PropsWithChildren<{}>) => {

    return (
        <div className={styles['header']}>
            <Flex justify="space-between">
                <Flex gap={4} align="center" className={styles['header__logo']}>
                    <Icon src={logoPng} size={40} padding={0}></Icon>
                    <div className={styles['header__logo__text']}>Leazy.ai</div>
                </Flex>
                <div className={styles['header__tools']}>
                    {props.children}
                </div>
            </Flex>
        </div>
    )
}