import { PropsWithChildren } from "react";

import styles from "./StyledText.module.scss";

export type TextProps = {
    ellipsis?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>


export default (props: TextProps) => {

    const {
        ellipsis = true
    } = props

    return (
        <div {...props} className={[props.className, ellipsis?styles['text_ellipsis']:''].join(" ")}>{props.children}</div>
    )

}