import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface JobDTO{
  id: string
  employerName: string
  jobTitle: string
  startYear:string
  endYear:string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query Query($caseFilingTaskId: ID!) {
  getJobs(caseFilingTaskId: $caseFilingTaskId) {
    id
    employerName
    jobTitle
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}

  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getJobs'] as JobDTO[]

}

const update = async (taskId: string, request: {created?: JobDTO[], updated?: JobDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: JobDTO) => {
  const operation = `
  mutation Mutation($jobInputs: [JobInput!]!, $caseFilingTaskId: ID!) {
  createJobs(jobInputs: $jobInputs, caseFilingTaskId: $caseFilingTaskId) {
    id
    employerName
    jobTitle
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}
  `
  const variables = {
    "jobInputs": [{
      "employerName": request.employerName,
      "jobTitle": request.jobTitle,
      "startYear": request.startYear,
      "endYear": request.endYear,
    }],
    "caseFilingTaskId": taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables,
      caseFilingTaskId: taskId
    }
)).data.data['createJobs'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation Mutation($jobId: ID!) {
  deleteJob(jobId: $jobId)
}
  `
  const variables = {
    jobId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: JobDTO) => {

  const operation = `
  mutation EditJob($jobInput: JobInput!, $jobId: ID!) {
  editJob(jobInput: $jobInput, jobId: $jobId) {
    id
    employerName
    jobTitle
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}
  `

  const variables = {
    "jobInput": {
      "employerName": request.employerName,
      "jobTitle": request.jobTitle,
      "startYear": request.startYear,
      "endYear": request.endYear,
    },
    "jobId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}