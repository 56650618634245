import { createContext } from "react";
import { BroadcastProps } from "./useBroadcast";


export interface BroadcastContextProps{
    info: (props: BroadcastProps) => void
    warning: (props: BroadcastProps) => void
    success: (props: BroadcastProps) => void
    error: (props: BroadcastProps) => void
    close: () => void
}

export const BroadcastContext = createContext<BroadcastContextProps>({
    info: function (props: BroadcastProps): void {
        throw new Error("Function not implemented.");
    },
    warning: function (props: BroadcastProps): void {
        throw new Error("Function not implemented.");
    },
    success: function (props: BroadcastProps): void {
        throw new Error("Function not implemented.");
    },
    error: function (props: BroadcastProps): void {
        throw new Error("Function not implemented.");
    },
    close: function (): void {
        throw new Error("Function not implemented.");
    }
})