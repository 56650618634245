import { ConfigProvider } from "antd"
import { PropsWithChildren } from "react"



export default (props: PropsWithChildren<{}>) => {

    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token，影响范围大
                    colorPrimary: "#65ACA3",
                    colorInfo: "#65ACA3",
                    colorSuccess: "#65ACA3",
                    borderRadius: 8,
                    // 派生变量，影响范围小
                    // colorBgContainer: '#58968E',
                    colorLink: "#58968E",
                    colorInfoBorder: "#65ACA3",
                    colorPrimaryHover: "#65ACA3",
                    colorPrimaryTextHover: "#65ACA3",
                    colorInfoTextHover: "#65ACA3",
                    colorTextPlaceholder: "#667085",
                    fontFamily: "Inter",
                    fontSize: 16,
                },
                components: {
                    "Select":{
                        optionSelectedBg: "#F9FAFB",
                    }
                }
            }}
            >
                {props.children}
        </ConfigProvider>
    )

}