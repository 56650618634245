import { Flex } from "antd"
import { PropsWithChildren } from "react"

import styles from "./Link.module.scss";

const Group = (props: PropsWithChildren<{}>) => {
    return (
        <Flex gap={24} className={styles['link-group']}>
            {props.children}
        </Flex>
    )
}


const Item = (props: PropsWithChildren<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>) => {
    return (
        <div className={styles['link-item']} {...props}>{props.children}</div>
    )
}


export default {
    Group,
    Item
}