import { createContext, useState } from "react";
import { FormFillingTaskDTO } from "../../api/FormFilling.api";


export interface FormFillingTaskContextProps{
    task?: FormFillingTaskDTO
    setTask: (task: FormFillingTaskDTO) => void
}



export const FormFillingTaskContext = createContext<FormFillingTaskContextProps>({
    setTask: function (task: FormFillingTaskDTO): void {
        throw new Error("Function not implemented.");
    }
})

export const useFormFillingTaskContext = () => {

    const [task, setTask] = useState<FormFillingTaskDTO>()

    return {
        task,
        setTask
    }

}