import axios from "axios";
import { checkCode } from "../libs/http";



export const sendEmailToResetPassword = async (email: string) => {
  const response = await axios.post(`/api/account/reset_password`, {
    email,
  });
  return response.data;

};


export const resetPasswordWithToken = async (token: string, password: string) => {
  const response = await axios.post(`/api/account/reset_password`, {
    token,
    new_password: password,
  });
  checkCode(response.data)
  return response.data;
};

export default {
    sendEmailToResetPassword,
    resetPasswordWithToken
}