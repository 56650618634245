import { useEffect, useState } from "react";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import "./HtmlViewer.scss";

export interface HtmlViewerProps {
    link: string;
}

const HtmlViewer = (props: HtmlViewerProps) => {
    const [fileContent, setFileContent] = useState<string | null>(null);

    useEffect(() => {
        const fetchFile = async () => {
            try {
                const response = await fetch(props.link);
                if (!response.ok) {
                    throw new Error("Network response was not ok.");
                }
                const data = await response.arrayBuffer();
                const blob = new Blob([data], { type: 'application/pdf' });
                const objectUrl = URL.createObjectURL(blob);
                setFileContent(objectUrl);

                // Cleanup the object URL when the component unmounts
                return () => URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error("Error fetching file:", error);
            }
        };

        fetchFile();
    }, [props.link]);

    return (
        fileContent ? (
            <div className="html_viewer">
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer fileUrl={fileContent} />
                </Worker>
                <div className="pdf_link">
                    <a href={props.link} target="_blank" rel="noopener noreferrer">Download PDF</a>
                </div>
            </div>
        ) : (
            <></>
        )
    );
};

export default HtmlViewer;
