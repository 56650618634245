import { Flex, message } from "antd"
import Screen from "../components/Screen"

import styles from "./ForgetPassword.module.scss";
import keySvg from "../login/assets/images/key-01.svg";
import arrowSvg from "../login/assets/images/arrow-left.svg";


import useGoogleLogin from "../login/components/useGoogleLogin";
import Form from "../components/form/Form";
import { useEffect, useState } from "react";
import Link from "../login/components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import useToken from "../libs/useToken";
import { useHref, useLocation, useNavigate } from "react-router-dom";
import Env from "../../Env";
import ForgetpasswordApi from "./Forgetpassword.api";
import { useFormInstance } from "../components/form/FormContext";


const {
    required,
    email
} = validators

export default () => {

    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const [token, setToken] = useState<string>()
    const formInstance = useFormInstance()

    const doSubmit = async (params: any) => {
        if(loading) return;
        setLoading(true)
        messageApi.loading({content: "Reseting", duration: 0})
        token && ForgetpasswordApi.resetPasswordWithToken(token, params['password']).then(() => {
            messageApi.destroy()
            navigate("/login")
        }).catch(e => {
            setLoading(false)
            messageApi.destroy()
            messageApi.error({content: "Reset failed", duration: 3})
        })
    }

    useEffect(() => {
        const token = new URL(window.location.href).searchParams.get("token");
        if(!token) throw new Error("Token is invalid");
        setToken(token)
    }, [])

    const isSamePassword = (value: string, values: any = {}) => {
        console.log(`isSamePassword, value: ${value}, values: ${JSON.stringify(values)}`)
        console.log("isSamePassword result: ", value === values['password'])
        return Promise.resolve(value === values['password'])
    }

    const Step2 = (
        <Form.Form instance={formInstance} onSubmit={doSubmit}>
            <Flex vertical gap={16}>
                <Flex vertical gap={24}>
                        <Form.Item 
                            label="Password" 
                            name="password"
                            rules={[
                                {validate:required, msg: "Please enter your password"},
                            ]}    
                        >
                            <Form.Password placeholder={"Create a password"}/>
                        </Form.Item>
                        <Form.Item 
                            label="Confirm Password" 
                            name="confirm_password"
                            rules={[
                                {
                                    validate:isSamePassword, 
                                    msg: "Passwords does not match",
                                },
                            ]}    
                        >
                            <Form.Password placeholder={"Confirm password"}/>
                        </Form.Item>
                    <Form.Submit >Request Password</Form.Submit>
                </Flex>
            </Flex>
        </Form.Form>
    )


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['forgetpassword']}>
                    <Flex align="center" vertical  gap={24}>
                        <div className={styles["forgetpassword__icon"]}>
                            <Icon src={keySvg} size={28}></Icon>
                        </div>
                        <Flex vertical  gap={12}>
                            <div className={styles['forgetpassword__title']}>Reset your password</div>
                            <div className={styles['forgetpassword__subtitle']}>Your password must contain a minimum of 8 characters</div>
                        </Flex>
                    </Flex>

                    <Flex vertical gap={16}>
                        {Step2}
                    </Flex>
                    
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}