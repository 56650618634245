import { Flex } from "antd"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import { StepForm } from "../CaseFillingForm"
import Icon from "../../components/icon/Icon"
import { PropsWithChildren, ReactNode, useContext, useEffect, useState } from "react"
import plusPrimarySvg from "../assets/images/plus-primary.svg"
import styles from "./AcademicBackground.module.scss"
import CaseFillingGraphsqlApi, { UndergraduateDTO } from "../../api/casefiling/AcademicBackgroundGraphsql.api"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import Env from "../../../Env"
import LoadingIcon from "../../components/icon/LoadingIcon"
import FormValidators from "../../components/form/FormValidators"
import trashPrimarySvg from "../assets/images/trash-primary.svg";
import CaseFillingApi from "../../api/CaseFilling.api"
import AcademicBackgroundGraphsqlApi from "../../api/casefiling/AcademicBackgroundGraphsql.api"
import GraduatesGraphsqlApi, { GraduateDTO } from "../../api/casefiling/GraduatesGraphsql.api"
import dayjs from "dayjs"
import validates from "../../libs/validates"

const highestDegrees = [
    "Master", 
    "Bachelor", 
    "High School", 
    "PhD", 
    "Associate", 
    "Professional", 
    "Doctorate", 
    "Postdoctoral", 
    "Secondary"
]
const HighestDegreeOptions = highestDegrees.map(it => {return {label: it, value: it.toLowerCase()}})
const graduateDegrees = [
    "Master", "Doctorate", "Postdoctoral", "PhD"
]
const GraduateDegreeOptions = graduateDegrees.map(it => {return {label: it, value: it.toLowerCase()}})
const GpaRanks = ["Top 1%", "Top 5%", "Top 10%", "Others"]
const GpaRankOptions = GpaRanks.map(it => {return {label: it, value: it}})


export default () => {

    return (
        <StepForm
            title="Academic background"
            subtitle=""
            required
        >
            <AcademicBackground/>
        </StepForm>
    )

}

interface Idx{
    id: string
    create?: boolean
    delete?: boolean
    form: Record<string, any>
    valid?: number
    btnValid?: boolean
}

const AcademicBackground =  () => {

    const formInstance = useFormInstance({autoValidate: true})
    const {clientCase} = useContext(ClientCaseContext)
    const {record, update, setStep, isFinish, setDisable} = useContext(CaseFillingFormContext)
    const [loading, setLoading] = useState<boolean>(true)
    const [undergraduateIdx, setUndergraduateIdx] = useState<Idx[]>([])
    const [graduateIdx, setGraduateIdx] = useState<Idx[]>([])
    
    useEffect(() => {
        if(record){
            CaseFillingGraphsqlApi.academicBackground(record.id).then(res => {
                // Env.DEBUG && console.log("undergraduates: ", res.undergraduates)
                // Env.DEBUG && console.log("graduates: ", res.graduates)
                
                setUndergraduateIdx(res.undergraduates.map(it => {
                    // const undergraduatesFormdata = undergraduatesConvertToFormData([it])
                    return {
                        id: it.id,
                        create: false,
                        form: it,
                    }
                }))
                
                setGraduateIdx(res.graduates.map(it => {
                    // const graduatesFormdata = graduatesConvertToFormData([it])
                    return {
                        id: it.id,
                        create: false,
                        form: it,
                    }
                }))

                formInstance.setInitial({...res.background})

                setLoading(false)
                // initialValidate(res.undergraduates, res.graduates)
            })
        }

        
    }, [])

    useEffect(() => {

        const prepareReduces1 = undergraduateIdx.filter(it => !it.create && it.valid).map(it => it.valid as number)
        console.log("idxs changed: ", undergraduateIdx, " ", prepareReduces1)
        if(prepareReduces1.length == 0) return;
        const validScore1 = prepareReduces1.reduce((o1, o2) => Math.min(o1 as number, o2 as number))

        const prepareReduces2 = graduateIdx.filter(it => !it.create && it.valid).map(it => it.valid as number)
        console.log("idxs changed: ", graduateIdx, " ", prepareReduces2)
        if(prepareReduces2.length == 0) return;
        const validScore2 = prepareReduces2.reduce((o1, o2) => Math.min(o1 as number, o2 as number))

        const validScore = Math.min(validScore1, validScore2)
        {
            if(validScore < 0){
                setDisable(CaseFilingSteps.AcademicBackground, true)
                record && 
                isFinish(CaseFilingSteps.AcademicBackground) && 
                CaseFillingApi.updateStatus(record.case_id, record.id, "ACADEMIC_BACKGROUND", false).then(() => {
                    CaseFillingApi.detail(record.case_id, record.id).then(update)
                })
            }else{
                setDisable(CaseFilingSteps.AcademicBackground, false)
            }
        }


    }, [undergraduateIdx, graduateIdx])

    useEffect(() => {
        if(formInstance.values2){
            const highestDegree = formInstance.values2['highestDegree']
            console.log("highestDegree changed: ", highestDegree)
            if(highestDegree){
                const taskId = record?.id as string 
                AcademicBackgroundGraphsqlApi.updateUndergraduates(taskId, {highestDegree: highestDegree})
            }
        }
    }, [formInstance.values2])
    


    const addForm1 = () => {
        setUndergraduateIdx(current => {
            return [
                ...current,
                {
                    id: new Date().getTime() + "",
                    create: true,
                    form: {}
                }
            ]
        })
    }

    const removeForm1 = (id: string) => {
        setUndergraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {...it, delete: true}
                }
                return it
            }).filter(it => !(it.create && it.delete)) as Idx[]
            return _current
        })
    }

    const createForm1 = (id: string, record: Record<string, any>) => {
        setUndergraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record},
                        create: false,
                        id: record['id']
                    }
                }
                return it
            })
            return _current
        })
    }

    const updateForm1 = (id: string, record: Record<string, any>) => {
        setUndergraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record}
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateForm1 = (id: string, valid: number) => {
        setUndergraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        valid
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateBtn1 = (id: string, btnValid: boolean) => {
        setUndergraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        btnValid
                    }
                }
                return it
            })
            return _current
        })
    }


    const addForm2 = () => {
        setGraduateIdx(current => {
            return [
                ...current,
                {
                    id: new Date().getTime() + "",
                    create: true,
                    form: {}
                }
            ]
        })
    }

    const removeForm2 = (id: string) => {
        setGraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {...it, delete: true}
                }
                return it
            }).filter(it => !(it.create && it.delete)) as Idx[]
            return _current
        })
    }

    const createForm2 = (id: string, record: Record<string, any>) => {
        setGraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record},
                        create: false,
                        id: record['id']
                    }
                }
                return it
            })
            return _current
        })
    }

    const updateForm2 = (id: string, record: Record<string, any>) => {
        setGraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        form: {...record}
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateForm2 = (id: string, valid: number) => {
        setGraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        valid
                    }
                }
                return it
            })
            return _current
        })
    }

    const validateBtn2 = (id: string, btnValid: boolean) => {
        setGraduateIdx(current => {
            const _current = current.map(it => {
                if(it.id === id){
                    return {
                        ...it, 
                        btnValid
                    }
                }
                return it
            })
            return _current
        })
    }

    const doSubmit = async (form: any) => {

        const taskId = record?.id as string 
        const caseId = record?.case_id as string
        return CaseFillingApi.updateStatus(caseId, taskId, "ACADEMIC_BACKGROUND", true).then(res => {
            CaseFillingApi.detail(caseId, taskId).then(update)
            setStep(CaseFilingSteps.Publications)
            setDisable(CaseFilingSteps.AcademicBackground, false)
        })
    }

    const undergraduateFormRender = () => {

        const nodes: ReactNode[] = []
        for(let i = 0; i < undergraduateIdx.length; i++){
            const idx = undergraduateIdx[i];
            if(idx.delete) continue;
            const id = idx.id;
            nodes.push(
                <UndergraduateForm
                    key={id} 
                    id={id} 
                    onRemove={() => removeForm1(id)}
                    onUpdate={(record) => updateForm1(id, record)}
                    onValidate={(valid) => validateForm1(id, valid)}
                    record={idx.form}
                    autoValidate={!idx.create}
                    onBtnValidate={(valid) => validateBtn1(id, valid)}
                    onCreated={(record) => createForm1(id, record)}
                />
                
            )
        }
        return <>{nodes}</>

    }

    const graduateFormRender = () => {

        const nodes: ReactNode[] = []
        for(let i = 0; i < graduateIdx.length; i++){
            const idx = graduateIdx[i];
            if(idx.delete) continue;
            const id = idx.id;
            nodes.push(
                <GraduateForm
                    key={id} 
                    id={id} 
                    onRemove={() => removeForm2(id)}
                    onUpdate={(record) => updateForm2(id, record)}
                    onValidate={(valid) => validateForm2(id, valid)}
                    record={idx.form}
                    autoValidate={!idx.create}
                    onBtnValidate={(valid) => validateBtn2(id, valid)}
                    onCreated={(record) => createForm2(id, record)}
                />
                
            )
        }
        return <>{nodes}</>

    }

    const valid = () => {
        const valid = validUndergraduate() && validGraduate()
        return valid
    }

    const validUndergraduate = () => {
        const forValidateIdx = undergraduateIdx.filter(it => !it.delete)
        if(!forValidateIdx || forValidateIdx.length == 0) return true
        return !forValidateIdx.find(it => !it.btnValid)
    }

    const validGraduate = () => {
        const forValidateIdx = graduateIdx.filter(it => !it.delete)
        if(!forValidateIdx || forValidateIdx.length == 0) return true
        return !forValidateIdx.find(it => !it.btnValid)
    }

    const updateHighestDegree = (highestDegrees: string) => {
        const taskId = record?.id as string 
        AcademicBackgroundGraphsqlApi.updateUndergraduates(taskId, {highestDegree: highestDegrees})
    }


    if(loading){
        return (
            <LoadingIcon/>
        )
    }else{
        return (
                <Form.Form
                    instance={formInstance}
                    showError={false}
                    onSubmit={doSubmit}
                >
                    <Flex vertical gap={48}>
                        <Flex vertical gap={32}>
                            <Form.Item
                                label="Highest academic degree"
                                name="highestDegree"
                                required
                                hiddenError
                                rules={[{validate: FormValidators.required, msg: ' '}]}
                            >
                                <Form.Select 
                                    options={HighestDegreeOptions} 
                                    // onChange={(value) => updateHighestDegree(value)}
                                    placeholder="Select one" 
                                    readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                            </Form.Item>

                            <Form.ItemGroup title="Undergraduate background">
                                <Flex vertical gap={32}>
                                    {undergraduateFormRender()}
                                </Flex>
                                {!isFinish(CaseFilingSteps.ReviewAndSubmit) &&  <AddLink onClick={addForm1}>Add more undergraduate background</AddLink>}
                            </Form.ItemGroup>

                            <Form.ItemGroup title="Graduate background">
                                <Flex vertical gap={32}>
                                    {graduateFormRender()}
                                </Flex>
                                {!isFinish(CaseFilingSteps.ReviewAndSubmit)  && <AddLink onClick={addForm2}>Add more graduate background</AddLink>}
                            </Form.ItemGroup>
                        </Flex>
                        <Form.Item nowrap label="" name="">
                            <Flex justify="flex-end">
                                <Form.Submit width={185} disabled={!valid() || isFinish(CaseFilingSteps.ReviewAndSubmit)}>Continue</Form.Submit>
                            </Flex>
                        </Form.Item>
                    </Flex>

                </Form.Form>
            )
    }
    
}

const UndergraduateForm = (
    props: {
        id: string, 
        onRemove: () => void, 
        record?: Record<string, any>, 
        onUpdate: (updated: Record<string, any>) => void, 
        onValidate: (valid: number) => void
        onBtnValidate: (valid: boolean) => void
        autoValidate: boolean
        onCreated: (updated: Record<string, any>) => void
    }
) => {

    const {update, setStep, isFinish, record: task} = useContext(CaseFillingFormContext)
    const formInstance = useFormInstance({autoValidate: props.autoValidate})
    const {
        id,
        record,
        onRemove,
        onUpdate,
        onValidate,
        onBtnValidate,
        autoValidate,
        onCreated
    } = props

    const {
        values2,
        errors2,
        disabled
    } = formInstance

    const [recordId, setRecordId] = useState<string>()
    useEffect(() => {
        record && setRecordId(record.id)
        autoValidate && formInstance.setInitial(record) //TODO auto validate
    }, [])

    useEffect(() => {
        if(values2){
            if(validates.isDeepEqual(values2, props.record)){
                console.log("值未发生变化。。。")
                return;
            }
            if(task){
                if(!recordId && !validates.isEmpty(values2)){
                    AcademicBackgroundGraphsqlApi.createUndergraduate(task?.id, values2 as any).then(it => {
                        setRecordId(it.id)
                        onCreated(it)
                    })
                }else if(recordId){
                    AcademicBackgroundGraphsqlApi.editUndergraduate({...values2, id: recordId} as any).then(() => {
                        onUpdate(values2)
                    })
                    
                }
            }
            onUpdate(values2)
        }
    }, [values2])

    useEffect(() => {
        if(errors2){
            onValidate(validates.isEmpty(errors2)?1:-1) 
        }
    }, [errors2])

    useEffect(() => {
        onBtnValidate(!disabled)
    }, [disabled])

    const doRemove = () => {
        recordId && AcademicBackgroundGraphsqlApi.deleteUndergraduate(recordId)
        onRemove()
    }


    return (
        <Form.Form 
            instance={formInstance}
            >
            <Flex gap={16} vertical key={id}>
            { !isFinish(CaseFilingSteps.ReviewAndSubmit) && <Flex justify="flex-end">
                    <Icon src={trashPrimarySvg} size={24} onClick={() => doRemove()}></Icon>
                </Flex>}
                <Form.Item
                        label="Undergraduate college/University"
                        name={`universityName`}
                        required
                        hiddenError
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                        lazy
                    >
                        <Form.Input placeholder="Please enter your undergraduate college/university" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Undergraduate GPA"
                        name={`gpa`}
                        hiddenError
                        lazy
                    >
                        <Form.Input placeholder="Ex. 4.0" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Undergraduate GPA rank"
                        name={`rank`}
                        hiddenError
                        lazy
                    >
                        <Form.Select options={GpaRankOptions} allowClear placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Start year"
                        name={`startYear`}
                        hiddenError
                        required
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                        lazy
                    >
                        <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="End year"
                        name={`endYear`}
                        hiddenError
                        required
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                        lazy
                    >
                        <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
            </Flex>
        </Form.Form>
        
    )

}

const GraduateForm = (
    props: {
        id: string, 
        onRemove: () => void, 
        record?: Record<string, any>, 
        onUpdate: (updated: Record<string, any>) => void, 
        onValidate: (valid: number) => void
        onBtnValidate: (valid: boolean) => void
        autoValidate: boolean
        onCreated: (updated: Record<string, any>) => void
    }
) => {

    const {update, setStep, isFinish, record: task} = useContext(CaseFillingFormContext)
    const formInstance = useFormInstance({autoValidate: props.autoValidate})
    const {
        id,
        record,
        onRemove,
        onUpdate,
        onValidate,
        onBtnValidate,
        autoValidate,
        onCreated
    } = props

    const {
        values2,
        errors2,
        disabled
    } = formInstance

    const [recordId, setRecordId] = useState<string>()
    useEffect(() => {
        record && setRecordId(record.id)
        autoValidate && formInstance.setInitial(record)
    }, [])

    useEffect(() => {
        if(values2){
            if(validates.isDeepEqual(values2, props.record)){
                console.log("值未发生变化。。。")
                return;
            }
            if(task){
                if(!recordId && !validates.isEmpty(values2)){
                    GraduatesGraphsqlApi.create(task?.id, values2 as any).then(it => {
                        setRecordId(it.id)
                        onCreated(it)
                    })
                }else if(recordId){
                    GraduatesGraphsqlApi.edit({...values2, id: recordId} as any).then(() => {
                        onUpdate(values2)
                    })
                    
                }
            }
            onUpdate(values2)
        }
    }, [values2])

    useEffect(() => {
        if(errors2){
            onValidate(validates.isEmpty(errors2)?1:-1) 
        }
    }, [errors2])

    useEffect(() => {
        onBtnValidate(!disabled)
    }, [disabled])

    const doRemove = () => {
        recordId && GraduatesGraphsqlApi.remove(recordId)
        onRemove()
    }


    return (
        <Form.Form instance={formInstance}>
            <Flex gap={16} vertical key={id}>
            { !isFinish(CaseFilingSteps.ReviewAndSubmit) && <Flex justify="flex-end">
                    <Icon src={trashPrimarySvg} size={24} onClick={() => doRemove()}></Icon>
                </Flex>}
                <Form.Item
                        label="Degree type"
                        name={`degreeType`}
                        required
                        hiddenError
                        lazy
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                    >
                        <Form.Select placeholder="Select one" options={GraduateDegreeOptions} readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Graduate college/University"
                        name={`universityName`}
                        required
                        hiddenError
                        lazy
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                    >
                        <Form.Input placeholder="Please enter your graduate college/university" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Graduate department/school"
                        name={`departmentName`}
                        hiddenError
                        lazy
                    >
                        <Form.Input placeholder="Please enter your graduate department/school" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Graduate gpa"
                        name={`gpa`}
                        hiddenError
                        lazy
                    >
                        <Form.Input placeholder="Ex. 4.0" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="Graduate GPA rank"
                        name={`rank`}
                        hiddenError
                        lazy
                    >
                        <Form.Select options={GpaRankOptions} placeholder="Select one" allowClear readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)} />
                    </Form.Item>
                    <Form.Item
                        label="Start year"
                        name={`startYear`}
                        hiddenError
                        required
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                        lazy
                    >
                        <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
                    <Form.Item
                        label="End year"
                        name={`endYear`}
                        hiddenError
                        required
                        rules={[{validate: FormValidators.required, msg: ' '}]}
                        lazy
                    >
                        <Form.DatePicker minDate={dayjs('1900')} picker="year" placeholder="Select one" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)}/>
                    </Form.Item>
            </Flex>
        </Form.Form>
        
    )

}

const AddLink = (props: PropsWithChildren<{onClick?: () => void}>) => {

    return (
        <Flex align="center" gap={8} className={styles['add-link']} onClick={props.onClick}>
            <Icon src={plusPrimarySvg} size={24}></Icon>
            {props.children}
        </Flex>
    )

}


export const isNotEmpty = (data: Record<string, any> = {}) => {

    return Object.entries(data).find(([key, value]) => {
        return value && value !== ""
    })
    
}
