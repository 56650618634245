
import { ReactNode, useState } from "react";
import styles from "./Mask.module.scss";
import { Flex, FlexProps } from "antd";

export type MaskProps = {
    // children: ReactNode
} & FlexProps

export default () => {
    
}

export const useMask = () => {

    const [open, setOpen] = useState<boolean>(false)
    const [children, setChildren] = useState<ReactNode>()

    const render = (
        <div className={[styles['mask'], open?styles['mask_opened']:''].join(" ")}>
            <Flex align="center" className={styles["mask__container"]}>
                {open && children}
            </Flex>
        </div>
    )

    const show = (children: ReactNode) => {
        setChildren(children)
        setOpen(true)
    }
    const close = () => {
        setOpen(false)
        setChildren(undefined)
    }


    return {
        render,
        show,
        close
    }

}
