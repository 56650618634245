import { SelectProps } from "antd";

import formData from "../assets/data/processing_time_dropdown.json"
import Env from "../../../Env";

interface FormOptions{
  label: string
  value: string
  category: {[key: string]: FormCategoryOptions}
//   centerService: {[key: string]: string}
} 
interface FormCategoryOptions{
    label: string
    value: string
    centerService: {[key: string]: string}
}

let formOptions: FormOptions[] = [];
(() => {
    
    let raw: any[] = formData
    let _form: {[key: string]: any} = {}
    for(const record of raw){
        const formName = record['form_name']
        const categoryName = record['form_type']
        const centerSeriviceName = record['office_code']
        if(!_form[formName]){
            _form[formName] = {label: record['form_name_long'], value: formName, category: {}}
        }

        let categoryValue: FormCategoryOptions = _form[formName]['category'][categoryName]
        if(!categoryValue){
            categoryValue = {label: record['form_category'], value: record['form_type'], centerService: {}}
            _form[formName]['category'][categoryName] = categoryValue
        }
        categoryValue['centerService'][centerSeriviceName] = record['field_office_or_service_center']
    }
    formOptions = Object.entries(_form).sort((a, b) => a[0].localeCompare(b[0])).map(it => it[1])
    Env.DEBUG && console.log("formOptions: ", formOptions)
})()

const $formCategory = (form: string): SelectProps['options'] => {
    const category = (formOptions.find(it => it.value === form)?.category??{})
    return Object.entries(category).sort((a, b) => a[0].localeCompare(b[0])).map(it => it[1])
}

const $formCenterService = (form: string, category: string): SelectProps['options'] => {
    const currentForm = formOptions.find(it => it.value === form);
    const categoryObj = currentForm?.['category'][category]
    const centerService = categoryObj?.['centerService']??{}
    return Object.entries(centerService).sort((a, b) => a[0].localeCompare(b[0])).map(it => {return {label: it[1], value: it[0]}})
}


export default {
    form: formOptions,
    formCategory: $formCategory,
    formCenterService: $formCenterService
}