

import { Col, Flex, Row, SelectProps } from "antd"
import styles from "./CheckStatus.module.scss"
import Button from "../../components/Button"
import { useContext, useEffect, useState } from "react"
import { ClientCaseContext } from "../context/ClientCaseContext"
import { ChatScreenContext } from "../context/ChatScreenContext"
import Form from "../../components/form/Form"
import { useFormInstance } from "../../components/form/FormContext"
import Options from "../data/Options"
import Env from "../../../Env"
import VisaOptions from "../data/VisaOptions"
import ClientApi, { CaseDTO, CheckStatusMetadata} from "../../api/Client.api"
import FormValidators from "../../components/form/FormValidators"
import useBroadcast from "../../components/broadcast/useBroadcast"
import ChatApi from "../../api/Chat.api"

const checkStatusSystemText = "Kindly provide USCIS receipt number of your client to enable automatic tracking of the case status. Additionally, you may enter details for the Form, Form Category, and the specific Field Office or Service Center. This information will assist us in providing an estimated processing time for the case."
const visaBulletinSystemText = "Please provide us with the priority date, place of birth, and type of immigration for your client. Once we have this information, we can automatically track the case status using the updated USCIS Visa Bulletin."


export default () => {

    const {clientCase} = useContext(ClientCaseContext)
    const [activateModuleIdx, setActivateModuleIdx] = useState<number>(0)

    return (
        <div className={styles['check-status']}>
            <Flex vertical gap={32}>
                <Flex gap={8} className={styles['check-status__opts']}>
                        <Button.WhiteBg focus={activateModuleIdx == 0} onClick={() => setActivateModuleIdx(0)}>Check Status</Button.WhiteBg>
                        <Button.WhiteBg focus={activateModuleIdx == 1} onClick={() => setActivateModuleIdx(1)}>Visa Bulletin</Button.WhiteBg>
                </Flex>
                {
                    activateModuleIdx == 0
                    ?
                    <CheckStatus/>
                    :
                    <VisaBulletion/>
                }
                
            </Flex>
        </div>
    )

}


const CheckStatus = () => {

    const {checkStatus} = useContext(ClientCaseContext)
    const {pin, setLoading} = useContext(ChatScreenContext)
    const [next, setNext] = useState<boolean>()
    const {error} = useBroadcast()
    
    useEffect(() => {
        if(!checkStatus){
            pin({
                author: "system", 
                payload: checkStatusSystemText, 
                type: "text"
            }, true)
        }else{
            setLoading(true)
            ChatApi.messages({chat_session_id: checkStatus.chat_session_id}).then(({messages}) => {
                if(messages && messages.length > 0){
                    pin(messages, true);
                }else{
                    pin({
                        author: "system", 
                        payload: checkStatusSystemText, 
                        type: "text"
                    }, true)
                }
                setLoading(false);
            }).catch((e) => {
                error(e);
                setLoading(false)
            })
        }
    }, [checkStatus])

    const CheckStatusWelcome = () => {

        return (
            <Flex vertical gap={32} className={styles["check-status__welcome"]}>
                <div>To track a client's case, provide the USCIS receipt number and specify the form, category, and processing location.</div>
                <Button.Primary width={160} style={{'alignSelf': 'flex-end'}} onClick={() => setNext(true)}>Start</Button.Primary>
            </Flex>
        )
    }

    return (
        checkStatus || next
        ?
        <CheckStatusForm/>
        :
        <CheckStatusWelcome/>
    )
}

const VisaBulletion = () => {

    const {visaBulletin} = useContext(ClientCaseContext)
    const {pin, setLoading} = useContext(ChatScreenContext)
    const [next, setNext] = useState<boolean>()
    const {error} = useBroadcast()
    
    useEffect(() => {
        if(!visaBulletin){
            pin({
                author: "system", 
                payload: visaBulletinSystemText, 
                type: "text"
            }, true)
        }else{
            setLoading(true)
            ChatApi.messages({chat_session_id: visaBulletin.chat_session_id}).then(({messages}) => {
                if(messages && messages.length > 0){
                    pin(messages, true);
                }else{
                    pin({
                        author: "system", 
                        payload: checkStatusSystemText, 
                        type: "text"
                    }, true)
                }
                setLoading(false)
            }).catch((e) => {
                error(e);
                setLoading(false)
            })
        }
    }, [])

    const VisaBulletinWelcome = () => {

        return (
            <Flex vertical gap={32} className={styles["visa-bulletin__welcome"]}>
                <div>Visa Bulletin refers to a system that updates priority dates and case statuses for visa applicants based on their nationality and visa type.</div>
                <Button.Primary width={160} style={{'alignSelf': 'flex-end'}} onClick={() => setNext(true)}>Start</Button.Primary>
            </Flex>
        )
    }

    return (
        visaBulletin || next
        ?
        <VisaBulletinForm/>
        :
        <VisaBulletinWelcome/>
    )
}


const CheckStatusForm = () => {

    const {pin} = useContext(ChatScreenContext)
    const formInstance = useFormInstance()
    const {
        values,
        setErrors
    } = formInstance
    const {clientCase, checkStatus, setCheckStatusMetadata} = useContext(ClientCaseContext)
    const [formOptions, setFormOptions] = useState<SelectProps['options']>(Options.form)
    const [formCategoryOptions, setFormCategoryOptions] = useState<SelectProps['options']>([])
    const [formServiceCenterOptions, setFormServiceCenterOptions] = useState<SelectProps['options']>([])
    const {error} = useBroadcast()

    useEffect(() => {
        Env.DEBUG && console.log("checkStatus changed:", checkStatus)
        formInstance.setInitial(checkStatus?.metadata)
    }, [checkStatus])

    useEffect(() => {
        // console.log("value changed:", formInstance.values)
        const formName = formInstance.values?.['form_name']
        const formCategory = formInstance.values?.['form_category']
        const serviceCenter = formInstance.values?.['service_center']
        if(!formName){
            setFormCategoryOptions([])
            setFormServiceCenterOptions([])
        }else{
            setFormCategoryOptions(Options.formCategory(formName))
            if(!formCategory){
                setFormServiceCenterOptions([])
            }else{
                setFormServiceCenterOptions(Options.formCenterService(formName, formCategory))
            }
        }
    }, [values])


    const doSubmit = (form: CheckStatusMetadata) => {
        return ClientApi
            .updateCaseStatus((clientCase as CaseDTO).case_id, form)
            .then(({messages}) => {
                setCheckStatusMetadata(form)
                Env.DEBUG && console.log("updated check status, messages: ", messages)
                pin(messages)
            }).catch((e) => {
                Env.DEBUG && console.log("something get wrong!")
                if(e instanceof Error && e.message === "30002")
                setErrors("receipt_number", "Invalid receipt number")
                error(e)
            })
    }

    return (
        <Form.Form
            instance={formInstance}
            showError={true}
            onSubmit={doSubmit}
        >
            <Flex vertical gap={32}>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item 
                            name="receipt_number" 
                            label="USCIS receipt number"
                            required
                            rules={[{validate: FormValidators.required, msg: "USCIS receipt number"}]}
                        >
                            <Form.Input placeholder="WAC1234567890"></Form.Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="form_name" 
                            label="Form"
                            required
                            rules={[{validate: FormValidators.required, msg: "Form"}]}
                        >
                            <Form.Select placeholder="Select One" options={formOptions}></Form.Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="form_category" 
                            label="Form category"
                            required
                            rules={[{validate: FormValidators.required, msg: "Form category"}]}
                        >
                            <Form.Select placeholder="Select One" options={formCategoryOptions}></Form.Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="service_center" 
                            label="Form office or service center"
                            required
                            rules={[{validate: FormValidators.required, msg: "Form office or service center"}]}
                        >
                            <Form.Select placeholder="Select One" options={formServiceCenterOptions}></Form.Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Flex justify="flex-end" gap={12}>
                    <Button.WhiteBg width={128}>Cancel</Button.WhiteBg>
                    <Form.Submit width={128}>Update</Form.Submit>
                </Flex>
            </Flex>

        </Form.Form>
    )

}

const VisaBulletinForm = () => {

    const {pin} = useContext(ChatScreenContext)
    const formInstance = useFormInstance()
    const {
        values
    } = formInstance
    const {clientCase, visaBulletin, setVisaBulletinMetadata} = useContext(ClientCaseContext)
    const [visaNationalities] = useState<SelectProps['options']>(VisaOptions.nationalities)
    const [visaTypes, setVisaTypes] = useState<SelectProps['options']>([])
    const {error} = useBroadcast()

    useEffect(() => {
        console.log("visaBulletion initialized:", visaBulletin)
        formInstance.setInitial(visaBulletin?.metadata)
    }, [visaBulletin])

    useEffect(() => {
        const nationality = formInstance.values?.['nationality']
        if(!nationality){
            setVisaTypes([])
        }else{
            setVisaTypes(VisaOptions.visaTypes(nationality))
        }
    }, [values])

    const onSubmit = async (request: any) => {
        Env.DEBUG && console.log("on visa bulletin form submit: ", request)
        return ClientApi
            .updateVisaBulletin((clientCase as CaseDTO).case_id, request)
            .then(({messages}) => {
                setVisaBulletinMetadata(request);
                Env.DEBUG && console.log("updated visaBulletin, messages: ", messages);
                pin(messages);
            }).catch((e) => error(e))
    }

    return (
        <Form.Form
            instance={formInstance}
            showError={false}
            onSubmit={onSubmit}
        >
            <Flex vertical gap={32}>
                <Row>
                    <Col span={24}>
                        <Form.Item 
                            name="priority_date" 
                            label="Priority date"
                            required
                        >
                            <Form.DatePicker 
                                placeholder="Choose priority date"
                                format={"YYYY/MM/DD"}    
                            ></Form.DatePicker>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="nationality" 
                            label="Choose place of birth"
                            required
                        >
                            <Form.Select options={visaNationalities} placeholder={"Choose place of birth"}></Form.Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item 
                            name="visa_type" 
                            label="Visa type"
                            required
                        >
                            <Form.Select options={visaTypes} placeholder="Choose Visa type"></Form.Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Flex justify="flex-end" gap={12}>
                    <Button.WhiteBg width={128}>Cancel</Button.WhiteBg>
                    <Form.Submit width={128}>Update</Form.Submit>
                </Flex>
            </Flex>

        </Form.Form>
    )

}