import { Flex } from "antd"
import Button from "../components/Button"
import { StepForm } from "./FormFillingForm"
import downloadSvg from "./assets/images/download-01.svg"
import Icon from "../components/icon/Icon"
import { useContext } from "react"
import { ClientCaseContext } from "../clientmanagement/context/ClientCaseContext"
import FormFillingApi from "../api/FormFilling.api"
import { FormFillingFormContext } from "./FormFillingFormContext"
import Env from "../../Env"



export default (props: {onFinish: (forceRefresh: boolean) => void}) => {

    const {clientCase} = useContext(ClientCaseContext)
    const {data: fillingForm} = useContext(FormFillingFormContext)

    const doDownload = () => {
        if(clientCase && fillingForm){
            const case_id = clientCase.case_id
            FormFillingApi.download(case_id, fillingForm.id).then(res => {
                Env.DEBUG && console.log("download, res: ", res)
                window.open(res.download_url, "_blank")
            })
        }
    }

    return (
        <StepForm
            title="Download your forms"
            subtitle="Your AI form filing job has been completed. Please download the completed forms using the provided link and ensure all information is correct before submission."
        >
            <Button.WhiteBg onClick={doDownload}>
                <Flex align="center" gap={8}>
                    <Icon src={downloadSvg} size={20}></Icon>
                    Download your forms
                </Flex>
            </Button.WhiteBg>
            <Flex justify="flex-end" style={{paddingTop:48}}>
                <Button.Primary width={185} onClick={() => {props.onFinish(true)}}>Finish and return</Button.Primary>
            </Flex>
        </StepForm>
    )
}