import { createContext, useState } from "react";


export interface CaseFillingTaskContextProps{
}



export const CaseFillingTaskContext = createContext<CaseFillingTaskContextProps>({
    
})

export const useFormFillingTaskContext = () => {

}