
import { Flex } from "antd";
import styles from  "./FormFilling.module.scss";
import infoCircleSvg from "./assets/images/info-circle.svg";
import arrowRightSvg from "./assets/images/arrow-right.svg";
import plusWhiteSvg from "./assets/images/plus-white.svg";
import NoDocumentSvg from "./assets/images/NoDocuments.png";
import { useTableInstance } from "../components/table/TableContext";
import Icon from "../components/icon/Icon";
import Button from "../components/Button";
import Table, { TableProps } from "../components/table/Table";
import { useMask } from "../components/mask/Mask";
import FormFillingForm from "./FormFillingForm";
import FormFillingApi from "../api/FormFilling.api";
import { useContext, useEffect } from "react";
import { ClientCaseContext } from "../clientmanagement/context/ClientCaseContext";
import Env from "../../Env";
import { FormFillingTaskContext, useFormFillingTaskContext } from "./context/FormFillingTaskContext";
import useBroadcast from "../components/broadcast/useBroadcast";
import { toHumanDateTime } from "../libs/datetime";
import downloadSvg from "./assets/images/download-01.svg";
import ProtectPage from "../components/layout/ProtectPage";


export default () => {

    const tableInstance = useTableInstance()
    const {render: maskRender, close, show} = useMask()
    const {clientCase} = useContext(ClientCaseContext)
    const taskContext = useFormFillingTaskContext()
    const {error} = useBroadcast()
    const {task, setTask} = taskContext

    const dataLoader: TableProps['dataLoader'] = (params) => {
        return FormFillingApi.list(clientCase?.case_id as string, {page_size: params.pageSize??100}).then(res => {
            Env.DEBUG && console.log("form filling tasks: ", res)
            if(params.page == 1 && res.size > 0 && res.tasks[0].status !== "COMPLETED"){
                setTask(res.tasks[0])
                return res.tasks.slice(1)
            }else{
                setTask(undefined)
            }
            return res.tasks
        }).catch((e) => {
            error(e)
            return []
        })        
    }

    const doDownload = (task_id: string) => {
        if(clientCase){
            const case_id = clientCase.case_id
            FormFillingApi.download(case_id, task_id).then(res => {
                Env.DEBUG && console.log("download, res: ", res)
                window.open(res.download_url, "_blank")
            })
        }
        
    }

    const emptyRender = (
        <Flex vertical align="center" gap={24} className={styles["form-filling__table__empty"]} >
            <Flex vertical align="center" gap={16}>
                <Icon src={NoDocumentSvg} size={150} height={102} padding={0}></Icon>
                <div className={styles["form-filling__table__empty__title"]}>No form history available</div>
                <div className={styles["form-filling__table__empty__subtitle"]}>No forms submitted yet. Start filling out and submitting forms to view their history here, track your submissions, and access previous entries.</div>
            </Flex> 
            <Button.Primary onClick={() => show(<FormFillingForm onClose={(forceRefresh) => {
                close()
                forceRefresh && tableInstance.refresh()
            }}/>)} disabled={!!task}>
                <Flex gap={8} align="center">
                    <Icon src={plusWhiteSvg} size={20}></Icon>
                    Create new
                </Flex>
            </Button.Primary>
        </Flex>
    )

    const headerRender = (
        <Flex className={styles['form-filling__table__header']} align="center" justify="space-between">
            Recent forms history
            {
                (tableInstance.data && tableInstance.data.length > 0)
                &&
                <Button.Primary onClick={() => show(<FormFillingForm onClose={(forceRefresh) => {
                    close()
                    forceRefresh && tableInstance.refresh()
                }}/>)} disabled={!!task}>
                    <Flex align="center" gap={8}>
                        <Icon src={plusWhiteSvg} size={20}></Icon>
                        Create
                    </Flex>
                </Button.Primary>
            }
        </Flex>
    )

    const cols: TableProps['cols'] = [
        {
            label: "Form name",
            name: "task_name"
        },
        {
            label: "Included forms",
            name: "form_types",
            width: 292,
            render(record, value, idx) {
                return (value??[]).join(", ")
            },
        },
        {
            label: "Modified",
            name: "updated_at",
            width: 292,
            render(record, value, idx) {
                return toHumanDateTime(value)
            },
        },
        {
            label: "",
            name: "",
            width: 32,
            render(record, value, idx) {
                return <Icon src={downloadSvg} size={20} padding={10} hoverable onClick={() => doDownload(record['id'])}/>
            }
        }
    ]

    useEffect(() => {
        tableInstance.refresh()
    }, [])

    const showCurrentTaskForm = () => {
        show(<FormFillingForm initial={task} onClose={(forceRefresh) => {
            close()
            if(forceRefresh) tableInstance.refresh()
        }}/>)
    }


    return (
        <FormFillingTaskContext.Provider value={taskContext}>
            <ProtectPage className={styles['form-filling']}>
                <Flex vertical gap={10} >
                        {
                            task
                            &&
                            <Flex className={styles['form-filling__processing']} align="flex-start">
                                <Icon src={infoCircleSvg} size={20} padding={10}></Icon>
                                <Flex vertical gap={12} style={{flex: 1}}>
                                    <div className={styles['form-filling__processing__title']}>You currently have an active form that is still being processed. Please complete your existing form before starting a new one.</div>
                                    <Flex gap={8} align="center" onClick={showCurrentTaskForm}>
                                        <div className={styles['form-filling__processing__link']}>Continue editing</div>
                                        <Icon src={arrowRightSvg} size={20}></Icon>
                                    </Flex>
                                </Flex>
                            </Flex>
                        }
                        
                        <div className={styles['form-filling__table']}>
                            <Table
                                instance={tableInstance}
                                dataLoader={dataLoader}
                                cols={cols}
                                emptyRender={emptyRender}
                                caption={headerRender}
                                showHeader={tableInstance.data && tableInstance.data.length > 0}
                                
                            ></Table>
                        </div>
                        {maskRender}
                    </Flex>
            </ProtectPage>
        </FormFillingTaskContext.Provider>
    )
}