import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"

export interface LawyerDTO{
    id: string
    apt_address: string
    bar_number: string
    city: string
    country: string
    daytime_phone: string
    email_address: string
    family_name: string
    fax_number: string
    given_name: string
    license_number: string
    mobile_phone: string
    org_name: string
    phone_extension: string
    state: string
    street_address: string
    uscis_acc_number: string
    zip_code: string
}

const list = async (request: {page: number, limit: number}) => {

    const {
        page = 1,
        limit = 10
    } = request??{}

    const res = (await axios.get(
        `/api/lawyer_profiles?page=${page}&limit=${limit}`
    )).data as HttpResponse<LawyerDTO[]>
    checkCode(res)
    return res.data
}

const create = async (request: any) => {
    const res = (await axios.post(
        `/api/lawyer_profiles`,
        request
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

const remove = async (lawyerId: string) => {
    const res = (await axios.delete(
        `/api/lawyer_profiles/${lawyerId}`
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

const update = async (id: string, request: any) => {
    const res = (await axios.patch(
        `/api/lawyer_profiles/${id}`,
        request
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

const detail = async (lawyerId: string) => {
    const res = (await axios.get(
        `/api/lawyer_profiles/${lawyerId}`
    )).data as HttpResponse<{lawyer_profile: LawyerDTO}>
    checkCode(res)
    return res.data.lawyer_profile
}

export default {
    list,
    create,
    remove,
    update,
    detail
}