import { Flex } from "antd"
import styles from "./FormItemLabel.module.scss"


export default (props: {title: string, subTitle: string}) => {

    const {
        title,
        subTitle
    } = props

    return (
        <Flex vertical gap={4}>
            <div className={styles['form__item__label__title']}>{title}</div>
            <div className={styles['form__item__label__subtitle']}>{subTitle}</div>
        </Flex>
    )
}