import { message } from "antd"



export default () => {

    const [messageApi, contextHolder] = message.useMessage()

    const error = (content: string) => {
        messageApi.error({content})
    }

    const loading = (content: string) => {
        messageApi.loading({content})
    }

    return {
        render: contextHolder,
        error,
        api: messageApi
    }

}