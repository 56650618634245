import axios from "axios"
import { HttpResponse, checkCode } from "../libs/http"
import { CaseDTO } from "./Client.api"

export interface FormFillingCreateRequest{
    case_id: number
}

export type FormType = "I-765" | "I-131" | "I-140" | "G-28"
export type TaskStatus = "SELECT_FORM" | "UPLOAD_DOC" | "REVIEW_CLIENT_INFO" | "CHOOSE_LAWYER_PROFILE" | "COMPLETED"
export type DocumentType = "ID" | "BIRTH_CERTIFICATE" | "VISA" | "ADMISSION"
export interface ProofDoc{
    category: DocumentType
    content_type: string
    created_at: string
    id: string
    name: string
    size: number
    task_id: string
    updated_at: string
}
export interface FormFillingTaskDTO{
    id: string
    case_id: string
    created_at: string
    form_types: FormType[]
    lawyer_profile_id?: string
    status: TaskStatus
    task_name: string
    updated_at: string
    proof_docs: ProofDoc[]
    client_info: ClientInfoDTO
}

export interface FormFillingListRequest{
    offset?: number
    page_size?: number
}

export interface FormFillingPagination{
    offset: number
    size: number
    tasks: FormFillingTaskDTO[]
}

export interface ClientInfoDTO{
    first_name: string
    last_name: string
    middle_name: string
    date_of_birth: string
    gender: string
    country_of_nationality: string
    country_of_birth: string
    state_of_birth: string
    city_of_birth: string
    alien_registration_number: string
    address_not_in_us: ClientInfoAddressDTO
    address_in_us: ClientInfoAddressDTO
}

export interface ClientInfoAddressDTO{
    street_address: string
    additional_address: string
    city: string
    state: string
    country: string
    zipcode: string

}

const create = async (case_id: string, props: FormFillingCreateRequest) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks`,
        props
    )).data as HttpResponse<FormFillingTaskDTO>
    checkCode(res)
    return res.data
}

const update = async (case_id: string, taskId: string, props: FormFillingCreateRequest) => {
    const res = (await axios.patch(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}`,
        props
    )).data as HttpResponse<FormFillingTaskDTO>
    checkCode(res)
    return res.data
}

const list = async (case_id: string, props: FormFillingListRequest = {}) => {

    const res = (await axios.get(
        `/api/cases/${case_id}/filling_form_tasks?offset=${props.offset??0}&page_size=${props.page_size??10}`
    )).data as HttpResponse<FormFillingPagination>
    checkCode(res)
    return res.data

}

export interface UploadTokenGetRequest{
    name: string
    type: string
    metadata: any
}

export interface UploadTokenDTO{
    file_id: string
    upload_args: {
        url: string
        fields: {[key: string]: string}
    }
}

export interface DownloadDTO{
    download_url: string
}

const getUploadToken = async (client_id: string, request: UploadTokenGetRequest) => {
    const res = (await axios.post(
        `/api/clients/${client_id}/files`,
        request
    )).data as HttpResponse<UploadTokenDTO>
    checkCode(res)
    return res.data
}

const updateDocument = async (caseId: string, taskId: string, request: {file_category: DocumentType, file_id: string}) => {
    const res = (await axios.post(
        `/api/cases/${caseId}/filling_form_tasks/${taskId}/documents`,
        request
    )).data as HttpResponse<ProofDoc>
    checkCode(res)
    return res.data
}

const deleteDocument = async (caseId: string, taskId: string, file_id: string) => {
    const res = (await axios.delete(
        `/api/cases/${caseId}/filling_form_tasks/${taskId}/documents/${file_id}`
    )).data as HttpResponse<any>
    checkCode(res)
    return res.data
}

const detail = async (case_id:string, taskId: string) => {
    const res = (await axios.get(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}`
    )).data as HttpResponse<FormFillingTaskDTO>
    checkCode(res)
    return res.data
}

const parseDocs = async (case_id:string, taskId: string) => {

    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/parse_docs`
    )).data as HttpResponse<ClientInfoDTO>
    checkCode(res)
    return res.data
}

const updateClientInfo = async (case_id:string, taskId: string, clientInfo: ClientInfoDTO) => {
    const res = (await axios.patch(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/client_info`,
        clientInfo
    )).data as HttpResponse<ClientInfoDTO>
    checkCode(res)
    return res.data
}

const confirmClientInfo = async (case_id: string, taskId: string) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/confirm_client_info`
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

const chooseLawyer = async (case_id: string, taskId: string, id?: string) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/choose_lawyer_profile`,
        {
            action: "save",
            "lawyer_profile_id": id
        }
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

/**
 * 
 * @param case_id 
 * @param taskId 
 * @param id lawyer id
 * @returns 
 */
const confirmLawyer = async (case_id: string, taskId: string, id?: string) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/choose_lawyer_profile`,
        {
            action: "proceed",
            "lawyer_profile_id": id
        }
    )).data as HttpResponse<void>
    checkCode(res)
    return res.data
}

const download = async (case_id:string, taskId: string) => {
    const res = (await axios.post(
        `/api/cases/${case_id}/filling_form_tasks/${taskId}/download`
    )).data as HttpResponse<DownloadDTO>
    checkCode(res)
    return res.data
}

export default {
    create,
    update,
    list,
    getUploadToken,
    updateDocument,
    detail,
    parseDocs,
    updateClientInfo,
    confirmClientInfo,
    chooseLawyer,
    confirmLawyer,
    download,
    deleteDocument
}