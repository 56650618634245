
import { Flex } from "antd";
import styles from  "./CaseFilling.module.scss";
import plusWhiteSvg from "./assets/images/plus-white.svg";
import NoDocumentSvg from "./assets/images/NoDocuments.png";
import { useTableInstance } from "../components/table/TableContext";
import Icon from "../components/icon/Icon";
import Button from "../components/Button";
import Table, { TableProps } from "../components/table/Table";
import { useMask } from "../components/mask/Mask";
import { useContext, useEffect } from "react";
import { ClientCaseContext } from "../clientmanagement/context/ClientCaseContext";
import Env from "../../Env";
import useBroadcast from "../components/broadcast/useBroadcast";
import { toHumanDateTime } from "../libs/datetime";
import editSvg from "./assets/images/edit.svg";
import ProtectPage from "../components/layout/ProtectPage";
import { CaseFillingTaskContext } from "./context/CaseFillingTaskContext";
import CaseFillingForm from "./CaseFillingForm";
import CaseFillingApi, { CaseFilingStatus, CaseFilingTaskDTO, CaseFilingTaskStatus } from "../api/CaseFilling.api";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { CaseDTO } from "../api/Client.api";


export default () => {

    const tableInstance = useTableInstance()
    const {
        refresh
    } = tableInstance
    const {render: maskRender, close, show} = useMask()
    const {clientCase, client} = useContext(ClientCaseContext)
    const {error} = useBroadcast()
    const loaderData = useRouteLoaderData("clientmanagement") as any
    const navigate = useNavigate()

    useEffect(() => {
        refresh()
    }, [])

    useEffect(() => {
        Env.DEBUG && console.log("moduleId changed: ", loaderData)
        const caseId = loaderData['caseId']
        const taskId = loaderData['moduleId']
        if(taskId !== "0"){
            CaseFillingApi.detail(caseId, taskId).then(task => doEdit2(task))
        }
    }, [loaderData])

    const dataLoader: TableProps['dataLoader'] = async (params) => {
        const caseId = clientCase?.case_id as string
        return CaseFillingApi.list(caseId, params)
    }

    const openCreateForm = () => {
        show(<CaseFillingForm onClose={doClose} onChange={refresh}/>) 
    }

    const doEdit = async (task: CaseFilingTaskDTO) => {
        navigate(`/workbench/clientmanagement/${client?.client_id}/${task.case_id}/2/${task.id}`)
        
    }

    const doEdit2 = (task: CaseFilingTaskDTO) => {
        show(<CaseFillingForm onClose={doClose} onChange={refresh} task={task}/>) 
    }

    const doClose = () => {
        close()
        navigate(`/workbench/clientmanagement/${client?.client_id}/${clientCase?.case_id}/2/0`)
    }

    const emptyRender = (
        <Flex vertical align="center" gap={24} className={styles["case-filling__table__empty"]} >
            <Flex vertical align="center" gap={16}>
                <Icon src={NoDocumentSvg} size={150} height={102} padding={0}></Icon>
                <div className={styles["case-filling__table__empty__title"]}>No history available</div>
                <div className={styles["case-filling__table__empty__subtitle"]}>It appears that you haven't started any case yet. Once you begin, this section will display a history of your case filing history, enabling you to track your progress.</div>
            </Flex> 
            <Button.Primary onClick={openCreateForm}>
                <Flex gap={8} align="center">
                    <Icon src={plusWhiteSvg} size={20}></Icon>
                    Create new
                </Flex>
            </Button.Primary>
        </Flex>
    )

    const headerRender = (
        <Flex className={styles['case-filling__table__header']} align="center" justify="space-between">
            Recent filing history
            {
                (tableInstance.data && tableInstance.data.length > 0)
                &&
                <Button.Primary onClick={openCreateForm} >
                    <Flex align="center" gap={8}>
                        <Icon src={plusWhiteSvg} size={20}></Icon>
                        Create
                    </Flex>
                </Button.Primary>
            }
        </Flex>
    )

    const cols: TableProps['cols'] = [
        {
            label: "Case name",
            name: "case_name"
        },
        {
            label: "Case status",
            name: "case_status",
            width: 292,
            render(record, value, idx) {

                const isAnalysingEvidence = (value as CaseFilingTaskStatus) === "ANALYSING_EVIDENCE"
                const isFinish = (record as CaseFilingTaskDTO).evidence_status === "SUCCEEDED" ||
                    (record as CaseFilingTaskDTO).evidence_status === "FAILED"
                return <StatusTag completed={isAnalysingEvidence && isFinish}></StatusTag>
            },
        },
        {
            label: "Time created",
            name: "created_at",
            width: 292,
            render(record, value, idx) {
                return toHumanDateTime(value)
            },
        },
        {
            label: "",
            name: "",
            width: 32,
            render(record, value, idx) {
                return <Icon src={editSvg} size={20} padding={10} hoverable onClick={() => doEdit(record)}/>
            }
        }
    ]

    


    return (
        <CaseFillingTaskContext.Provider value={{}}>
            <ProtectPage className={styles['case-filling']}>
                <Flex vertical gap={10} >
                        <div className={styles['form-filling__table']}>
                            <Table
                                instance={tableInstance}
                                dataLoader={dataLoader}
                                cols={cols}
                                emptyRender={emptyRender}
                                caption={headerRender}
                                showHeader={tableInstance.data && tableInstance.data.length > 0}
                                
                            ></Table>
                        </div>
                        {maskRender}
                    </Flex>
            </ProtectPage>
        </CaseFillingTaskContext.Provider>
    )
}

const StatusTag = (props: {completed: boolean}) => {

    const {
        completed
    } = props

    return (
        <div className={
            [
            styles['status-tag'], 
            !completed?styles['status-tag_inprogress']:'',
            completed?styles['status-tag_completed']:''
            ].join(" ")}>
                {
                    !completed?'In progress':'Completed'
                }
        </div>
    )

}