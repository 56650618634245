import { Flex, Input, List, Modal, Popover } from "antd"
import { useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component"
import ChatbotDatasource, { ChatSessionDTO } from "./ChatbotDatasource";

import "./Chatbot.scss";
import toolbarIconPng from './assets/images/dots-horizontal@3x.png'
import editIcon from './assets/images/edit-04@3x.png'
import deleteIcon from './assets/images/trash-01@3x.png'
import createIcon from "./assets/images/plus@3x.png"
import useChatDeleteConfirm from "./useChatDeleteConfirm";
import { ChatSessionContext } from "./ChatSessionContext";
import Env from "../../Env";

import togglebarOpenedPng from './assets/images/Chatbot default@3x.png'
import togglebarHiddenPng from './assets/images/Chatbot Open sidebar@3x.png'
import { useMask } from "../components/mask/Mask";
import { DeleteModal } from "../components/modal/Modal";



export const ChatbotSessionBar = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<ChatSessionDTO[]>([]);
    // const [reverseData, setReverseData] = useState<ChatSessionDTO[]>([]);
    const [hasMore, setHashMore] = useState(() => true)
    const [page, setPage] = useState(() => 0)
    const [activateIdx, setActivateIdx] = useState<number>(-1);
    // const {render: sessionDeleteRender, open} = useChatDeleteConfirm()
    const [editIdx, setEditIdx] = useState<number>(-1);
    const {session, setSession} = useContext(ChatSessionContext)
    const [showTogglebar, setShowTogglebar] = useState<boolean>(() => true)
    const {render: maskRender, close, show} = useMask()

    const loadMoreData = (nextPage: number) => {
        if (loading) {
            return;
        }
        setLoading(true);
        ChatbotDatasource.getChatHistory({page: nextPage, limit: 50}).then(sessions => {
            if(nextPage == 1){
                setData(toReverse(sessions))
            }else{
                setData((current) => {
                    return [...toReverse(sessions), ...current]
                })
            }
            
            setHashMore(sessions.length > 0)
            sessions.length > 0 && setPage(nextPage)
        }).then(() => setLoading(false)).catch(() => setLoading(false))
        
    };

    const toolbar = (idx: number) => {

        const tools = [
            {
                icon: deleteIcon,
                title: 'Delete',
            }
        ]
        if(data[idx].id){
            tools.unshift({
                icon: editIcon,
                title: 'Rename',
            })
        }

        const doEdit = () => {
            // ChatbotDatasource.chat
            setEditIdx(idx)
        }

        const doDelete = () => {
            show(
                <DeleteModal
                title="Are you sure you want to delete this chat?"
                content="Please be aware that this action is irreversible and once completed, the chat cannot be retrieved or restored."
                onCancel={close}
                onConfirm={() => {
                    setData(current => {
                        const deleteSession = current[idx];
                        deleteSession.id && ChatbotDatasource.deleteSession({chat_session_id: deleteSession.id})
                        if(idx == activateIdx) setActivateIdx(Math.max(idx - 1, 0))
                        return current.filter((it, _idx) => _idx != idx)
                    })
                    close()
                }}
                />
            )
        }
        
        return (
           <>
             <List 
                className="chatbot__sessionbar__session__toolbar" 
                grid={{column: 1, gutter: [0, 8]}} 
                dataSource={tools}
                renderItem={(item, idx) => (
                    <Flex align="center" gap={8} className="chatbot__sessionbar__session__tool" onClick={() => {item.title === "Rename"?doEdit():doDelete()}}>
                        <img src={item.icon} className="chatbot__sessionbar__session__tool__icon"></img>
                        {item.title}
                    </Flex>
                )}
            >
            </List>
           </>
        )
    }

    const doEditConfirm = (idx: number, name: string) => {
        Env.DEBUG && console.log(`doEditConirm, idx: ${idx}, name: ${name}`)
        if(name && name !== ""){
            const session = data[idx];
            session.id && ChatbotDatasource.updateSession({
                chat_session_id: data[idx].id,
                session_title: name
            })
            setData(current => {
                setEditIdx(-1)
                return current.map((it, _idx) => {
                    if(_idx == idx) it.title = name
                    return it
                })
            })
        }
        
    }

    const changeSession = (idx: number) => {
        if(idx == activateIdx) return;
        Env.DEBUG && console.log(`changeSession, idx: ${idx}`)
        setActivateIdx(idx)
        setSession({...data[idx]})
    }

    const createSession = () => {
        setData((current) => {
            const newSession = {title: 'New Chat', id: '', newSessionToken: new Date().getTime() + "", newSession: true}
            const newSessions = [...current, newSession]
            setActivateIdx(newSessions.length -1)
            setSession({...newSession})
            return newSessions
        })
    }

    const toReverse = (current: ChatSessionDTO[]) => {

        let output:ChatSessionDTO[] = []

        current.forEach(it => output.unshift(it))

        return output;
    }

    useEffect(() => {
        loadMoreData(1)
    }, [])

    useEffect(() => {
        if(data && data.length > 0 && (activateIdx == -1 || data[activateIdx].id !== session?.id)){
            if(activateIdx == -1){
                setActivateIdx(data.length - 1)
                setSession(data[data.length - 1])
            }else{
                setSession(data[activateIdx])
            }
            
        }
    }, [data])

    // useEffect(() => {
    //     const currentSession = {...data[activateIdx]}
    //     Env.DEBUG && console.log("session发生变化, currentSession: ", currentSession)
    //     if(activateIdx >= 0) setSession(currentSession)
    // }, [activateIdx])

    useEffect(() => {
        if(session && session.newSessionToken){
            Env.DEBUG && console.log("session发生变化, session: ", session, "data: ", data)
            setData(data.map(it => {
                if(it.newSessionToken === session.newSessionToken && session.id){
                    return {...it, newSession: false, newSessionToken: undefined, id: session.id, title: session.title??""}
                }
                //更新名称
                //TODO chishijie
                return {...it}
            }))
        }
    }, [session])

    const realIdx = (listIdx: number) => {
        return data.length - listIdx - 1
    }

    
    return (
        <Flex className="chatbot__sessionbar-wrapper">
            <Flex vertical gap={16} className={["chatbot__sessionbar", !showTogglebar?"chatbot__sessionbar_hidden":""].join(" ")} >
                <Flex gap={8} align="center" className="chatbot__sessionbar__create" onClick={createSession}>
                    <div style={{"flex": 1}} className="chatbot__sessionbar__create__title">New Chat</div>
                    <img src={createIcon} className="chatbot__sessionbar__create__icon"></img>
                </Flex>
                <div 
                    style={{flex: 1}} 
                    className="chatbot__sessionbar__sesssions" 
                    id="chatbot__sessionbar__sesssions"
                >
                    <InfiniteScroll
                        dataLength={data.length}
                        next={() => loadMoreData(page + 1)}
                        hasMore={hasMore}
                        loader={<></>}
                        // endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="chatbot__sessionbar__sesssions"
                    >
                        <List
                        bordered={false}
                        dataSource={toReverse(data)}
                        grid={{column: 1, gutter: [0, 4]}}
                        loading={loading}
                        renderItem={(item, idx) => (
                            <Flex 
                                key={item.id??item.newSessionToken}
                                align="center" 
                                gap={8} 
                                className={["chatbot__sessionbar__session", (activateIdx == realIdx(idx) )?"chatbot__sessionbar__session_activate":""].join(" ")} 
                                onClick={() => changeSession(realIdx(idx))}
                                // onMouseEnter={() => doMouseEnter(idx)} 
                                // onMouseLeave={() => doMouseLeave(idx)}
                            >
                                <div className="chatbot__sessionbar__session__title" style={{flex: 1}}>
                                    {
                                        editIdx == realIdx(idx)
                                        ?
                                        <Input defaultValue={item.title??'New Chat'} onBlur={e => doEditConfirm(realIdx(idx), e.target.value)} onKeyDown={e => e.key === "Enter"?doEditConfirm(realIdx(idx), (e.target as any).value):""}></Input>
                                        :
                                        item.title??'New Chat'
                                    }
                                </div>
                                <Popover placement="bottomLeft" arrow={false} content={() => toolbar(realIdx(idx))}>
                                    {(activateIdx == realIdx(idx) ) && <img style={{flex: "0 0 "}} className="chatbot__sessionbar__session__toolbarIcon" src={toolbarIconPng}></img>}
                                </Popover>
                            </Flex>
                        )}
                        />
                    </InfiniteScroll>
                </div>
            </Flex>
            <div className="chatbot__togglebar">
                {
                    showTogglebar
                    ?
                    <img src={togglebarOpenedPng} className="chatbot__togglebar_opened" onClick={() => setShowTogglebar(false)}></img>
                    :
                    <img src={togglebarHiddenPng} className="chatbot__togglebar_hidden" onClick={() => setShowTogglebar(true)}></img>
                }
            </div>
            {maskRender}
        </Flex>
    )
}