import axios from "axios"
import { AcademicBackgroundDTO } from "./AcademicBackgroundGraphsql.api"
import { AwardDTO } from "./AwardsGraphsql.api"
import { ArtExhibitionDTO } from "./ArtExhibitionGraphsql.api"
import { JobDTO } from "./JobsGraphsql.api"
import { MediaCoverageDTO } from "./MediaCoveragesGraphsql.api"
import { MembershipDTO } from "./MembershipGraphsql.api"
import { PatentsDTO } from "./PatentsGraphsql.api"
import { PublicationReviewDTO } from "./ReviewGraphsql.api"
import { PublicationDTO } from "./PublicationsGraphsql.api"


export interface CaseFilingExtraDTO{
  academicBackground: AcademicBackgroundDTO
  awards: AwardDTO[]
  exhibitions: ArtExhibitionDTO[]
  jobs: JobDTO[]
  mediaCoverages: MediaCoverageDTO[]
  memberships: MembershipDTO[]
  patents: PatentsDTO[]
  publications: PublicationDTO[]
  reviews: PublicationReviewDTO[]
}


const getAll = async (caseFilingTaskId: string): Promise<CaseFilingExtraDTO> => {

  const operation = `
      query Query($caseFilingTaskId: ID!, $getAwardsCaseFilingTaskId2: ID!, $getExhibitionsCaseFilingTaskId2: ID!, $getJobsCaseFilingTaskId2: ID!, $getMediaCoveragesCaseFilingTaskId2: ID!, $getMembershipsCaseFilingTaskId2: ID!, $getPatentsCaseFilingTaskId2: ID!, $getPublicationsCaseFilingTaskId2: ID!, $getReviewsCaseFilingTaskId2: ID!) {
getAcademicBackground(caseFilingTaskId: $caseFilingTaskId) {
id
highestDegree
undergraduate {
id
universityName
startYear
endYear
gpa
rank
academicBackgroundId
userId
}
graduate {
id
degreeType
universityName
departmentName
gpa
rank
academicBackgroundId
userId
startYear
endYear
}
clientProfileInfoId
userId
}
getAwards(caseFilingTaskId: $getAwardsCaseFilingTaskId2) {
id
awardName
issueAuthority
authorRanking
year
clientProfileInfoId
userId
}
getExhibitions(caseFilingTaskId: $getExhibitionsCaseFilingTaskId2) {
id
exhibitionName
organizerName
year
clientProfileInfoId
userId
}
getJobs(caseFilingTaskId: $getJobsCaseFilingTaskId2) {
id
employerName
jobTitle
startYear
endYear
clientProfileInfoId
userId
}
getMediaCoverages(caseFilingTaskId: $getMediaCoveragesCaseFilingTaskId2) {
id
mediaName
year
link
clientProfileInfoId
userId
}
getMemberships(caseFilingTaskId: $getMembershipsCaseFilingTaskId2) {
id
associationName
position
startYear
endYear
clientProfileInfoId
userId
}
getPatents(caseFilingTaskId: $getPatentsCaseFilingTaskId2) {
id
title
patentNumber
year
authorRanking
clientProfileInfoId
userId
}
getPublications(caseFilingTaskId: $getPublicationsCaseFilingTaskId2) {
id
type
title
journalConferenceName
year
authorRanking
source
clientProfileInfoId
userId
}
getReviews(caseFilingTaskId: $getReviewsCaseFilingTaskId2) {
id
type
journalConferenceName
startYear
endYear
counts
clientProfileInfoId
userId
}

}
  `

  const variables = {
      "caseFilingTaskId": caseFilingTaskId,
      "getAwardsCaseFilingTaskId2": caseFilingTaskId,
      "getExhibitionsCaseFilingTaskId2": caseFilingTaskId,
      "getJobsCaseFilingTaskId2": caseFilingTaskId,
      "getMediaCoveragesCaseFilingTaskId2": caseFilingTaskId,
      "getMembershipsCaseFilingTaskId2": caseFilingTaskId,
      "getPatentsCaseFilingTaskId2": caseFilingTaskId,
      "getPublicationsCaseFilingTaskId2": caseFilingTaskId,
      "getReviewsCaseFilingTaskId2": caseFilingTaskId,
    }

    const _data = (await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
    )).data?.['data']

    return {
      academicBackground: _data['getAcademicBackground'],
      awards: _data['getAwards'],
      exhibitions: _data['getExhibitions'],
      jobs: _data['getJobs'],
      mediaCoverages: _data['getMediaCoverages'],
      memberships: _data['getMemberships'],
      patents: _data['getPatents'],
      publications: _data['getPublications'],
      reviews: _data['getReviews']
    }

}


export default {
  getAll
}