

import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface MembershipDTO{
  id: string
  associationName: string
  position: string
  startYear:string
  endYear:string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query Query($caseFilingTaskId: ID!) {
  getMemberships(caseFilingTaskId: $caseFilingTaskId) {
    id
    associationName
    position
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}
  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getMemberships'] as MembershipDTO[]

}

const update = async (taskId: string, request: {created?: MembershipDTO[], updated?: MembershipDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: MembershipDTO) => {
  const operation = `
  mutation CreateMemberships($membershipInputs: [MembershipInput!]!, $caseFilingTaskId: ID!) {
  createMemberships(membershipInputs: $membershipInputs, caseFilingTaskId: $caseFilingTaskId) {
    id
    associationName
    position
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}
  `
  const variables = {
    "membershipInputs": [{
      "associationName": request.associationName,
      "position": request.position,
      "startYear": request.startYear,
      "endYear": request.endYear,
    }],
    "caseFilingTaskId": taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables,
      caseFilingTaskId: taskId
    }
)).data.data['createMemberships'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeleteMembership($membershipId: ID!) {
  deleteMembership(membershipId: $membershipId)
}
  `
  const variables = {
    membershipId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: MembershipDTO) => {

  const operation = `
  mutation EditMembership($membershipInput: MembershipInput!, $membershipId: ID!) {
  editMembership(membershipInput: $membershipInput, membershipId: $membershipId) {
    id
    associationName
    position
    startYear
    endYear
    clientProfileInfoId
    userId
  }
}
  `

  const variables = {
    "membershipInput": {
      "associationName": request.associationName,
      "position": request.position,
      "startYear": request.startYear,
      "endYear": request.endYear,
    },
    "membershipId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}