import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface ArtExhibitionDTO{
  id: string
  exhibitionName: string
  organizerName: string
  year:string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query GetExhibitions($caseFilingTaskId: ID!) {
    getExhibitions(caseFilingTaskId: $caseFilingTaskId) {
      id
      exhibitionName
      organizerName
      year
      clientProfileInfoId
      userId
    }
  }

  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getExhibitions'] as ArtExhibitionDTO[]

}

const update = async (taskId: string, request: {created?: ArtExhibitionDTO[], updated?: ArtExhibitionDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: ArtExhibitionDTO) => {
  const operation = `
  mutation CreateExhibitions($exhibitionInputs: [ExhibitionInput!]!, $caseFilingTaskId: ID!) {
  createExhibitions(exhibitionInputs: $exhibitionInputs, caseFilingTaskId: $caseFilingTaskId) {
    id
    exhibitionName
    organizerName
    year
    clientProfileInfoId
    userId
  }
}
  `
  const variables = {
    "exhibitionInputs": [{
      "exhibitionName": request.exhibitionName,
      "organizerName": request.organizerName,
      "year": request.year,
    }],
    "caseFilingTaskId": taskId
  }

  return (
    await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables,
        caseFilingTaskId: taskId
      }
  )
  ).data.data['createExhibitions'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeleteExhibition($exhibitionId: ID!) {
  deleteExhibition(exhibitionId: $exhibitionId)
}
  `
  const variables = {
    exhibitionId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: ArtExhibitionDTO) => {

  const operation = `
  mutation EditExhibition($exhibitionInput: ExhibitionInput!, $exhibitionId: ID!) {
  editExhibition(exhibitionInput: $exhibitionInput, exhibitionId: $exhibitionId) {
    id
    exhibitionName
    organizerName
    year
    clientProfileInfoId
    userId
  }
}
  `

  const variables = {
    "exhibitionInput": {
      "exhibitionName": request.exhibitionName,
      "organizerName": request.organizerName,
      "year": request.year,
    },
    "exhibitionId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}