import { createContext, useEffect, useState } from "react";
import Env from "../../../Env";

export interface FormContextData{
    values: Record<string, any>
    values2?: Record<string, any>
    errors: Record<string, any>
    errors2?: Record<string, any>
    setValues: (name: string, value: any, forceValidate?: boolean) => void
    setRules: (name: string, value: any) => void
    setErrors: (name: string, value: any) => void
    validate: (name: string) => Promise<void>
    validateAll: () => Promise<void>
    disabled: boolean
    setInitial: (initial: any) => void
    submit: () => Promise<any> | void
    setShowError: (showError: boolean) => void
    showError?: boolean
    loading?: boolean
    setLoading: (loading: boolean) => void
    cleanValue: (name: string) => void
}

export interface FormItemContextData{
    name: string
    
}

export interface FormItemValidateRule{
    msg: string,
    validate: (value: any, formData?: any) => Promise<boolean>
}

export const FormContext = createContext<FormContextData>({
    disabled: true,
    values: {},
    errors: {},
    setValues(name, value) {
        throw new Error("unimplement");
    },
    setRules(name, value) {
        throw new Error("unimplement");
    },
    setErrors: function (name: string, value: any): void {
        throw new Error("Function not implemented.");
    },
    validate: function (name: string): Promise<void> {
        throw new Error("Function not implemented.");
    },
    validateAll: function (): Promise<void> {
        throw new Error("Function not implemented.");
    },
    setInitial: function (initial: any): void {
        throw new Error("Function not implemented.");
    },
    submit: function (): void {
        throw new Error("Function not implemented.");
    },
    setShowError: function (): void {
        throw new Error("Function not implemented.");
    },
    setLoading: function (loading: boolean): void {
        throw new Error("Function not implemented.");
    },
    cleanValue: function (name: string): void {
        throw new Error("Function not implemented.");
    }
})

export const FormItemContext = createContext<FormItemContextData>({
    name: "",
})


export const useFormInstance = (props:{autoValidate?: boolean} = {}): FormContextData => {

    const [_values, _setValues] = useState<{[key: string]: any}>()
    const [_errors, _setErrors] = useState<{[key: string]: any}>()
    const [_rules, _setRules] = useState<{[key: string]: FormItemValidateRule[] | undefined}>({})
    const [disabled, setDisabled] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [initialValidateStep, setInitialValidateStep] = useState<number>(0)

    useEffect(() => {
        checkDisable()
        // props.autoValidate && validateAll().catch(() => {})
        if(initialValidateStep == 1){
            validateAll().catch(() => {}).finally(() => {
                Env.DEBUG && console.log("执行全局校验, _rules: ", _rules, " values: ", _values)
                setInitialValidateStep(2)
            })
        }
    }, [_rules, _values])


    // useEffect(() => {
    //     console.log("errors changed: ", _errors)
    // }, [_errors])

    const setValues = (name: string, value: any, forceValidate: boolean = false) => {
        // Env.DEBUG && console.log("invoke setValues, name: ", name, " value: ", value, " forceValidate: ", forceValidate, " rules: ", _rules[name])
        _setValues(current => {
            return {...current, [name]: value}
        })
        if(forceValidate){
            _validate(name, value, _rules[name], {..._values})
            .then(() => {
                cleanErrors(name)
            })
            .catch(({name, msg}) => {
                setErrors(name, msg)
            })
        }
    }
    const setRules = (name: string, rules?: FormItemValidateRule[]) => {
        // Env.DEBUG && console.log("invoke setRules, name: ", name, " rules: ", rules, " lazy: ", lazy)
        _setRules(current => {
            return {...current, [name]: rules??[]}
        })
        // if(rules){
        //     _validate(name, _values[name], rules)
        //     .then(() => {
        //         // cleanErrors(name)
        //     })
        //     .catch(({name, msg}) => {
        //         // setErrors(name, msg)
        //     })
        // }
    }

    const setErrors = (name: string, msg: string) => {
        // Env.DEBUG && console.trace("invoke setErrors, name: ", name, " msg: ", msg)
        _setErrors(current => {
            return {...current, [name]: msg}
        })
    }

    const cleanErrors = (name: string) => {
        _setErrors(current => {
            current && (delete current[name])
            return {...current}
        })
    }


    const validate = (name: string): Promise<void> => {
        const value = _values?.[name]
        const rules = _rules[name];
        // Env.DEBUG && console.log("invoke validate, name: ", name, " rules: ", rules," value: ", value)
        return _validate(name, value, rules, {..._values})
        .then(() => cleanErrors(name))
        .catch(({name, msg}) => setErrors(name, msg))
    }

    const _validate = (name: string, value: any, rules?: FormItemValidateRule[], formdata?: any): Promise<void> =>{
        if(!rules){
            return Promise.resolve();
        }
        return new Promise(async (resolve, reject) => {
            const results = await Promise.all(rules.map(rule => rule.validate(value, formdata)))
            const found = results.map((result, idx) => [result, idx]).find(it => !it[0])
            if(found){
                const msg = rules[found[1] as number].msg
                // Env.DEBUG && console.log(`value validate error, name: ${name}, value: ${value}`)
                // _setErrors({..._errors, [name]: msg})
                reject({name, msg})
            }else{
                // cleanErrors(name)
                resolve()
            }
        })
    }
    
    const validateAll = (): Promise<void> => {
        return new Promise(async (resolve, reject) => {
            if(!_rules || Object.keys(_rules).length == 0) resolve()
            for(let entry of Object.entries(_rules)){
                const [name, rules] = entry
                let value = _values?.[name]
                _validate(name, value, rules, {..._values})
                .then(() => {cleanErrors(name); resolve()})
                .catch(({name, msg}) => {setErrors(name, msg); reject()})
            }
        })
    }

    const checkDisable = async () => {
        let _disabled = false;
        for(let entry of Object.entries(_rules)){
            const [name, rules] = entry
            let value = _values?.[name]
            try{
                await _validate(name, value, rules, {..._values})
            }catch(e){
                _disabled = true
                break;
            }
        }
        setDisabled(_disabled)
    }

    const setInitial = (initial: any) => {
        _setValues(_ => {
            setInitialValidateStep(1)
            return initial??{}
        })
        
    }

    const cleanValue = (name: string) => {
        delete _values?.[name]
        delete _rules[name]
        _errors && (delete _errors[name])

        _setValues(current => {
            current && (delete current[name])
            return {...current}
        })

        _setErrors(current => {
            current && (delete current[name])
            return {...current}
        })

        _setRules(current => {
            delete current[name]
            checkDisable()
            return {...current}
        })

    }

    return {
        disabled,
        values: _values??{},
        values2: _values,
        errors: _errors??{},
        errors2: _errors,
        setValues,
        setRules,
        setErrors,
        validate,
        validateAll,
        setInitial,
        submit() {
            throw new Error("Function not implemented.");
        },
        setShowError,
        loading,
        setLoading,
        cleanValue
    }

}