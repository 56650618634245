import { MessageArgsProps, message, notification } from "antd"



export interface BroadcastProps{
    content?: any
    duration?: number
}

export default () => {

    const [api, render] = message.useMessage()

    const info = (props: BroadcastProps) => {

        const {
            content,
            duration = 2
        } = props

        let msg = content instanceof Error?content.message:content
        api.info(msg, duration)
    }

    const success = (props: BroadcastProps) => {
        
        const {
            content,
            duration = 2
        } = props

        let msg = content instanceof Error?content.message:content
        api.success(msg, duration)
    }

    const warning = (props: BroadcastProps) => {
        
        const {
            content,
            duration = 2
        } = props

        let msg = content instanceof Error?content.message:content
        api.warning(msg, duration)
    }

    const error = (props: BroadcastProps) => {
        
        const {
            content,
            duration = 2
        } = props

        let msg = content instanceof Error?content.message:content
        api.error(msg, duration)
    }

    const close = () => {
        api.destroy()
    }

    return {
        render,
        info,
        success,
        warning,
        error,
        close
    }

}