import { Flex, message } from "antd"
import Screen from "../components/Screen"

import styles from "./ForgetPassword.module.scss";
import keySvg from "../login/assets/images/key-01.svg";
import arrowSvg from "../login/assets/images/arrow-left.svg";


import useGoogleLogin from "../login/components/useGoogleLogin";
import Form from "../components/form/Form";
import { useState } from "react";
import Link from "../login/components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import useToken from "../libs/useToken";
import { useNavigate } from "react-router-dom";
import ForgetpasswordApi from "./Forgetpassword.api";
import { useFormInstance } from "../components/form/FormContext";


const {
    required,
    email
} = validators

export default () => {

    const {loginBtn: googleLoginBtn} = useGoogleLogin()
    const [loginStep, setLoginStep] = useState<number>(1)
    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const {setToken} = useToken()
    const navigate = useNavigate()
    const formInstance = useFormInstance()

    const doSubmit = (params: any) => {
        if(loading) return;
        setLoading(true)
        messageApi.loading({content: "Applying", duration: 0})
        return ForgetpasswordApi.sendEmailToResetPassword(params['email']).then(() => {
            messageApi.destroy()
            navigate("/forgetPassword/checkEmail")
        })
        
    }

    const Step2 = (
        <Form.Form instance={formInstance} onSubmit={doSubmit}>
            <Flex vertical gap={16}>
                <Flex vertical gap={24}>
                    <Form.Item 
                        label="Email" 
                        name="email"
                        rules={[
                            {validate:required, msg: "Please enter your email"},
                            {validate:email, msg: "Email address must be in the format"}
                        ]}
                    >
                        <Form.Input placeholder={"Enter your email"}/>
                    </Form.Item>
                    <Form.Submit>Request Password</Form.Submit>
                </Flex>
            </Flex>
        </Form.Form>
    )

    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['forgetpassword']}>
                    <Flex align="center" vertical  gap={24}>
                        <div className={styles["forgetpassword__icon"]}>
                            <Icon src={keySvg} size={28}></Icon>
                        </div>
                        <Flex vertical  gap={12}>
                            <div className={styles['forgetpassword__title']}>Forgot password?</div>
                            <div className={styles['forgetpassword__subtitle']}>No worries, we’ll send you reset instructions.</div>
                        </Flex>
                    </Flex>

                    <Flex vertical gap={16}>
                        {Step2}
                    </Flex>

                    <Flex gap={8} justify="center" className={styles['forgetpassword__backtologin']}>
                        <Icon src={arrowSvg} size={20}></Icon>
                        <Link onClick={() => navigate("/login")}>Back to log in</Link>
                    </Flex>
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}