
import { Flex } from 'antd';
import Icon from '../icon/Icon';
import styles from './Modal.module.scss';
import trashRedSvg from "./assets/images/trash-red-01.svg";
import alertCircleSvg from "./assets/images/alert-circle.svg";
import Button from '../Button';
import { useState } from 'react';

export interface ModalProps{
    title: string
    content: string
    onCancel: () => void
    onConfirm: () => Promise<void> | void
}

export const DeleteModal = (props: ModalProps) => {

    const [loading, setLoading] = useState<boolean>()

    const _confirm = async () => {
        if(loading) return;
        setLoading(true)
        try{
            await props.onConfirm()
        }finally{
            setLoading(false)
        }
        
    }

    return (
        <div className={styles['delete-modal']} style={{"margin": "auto"}}>
            <Flex vertical align='center' gap={10} className={styles['delete-modal__body']}>
                <div className={styles['delete-modal__icon']}>
                    <Icon src={trashRedSvg} size={24}></Icon>
                </div>
                <Flex vertical gap={4}>
                    <div className={styles['delete-modal__title']}>{props.title}</div>
                    <div className={styles['delete-modal__content']}>{props.content}</div>
                </Flex>
            </Flex>
            <div className={styles['delete-modal__footer']}>
                <Flex gap={12}>
                    <Button.WhiteBg width={"100%"} onClick={props.onCancel}>Cancel</Button.WhiteBg>
                    <Button.RedBg width={"100%"} onClick={_confirm} loading={loading}>Delete</Button.RedBg>
                </Flex>
            </div>
        </div>
    )

}

export const ConfirmModal = (props: ModalProps) => {

    const [loading, setLoading] = useState<boolean>()

    const _confirm = async () => {
        if(loading) return;
        setLoading(true)
        try{
            await props.onConfirm()
        }finally{
            setLoading(false)
        }
        
    }

    return (
        <div className={styles['confirm-modal']} style={{"margin": "auto"}}>
            <Flex vertical align='center' gap={10} className={styles['confirm-modal__body']}>
                <div className={styles['confirm-modal__icon']}>
                    <Icon src={alertCircleSvg} size={24}></Icon>
                </div>
                <Flex vertical gap={4}>
                    <div className={styles['confirm-modal__title']}>{props.title}</div>
                    <div className={styles['confirm-modal__content']}>{props.content}</div>
                </Flex>
            </Flex>
            <div className={styles['confirm-modal__footer']}>
                <Flex gap={12}>
                    <Button.WhiteBg width={"100%"} onClick={props.onCancel}>Cancel</Button.WhiteBg>
                    <Button.Primary width={"100%"} onClick={_confirm} loading={loading}>Confirm</Button.Primary>
                </Flex>
            </div>
        </div>
    )

}