import { useContext, useEffect } from "react"
import { ClientCaseContext } from "../clientmanagement/context/ClientCaseContext"
import { FormContext, FormItemContext, useFormInstance } from "../components/form/FormContext"
import { StepForm } from "./FormFillingForm"
import FormFillingApi from "../api/FormFilling.api"
import Env from "../../Env"
import Form from "../components/form/Form"
import FormValidators from "../components/form/FormValidators"
import { Checkbox, Flex } from "antd"
import formFillingJson from "./assets/data/formfilling.json"
import formStyles from "../components/scss/form.module.scss"
import styles from "./FormFillingForm.module.scss"
import { FormFillingFormContext, convertToStep } from "./FormFillingFormContext"



const {
    form_types
} = formFillingJson


export default () => {

    const {clientCase} = useContext(ClientCaseContext)
    const formInstance = useFormInstance()
    const {data, setData, setStep} = useContext(FormFillingFormContext)

    const doSubmit = (form: any) => {
        if(data){
            return FormFillingApi.update(data.case_id, data.id, form).then((res) => {
                Env.DEBUG && console.log("update formFillingTask: ", res);
                setData(res)
                setStep(convertToStep(res.status))
            })
        }else{
            return FormFillingApi.create(clientCase?.case_id as string, form).then((res) => {
                Env.DEBUG && console.log("create formFillingTask: ", res);
                setData(res)
                setStep(convertToStep(res.status))
            })
        }
    }

    useEffect(() => {
        
        if(data){
            formInstance.setInitial(data)
        }
    }, [data])

    return (
        <StepForm
            title={"Select your form"}
            subtitle={"Please select any of the forms below that you would like to fill with AI."}
        >
            <Form.Form
                instance={formInstance}
                onSubmit={doSubmit}
                showError={false}
            >
                <Form.Item 
                    label="Available forms" 
                    name="form_types" 
                    required 
                    rules={[{validate: FormValidators.required, msg: "Available forms"}]}
                >
                    <FormTypesSelect/>
                </Form.Item>
                <Form.Item nowrap label="" name="">
                    <Flex justify="flex-end">
                        <Form.Submit width={185}>Continue</Form.Submit>
                    </Flex>
                </Form.Item>
            </Form.Form>
        </StepForm>
    )
}

const FormTypesSelect = () => {

    const {name} = useContext(FormItemContext)
    const {values, setValues} = useContext(FormContext)
    const {data} = useContext(FormFillingFormContext)

    const onTypeChange = (checked: boolean, type: string) => {

        let _values = values[name] as string[]??[]
        if(!checked){
            _values = _values.filter(it => it !== type)
        }else{
            _values = [..._values, type]
        }

        setValues(name, _values)
    }

    const isChecked = (type: string) => {
        const types = values[name] as string[]??[]
        return !!types.find(it => it === type)
    }

    return (
        <Flex vertical gap={16}>
            {
                form_types.map(type => (
                    <Flex gap={12} align="flex-start">
                        <div style={{width:"20px"}}>
                            {/* <input type="checkbox" className={formStyles['checkbox']} /> */}
                            <Checkbox
                                className={formStyles['checkbox']} 
                                checked={isChecked(type.value)} 
                                onChange={e => onTypeChange(e.target.checked, type.value)}
                                />
                        </div>
                        <Flex vertical gap={2}>
                            <div className={styles['select-your-form__formtypes__title']}>{type.title}</div>
                            <div className={styles['select-your-form__formtypes__subtitle']}>{type.subtitle}</div>
                        </Flex>
                    </Flex>
                ))
            }

        </Flex>
    )
}