import { createContext, useContext } from "react";
import { UserDTO } from "../../api/login.api";

export interface UserContextData {
    user: UserDTO | undefined
    setUser: (user: UserDTO | undefined) => void
}

export const UserContext = createContext<UserContextData>({
    user: undefined,
    setUser(user) {
        throw new Error("unimplement")
    },
})