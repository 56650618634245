import { Outlet, useLocation } from "react-router-dom"
import ForgetPasswordApply from "./ForgetPasswordApply"
import urls from "../libs/urls"


export default () => {

    const location = useLocation()

    const paths = urls.paths(location.pathname)
    if(paths[paths.length -1] == "forgetPassword"){
        return <ForgetPasswordApply/>
    }

    return (
        <Outlet/>
    )

}