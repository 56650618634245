import { createContext } from "react";
import { RegisterRequest } from "../Signup.api";

export interface RegisterParams{
    email: string
    signup_token: string
}

export interface SignupContextData{
    register: RegisterParams | undefined
    setRegister: (register: RegisterParams) => void
}

export const SignupContext = createContext<SignupContextData>({
    register: undefined,
    setRegister(register) {
        throw new Error("unimplement")
    },
})