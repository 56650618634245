
const key = 'pc-key'

export default () => {

    const setToken = (token: string) => {
        return window.localStorage.setItem(key, token)
    }
    
    const getToken = () => {
        return window.localStorage.getItem(key)
    }
    
    const removeToken = () => {
        return window.localStorage.removeItem(key)
    }

    return {
        setToken,
        getToken,
        removeToken
    }

}