
import { ConfigProvider, Flex, Popover } from "antd";
import styles from "./Workbench.module.scss";

import { Outlet, useLocation, useNavigate, useNavigation } from "react-router-dom";
import { ReactNode, useContext } from "react";
import Icon from "../components/icon/Icon";
import logoutSvg from "./assets/images/logout.svg"
import { UserContext } from "../login/context/UserContext";
import AuthFilter from "../login/AuthFilter";
import useToken from "../libs/useToken";
import loginApi from "../api/login.api";
import avatarPng from "./assets/images/Avatar.png";

export default () => {

    return (
        <AuthFilter>
            <Flex className={styles['workbench']}>
                <div className={styles['workbench__siderbar']}>
                    <WorkbeanchSiderBar/>
                </div>
                <div style={{flex: 1}} className={styles['workbench__container']}>
                    <Outlet/>
                </div>
            </Flex>
        </AuthFilter>
    )

}

const WorkbeanchSiderBar = () => {

    const navigate = useNavigate()
    const {user} = useContext(UserContext)
    const {removeToken} = useToken()

    const logout = () => {
        
        loginApi.logout().then(() => {
            
        })
        removeToken()
        navigate("/")
    }

    const ToolTip = (
        <Flex vertical align="center" className={styles['siderbar__toolbox']}>
            <div className={styles['siderbar__toolbox__profile']}>
                <div className={styles['siderbar__toolbox__profile__name']}>{user?.last_name} {user?.first_name}</div>
                <div className={styles['siderbar__toolbox__profile__email']}>{user?.email}</div>
            </div>
            <div className={styles['siderbar__toolbox__tools']}>
                <Flex gap={8} align="center" className={styles['siderbar__toolbox__tool']}>
                    <Icon src={logoutSvg} size={16}></Icon>
                    <div style={{flex: 1}} className={styles['siderbar__toolbox__tool__label']} onClick={logout}>Log out</div>
                </Flex>
            </div>
        </Flex>
    )

    return (
        <Flex vertical justify="space-between" className={styles['siderbar']}>
            <Flex vertical align="center" gap={24}>
                <Popover placement="rightBottom" arrow={false} content={ToolTip}>
                    <div className={styles['siderbar__avatar']}>
                        <Icon src={avatarPng} size={48}></Icon>
                    </div>
                </Popover>
                <div className={styles['siderbar__divider']}></div>
                <MenuBar/>
            </Flex>
        </Flex> 
    )
}

const menus = [
    {
        path: "/workbench/chatbot",
        name: "chatbot"
    },
    {
        path: "/workbench/clientmanagement/client/home/0/0",
        name: "clientmanagement"
    }
]

export interface MenuType{
    path: string
    name: string
}

const MenuBar = () => {

    const loc = useLocation()
    const navigate = useNavigate()

    const activate = (menu: MenuType) => {
        return loc.pathname == menu.path
    }

    const menuRender = (menu: MenuType) => {
        
        return (
            <div 
                className={[styles['menubar__item'], activate(menu)?styles['menubar__item_activate']:''].join(" ")}
                onClick={() => navigate(menu.path)}
            >
                <div className={[
                    styles['menubar__item__icon'], styles[`menubar__item__icon_${menu.name}`], activate(menu)?styles[`menubar__item__icon_${menu.name}_activate`]:''].join(" ")}></div>
            </div>
        )
    }

    return (
        <Flex vertical align="center" gap={16}>
            {menus.map(menu => menuRender(menu))}
        </Flex>
    )

}