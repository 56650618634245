import { createContext, useState } from "react"
import { FormFillingTaskDTO, TaskStatus } from "../api/FormFilling.api"

export interface FormFillingFormContextProps{
    step: number
    setStep: (step: number) => void
    // states: FormFillingState[]
    // setStates: (states: FormFillingState[]) => void
    // /**
    //  * 任务本身完成的步骤
    //  */
    // taskStep: number
    // setTaskStep: (step: number) => void
    data?: FormFillingTaskDTO
    setData: (data: FormFillingTaskDTO) => void
}

export interface FormFillingState{
    finish: boolean
}

export const FormFillingFormContext = createContext<FormFillingFormContextProps>({
    step: 0,
    setStep: function (step: number): void {
        throw new Error("Function not implemented.")
    },
    // states: [],
    // setStates: function (states: FormFillingState[]): void {
    //     throw new Error("Function not implemented.")
    // },
    // taskStep: 0,
    // setTaskStep: function (step: number): void {
    //     throw new Error("Function not implemented.")
    // },
    setData: function (data: FormFillingTaskDTO): void {
        throw new Error("Function not implemented.")
    }
})

export const useFormFillingFormContext = (): FormFillingFormContextProps => {

    const [step, setStep] = useState<number>(0)
    const [taskStep, setTaskStep] = useState<number>(0)
    const [states, setStates] = useState<FormFillingState[]>([])
    const [data, setData] = useState<FormFillingTaskDTO>()

    return {
        step,
        setStep,
        // states,
        // setStates,
        // taskStep,
        // setTaskStep,
        data,
        setData
    }

}

export const convertToStep = (taskStatus: TaskStatus = "SELECT_FORM") => {
    switch(taskStatus){
        case "SELECT_FORM":
            return 0
        case "UPLOAD_DOC":
            return 1
        case "REVIEW_CLIENT_INFO":
            return 2
        case "CHOOSE_LAWYER_PROFILE":
            return 3
        case "COMPLETED":
            return 4
        default:
            return 0
    }
} 


