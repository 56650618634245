
import { PropsWithChildren, ReactNode, createContext, createRef, forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import './Chatbot.scss'
import {Col, Flex, Popover, Row} from "antd"
import ChatbotDatasource, { ChatSessionDTO } from './ChatbotDatasource';
import AiChatAvatarPng from './assets/images/AI Chatbot Avatar.svg'
import { ChatbotContext, ChatbotMessage, ChatbotMessageAuthor, ChatbotWholeMessage, ChatbotMessageType, ChatbotPayloadQa, ChatbotQuery, ChatbotQueryText, ChatbotResource, ChatbotResourceAction, ChatbotStreamMessage, ChatbotTipType, ChatMessageFollowup, ChatMessageReference, ChatPayloadVisaBulletin, ChatSession } from './ChatbotContext';
import InputBtnPng from "./assets/images/send-01@3x.png"
import file02Png from "./assets/images/file-02@3x.png"
import fileSearch02Png from "./assets/images/file-search-02@3x.png"
import useModal from './components/antd/userModal';
import { ChatbotSessionBar } from './useChatbotSessionBar';
import { ChatSessionContext, ChatSessionContextType, Session } from './ChatSessionContext';
import Env from '../../Env';
import LoadingAnimation from './components/loading/LoadingAnimation';
import ThumbUp from './ThumbUp';
import ThumbDown from './ThumbDown';
import Citation from './Citation';
import { useNavigate } from 'react-router-dom';
import Divider from './components/Divider';
import HtmlViewer from './components/HtmlViewer';



type Position = "screen"|"modal"
type PositionProps<T = any> = {
    position: Position
} & T

export default () => {

    const [session, setSession] = useState<Session>()
    const chatbotRef = createRef<ChatbotScreenRef>()
    
    const _setSession = (session: Session) => {
        const newSession = {...session}
        Env.DEBUG && console.log("setSession: ", newSession)
        setSession(newSession);
        chatbotRef.current?.changeSession(newSession)
        if(session.newSession){
            chatbotRef.current?.appendMessage({
                author: "system",
                type: "welcome"
            })
        }
    }

    const updateSessionInfo = (id: string, title?: string) => {
        setSession(current => {
            const newSession = {...current, id, title}
            Env.DEBUG && console.log("updateSessionInfo: ", id, " new Session: ", newSession)
            // updateSession(newSession)
            return newSession
        })
    }

    return (
        <ChatSessionContext.Provider value={{session, setSession: _setSession, updateSessionInfo}}>
            <ChatBotV2 ref={chatbotRef}/>
        </ChatSessionContext.Provider>
    )

}

const ChatBotV2 = forwardRef(({}, ref) => {

    const {render: screenRender, changeSession, sendMessage, appendMessage} = useChatbotScreen({children: <ChatbotHeader position="screen" />, position: "screen"})

    useImperativeHandle(ref, () => ({
        changeSession,
        sendMessage,
        appendMessage
      }));


    return (
        <Flex className='chatbot' style={{flex: 1}}>
            <ChatbotSessionBar/>
            {screenRender}
        </Flex>
    )
})

const useChatbotResourceModal = () => {
    
    const {render: screenRender, cleanMessages, sendMessage, cleanSession} = useChatbotScreen({position: "modal"})
    const {render, show: showModal, opening} = useModal({children: screenRender})

    const show = (resource: ChatbotResource) => {
        showModal()
        sendMessage({
            chat_type: "focused",
            resource_id: resource.resource_id,
            resource_type: resource.resource_type
        })
    }

    useEffect(() => {

        if(!opening){
            cleanMessages()
            cleanSession()
        }

    }, [opening])


    return {
        render: render,
        show
    }
}

interface ChatbotScreenRef{
    changeSession:(session: Session) => void
    sendMessage:(query: ChatbotQuery) => void
    appendMessage: (message: ChatbotMessage) => void
}

const useChatbotScreen = (props: PropsWithChildren<{position: Position}>) => {

    const [session, setSession] = useState<Session>()
    const [messages, setMessages] = useState<ChatbotMessage[]>(() => [])
    const [sending, setSending] = useState<boolean>(() => false)
    const [followUps, setFollowUps] = useState<ChatMessageFollowup[]>(() => [])
    const {updateSessionInfo} = useContext(ChatSessionContext)

    const changeSession = (session: Session) => {
        Env.DEBUG && console.log("changeSession: ", session);
        setSession(session)
        cleanMessages()
        if(!session.newSession){
            ChatbotDatasource.getAllChatsInOneSession({chat_session_id: session.id}).then(sessionMessages => {
                setMessages(sessionMessages.messages??[])
                const _followUps = sessionMessages.messages[sessionMessages.messages.length - 1].follow_ups;
                setFollowUps(_followUps??[])
            })
        }
        
    }

    const updateSession = (session: Session) => {
        Env.DEBUG && console.log("updateSession: ", session);
        setSession((current) => {
            return {...current, ...session}
        })
    }

    const sendMessage = (query: ChatbotQuery) => {

        setSending(true)
        cleanFollowUps()

        if(query.chat_type !== "focused"){
            const msg: ChatbotMessage = {
                text: (query as ChatbotQueryText).query,
                author: "human",
                type: "text"
            }
            setMessages(current => {
                return [...current, msg, {type: "loading", author: "ai"}]
            })
        }

        const chatStreamRequest: Record<string, any> = {...query}
        if(session?.id){
            chatStreamRequest['chat_session_id'] = session.id
        }
        const {getMessageType, stream, getWholeMessage, getTitleGenerationMessage} = ChatbotDatasource.chatStream(chatStreamRequest)
        getMessageType().then((messageType) => {
            //TODO chishijie 新会话更新session
            Env.DEBUG && console.log(`messageType: `, messageType)
            const feedback: ChatbotMessage = {
                type: messageType,
                author: "ai",
                stream
            }
            setMessages(current => {
                return [...current.filter(it => it.type !== "loading"), feedback]
            })
        })

        getWholeMessage().then((wholeMessage) => {
            const id = wholeMessage.chat_session_id;
            const type = wholeMessage.chat_session.chat_type;
            Env.DEBUG && console.log(`wholeMessage: `, wholeMessage);
            const _followUps = wholeMessage.messages[wholeMessage.messages.length - 1].follow_ups;
            setFollowUps(_followUps??[])
        }).finally(() => setSending(false))
        
        if(!session?.id){
            getTitleGenerationMessage().then((msg) => {
                Env.DEBUG && console.log("收到title generation message.", msg);
                const chatSession = msg?.chat_session as ChatSession
                setSession(current => {
                    if(chatSession.chat_type == "normal"){
                        updateSessionInfo(chatSession.id, chatSession.title)
                    }
                    return {...current, id: chatSession.id, newSession: false}
                })
                return
            })
        }
    }

    const appendMessage = (message: ChatbotMessage) => {
        setMessages(current => {
            return [...current, message]
        })
        setFollowUps(message.follow_ups??[])
    }

    const cleanMessages = () =>{
        setMessages([])
    }

    const cleanFollowUps = () => {
        setFollowUps([])
    }

    const cleanSession = () => {
        setSession(undefined)
    }

    // const bind = useScroll((state) => {
    //     console.log("state: ", state)
    // })

    

    const render = (
        <ChatbotContext.Provider value={{messages, sending, followUps, sendMessage, cleanFollowUps, appendMessage}}>
            <div className='chatbot__content-container'>
                {props.position == "screen" && <div className='chatbot__content__header'></div>}
                <Flex style={{flex: "1"}} vertical align='center' className='chatbot__content-scrollview'>
                    <Flex vertical gap={48} className='chatbot__content' style={{width: `var(--${props.position}-chat-width)`}}>
                        {props.position == "screen" && <ChatbotHeader position={props.position} />}
                        <ChatbotContentBody position={props.position} ></ChatbotContentBody>
                    </Flex>
                </Flex>
                <ChatbotContentFooter position={props.position}></ChatbotContentFooter>
            </div>
        </ChatbotContext.Provider>
    )

    return {
        render,
        sendMessage,
        cleanMessages,
        appendMessage,
        changeSession,
        updateSession,
        cleanSession
    }

}

const ChatbotHeader = (props: {position: Position}) => {

    const {sendMessage, appendMessage} = useContext(ChatbotContext)
    const shortcuts = ChatbotDatasource.shortcuts

    const doPrompt = (idx: number) => {
        if(shortcuts[idx].action === "prompt"){
            sendMessage({
                query: shortcuts[idx].title
            })
        }else{
            appendMessage({
                text: shortcuts[idx].title,
                type: "gotoprofile",
                author: "system",
                follow_ups: [
                    {
                        action: "gotoprofile",
                        text: "Proceed to Client Management",
                        prompt: ""
                    }
                ]
            })
        }
    }

    return (
        <Flex gap={24} vertical className='chatbot__header' style={{width: `var(--${props.position}-chat-width)`}}>
            <Flex gap={20} vertical align='center' className='chatbot__title'>
                <img className='chatbot__title__logo' src={AiChatAvatarPng}></img>
                <Flex gap={0} vertical>
                    <div className='chatbot__title__title'>Welcome to Leazy.AI</div>
                    <div className='chatbot__title__subTitle'>Your AI-powered copilot for Lawyer</div>
                </Flex>
            </Flex>
            <Row className='chatbot__shortcuts' gutter={8}>
                {
                    shortcuts.map((it, idx) => (
                        <Col key={idx} span={24 / shortcuts.length}>
                            <Flex gap={0} vertical className='chatbot__shortcut' onClick={() => doPrompt(idx)}>
                                <div className='chatbot__shortcut__title'>{it.title}</div>
                                <div className='chatbot__shortcut__subTitle'>{it.description}</div>
                            </Flex>
                        </Col>
                    ))
                }
            </Row>
        </Flex>
    )
}

const BodyContext = createContext<{scrollToBottom: (smooth?: boolean) => void}>({scrollToBottom(smooth?: boolean){throw new Error("unimplement")}})

const ChatbotContentBody = (props: {position: Position}) => {

    const {messages, sending} = useContext(ChatbotContext)
    const {position} = props
    const bottomRef = useRef<any>()
    

    useEffect(() => {

        // const onScroll = (e: Event) => {
        //     const _scrollTo = (e.target as HTMLDivElement).scrollTo
            
        // }

        // const onScrollEnd = (e: Event) => {
            
        // }
        
        // window.addEventListener("wheel", () => {
        //     console.log("scroll!!!")
        // })
        // window.addEventListener("scroll", () => {
        //     // console.log("scroll!!!")
        // }, true)
        // window.addEventListener("scroll", (e) => {
            
        // }, true)

        // window.addEventListener("scrollend", (e) => {
        //     console.log(`scroll end`)
        // }, true)
    }, [])

    const messageRender = (message: ChatbotMessage) => {
        switch(message.type){
            case 'welcome':
                return <ChatbotMessageWelcome {...message} position={position}/>
            case 'gotoprofile':
                return <ChatbotMessageProfile {...message} position={position}/>
            case 'loading':
                return <LoadingAnimation/>
            case "qa":
                return <ChatbotMessageQa {...message} position={position} />
            case "document_link":
                return <ChatbotMessageDocumentLink {...message} position={position}/>
            case "resource_list":
                return <ChatbotMessageResourceList {...message} position={position}/>
            case "visa_bulletin":
                return <ChatbotMessageVisaBulletin {...message} position={position}/>
            default: 
                return <ChatbotMessageText {...message} position={position}/>
        }
    }

    const scrollToBottom = (smooth: boolean = true) => {
        window.requestAnimationFrame(() => {
            (bottomRef.current as HTMLDivElement).scrollIntoView({behavior: smooth? "smooth":"auto"});
        })
    }

    return (
        <BodyContext.Provider value={{scrollToBottom}}>

            <Flex vertical gap={24} className='chatbot__content__body' style={{width: `100%`}}>
                {messages.map((message, idx) => {
                    if(message.type == "loading"){
                        return <LoadingAnimation/>
                    }else{
                        return (
                            <ChatbotMessageBox key={idx} author={message.author}>
                                {messageRender(message)}
                            </ChatbotMessageBox>
                        )
                    }
                })}
                <div ref={bottomRef} id='chatbot__content__body__bottom' style={{width: "100%", height: "1px"}}></div>
            </Flex>
                
        </BodyContext.Provider>
    )
}


interface MessageIndexKey {
    sessionId: string
    msgId?: string
    feedback?: ChatbotMessage['feedback']
}
const ChatbotMessageBoxContext = createContext<{key?: MessageIndexKey, setKey: (key: MessageIndexKey) => void}>({setKey(key) {
    throw new Error("unimplement")
},})

const ChatbotMessageBox = (props: PropsWithChildren<{author: ChatbotMessageAuthor}>) => {

    const [key, setKey] = useState<MessageIndexKey>()

    const doThumbup = () => {
        key && setKey({...key, feedback: "up"})
        key && key.msgId && ChatbotDatasource.msgFeedback(key.sessionId, key.msgId, "up")
    }
    
    const doThumbDown = () => {
        key && setKey({...key, feedback: "down"})
        key && key.msgId && ChatbotDatasource.msgFeedback(key.sessionId, key.msgId, "down")
    }

    const feedback = (
        <Flex align='center' gap={8} className='chatbot__msg__feedback'>
            <ThumbUp onClick={doThumbup} activate={key?.feedback === "up"}></ThumbUp>
            <ThumbDown onClick={doThumbDown} activate={key?.feedback === "down"}></ThumbDown>                  
        </Flex>
    )

    return (
        <ChatbotMessageBoxContext.Provider value={{key, setKey}}>
            <Flex
                style={{flex: 1}}
                justify={props.author == 'human'? 'flex-end': 'flex-start'} 
            >
                <div 
                    className={['chatbot__msg', `chatbot__msg_${props.author}`].join(" ")}
                >
                    <Flex vertical gap={24}>
                        {props.children}
                        {/* {props.author == "ai" && key && feedback} */}
                    </Flex>
                </div>
            </Flex>
        </ChatbotMessageBoxContext.Provider>
    )
}

const ChatbotMessageWelcome = (props: PositionProps<ChatbotMessage>) => {

    return (
        <>
            <div className='chatbot__msg_text' style={{maxWidth: `var(--${props.position}-chat-msg-default-width)`}}>
                Hey, feel free to pick from the topics above to keep the conversation going, or if you've got any questions, just ask away!
            </div>
        </>
        
    )

}

const ChatbotMessageProfile = (props: PositionProps<ChatbotMessage>) => {

    return (
        <div className='chatbot__msg_text' style={{maxWidth: `var(--${props.position}-chat-msg-default-width)`}} >
            <div>
            Quick Case Evaluation involves uploading a client's resume or entering their details to compare against successful cases in a database, thereby providing
            a preliminary ranking of their EB1/NIW eligibility.
            <br />
            <br /> To ensure no information is missed, we recommend using our client management system to create and evaluate your client's case. Ready to proceed?
            Click the button below, or stay on this page if you have further questions.
            </div>
        </div>
        
    )

}

//


const ChatbotMessageText = (props: PositionProps<ChatbotMessage>) => {

    const [text, setText] = useState<string>("")
    const {setKey} = useContext(ChatbotMessageBoxContext)
    const {session} = useContext(ChatSessionContext)
    const {scrollToBottom} = useContext(BodyContext)

    useEffect(() => {
        props.stream?.((msg) => {
            // console.log("msg: ", msg)
            if(msg.content_type === "llm_chunk"){
                setText(current => {
                    return current + msg.content
                })
                scrollToBottom(false)
            }
            
            if(msg.data_category === "whole_message"){
                const messageSession = (msg.content as ChatbotWholeMessage)
                const messages = messageSession.messages
                for(let message of messages){
                    setText(message.text??"")
                    setKey({sessionId: messageSession.chat_session_id, msgId: message.id})
                }
                scrollToBottom()
            }
            
        })
        if(!(props.stream)){
            setText(props.text??"")
            session?.id && setKey({sessionId: session?.id, msgId: props.id, feedback: props.feedback})
            scrollToBottom()
        }
    }, [])


    return (
        <>
            <div className={['chatbot__msg_text', props.author === "human"?'chatbot__msg_text_human':'', props.author === "human"?`chatbot__msg_text_human_${props.position}`:""].join(" ")}>
                {text}
            </div>
        </>
        
    )
}

const ChatbotMessageQa = (props: PositionProps<ChatbotMessage>) => {

    const [text, setText] = useState<string>(() => "")
    const [relatedTopics, setRelatedTopics] = useState<string[]>()
    const {sendMessage} = useContext(ChatbotContext)
    const {setKey} = useContext(ChatbotMessageBoxContext)
    const {session} = useContext(ChatSessionContext)
    const [references, setReferences] = useState<ChatMessageReference[]>(() => [])
    const {scrollToBottom} = useContext(BodyContext)

    const referenceWrap = (_text: string) => {
        const matches = [..._text.matchAll(/\[\[citation:([\d])+\]\]/gi)]
        if(matches.length > 0){
            const items: ReactNode[] = []
            let cursor = 0
            for(let match of matches){
                const citation = match[0]
                const no = parseInt(match[1])
                const startAt = match['index']??cursor

                // Env.DEBUG && console.log("citation: ", citation, " no: ", no, " startAt: ", startAt)

                items.push(<>{_text.slice(cursor, startAt)}</>)
                items.push((
                    references[no - 1] && <Citation reference={references[no - 1]}>{no}</Citation>
                ))

                cursor = startAt + citation.length
            }
            items.push(_text.slice(cursor))
            // Env.DEBUG && console.log("reference items: ", items)
            return items
        }
        return _text
    }

    useEffect(() => {

        if(!props.stream){
            const payload = (props.payload as ChatbotPayloadQa)
            setText(payload.answer??"")
            setRelatedTopics(payload.related_topics)
            session && setKey({sessionId: session?.id, msgId: props.id, feedback: props.feedback})
            setReferences(payload.references??[])
            // Env.DEBUG && console.log("references: ", payload.references??[])
            scrollToBottom()
        }else{
            props.stream?.((msg) => {
                // console.log("msg: ", msg)
                if(msg.data_category === "payload"){
                    if(msg.content_type === "llm_chunk"){
                        setText(current => {
                            return current + msg.content
                        })
                        scrollToBottom(false)
                    }
                    if(msg.content_type === "json"){
                        setReferences(msg.content as ChatMessageReference[])
                        scrollToBottom()
                    }
                }
                
                if(msg.data_category === "whole_message"){
                    const messageSession = (msg.content as ChatbotWholeMessage)
                    const messages = (msg.content as ChatbotWholeMessage).messages
                    for(let message of messages){
                        const payload = (message.payload as ChatbotPayloadQa)
                        setText(payload.answer??"")
                        setRelatedTopics(payload.related_topics)
                        setKey({sessionId: messageSession.chat_session_id, msgId: message.id})
                        setReferences(payload.references??[])
                    }
                    scrollToBottom()
                }
                
                
            })
        }

    }, [])

    const doPrompt = (prompt: string) => {
        sendMessage({
            query: prompt
        })
    }

    const relatedTopicsRender = (
        relatedTopics && relatedTopics.length > 0 &&
            <Flex vertical gap={12} className='chatbot__msg_qa__relatedTopics'>
                <div className='chatbot__msg_qa__relatedTopics__title'>
                    Related Topics
                </div>
                <Divider/>
                <Flex vertical align="flex-start" gap={12}>
                    {
                        relatedTopics.map(topic => (
                            <div className='chatbot__msg_qa__relatedTopic' onClick={() => doPrompt(topic)}>{topic}</div>
                        ))
                    }
                </Flex>
            </Flex>
    )

    return (
        <>
            <Flex vertical gap={24} className='chatbot__msg_qa' style={{maxWidth: `var(--${props.position}-chat-msg-qa-width)`}}>
                <div className='chatbot__msg_qa__text'>
                    {text && text !== "" && referenceWrap(text)}
                </div>
                {relatedTopicsRender}
            </Flex>
        </>
        
    )
}

const ChatbotMessageDocumentLink = (props: PositionProps<ChatbotMessage>) => {

    const [link, setLink] = useState<string>();
    const [height, setHeight] = useState<number>(() => 500)
    const {setKey} = useContext(ChatbotMessageBoxContext)
    const {session} = useContext(ChatSessionContext)
    const {scrollToBottom} = useContext(BodyContext)
    const [html, setHtml] = useState<string>()

    useEffect(() => {
        if(!props.stream){
            setLink(props.link)
            session && setKey({sessionId: session?.id, msgId: props.id, feedback: props.feedback})
            scrollToBottom()
        }else{
            props.stream?.(msg => {
                if(msg.data_category === "whole_message"){
                    const messageSession = (msg.content as ChatbotWholeMessage)
                    const messages = (msg.content as ChatbotWholeMessage).messages
                    for(let message of messages){
                        setLink(message.link)
                        setKey({sessionId: messageSession.chat_session_id, msgId: message.id})
                    }
                    scrollToBottom()
                }
            }) 
        }
               
    }, [])

    const loadHandle = (e: HTMLIFrameElement) => {
        if(e.contentDocument){
            setHeight(e.contentDocument.body.scrollHeight)
        }
    }

    return (
        <>
            <div className='chatbot__msg_documentlink' style={{width: `var(--${props.position}-chat-msg-documentlink-width)`}}>
                {link && <HtmlViewer link={link}/>
                // <div dangerouslySetInnerHTML={}></div>
                // <iframe 
                //     src={link} 
                //     style={{width: "100%", border: "0", margin: "0", padding: "0", height: height, overflow: "auto"}} 
                //     seamless={true} 
                //     // scrolling='no' 
                //     frameBorder={0}
                //     onLoad={e => loadHandle(e.target as HTMLIFrameElement)}>
                // </iframe>
                }
            </div>
        </>
    )
}

const ChatbotMessageResourceList = (props: PositionProps<ChatbotMessage>) => {

    const [resources, setResources] = useState<ChatbotResource[]>(() => [])
    const {render: modelRender, show} = useChatbotResourceModal()
    const {sendMessage} = useContext(ChatbotContext)
    const {setKey} = useContext(ChatbotMessageBoxContext)
    const {session} = useContext(ChatSessionContext)
    const {scrollToBottom} = useContext(BodyContext)
    useEffect(() => {
        
        if(!props.stream){
            setResources((props.resources as ChatbotResource[]))
            session && setKey({sessionId: session?.id, msgId: props.id, feedback: props.feedback})
            scrollToBottom()
        }else{
            props.stream?.((msg) => {
            
                if(msg.data_category === "whole_message"){
                    const messageSession = (msg.content as ChatbotWholeMessage)
                    const messages = (msg.content as ChatbotWholeMessage).messages
                    for(let message of messages){
                        setResources((message.resources as ChatbotResource[]))
                        setKey({sessionId: messageSession.chat_session_id, msgId: message.id})
                    }
                    scrollToBottom()
                }

                
            })
        }
        

    }, [])


    const resourceRender = (resource: ChatbotResource) => {

        const doAction = (idx: number) => {
            const action = resource.actions[idx]
            if(action.action_type === "prompt"){
                sendMessage({
                    query: action.prompt
                })
            }
        }
        return (
            <Flex vertical gap={12} className='chatbot__msg_resource' >
                {resource.title}
                <div className='chatbot__msg_resource__divider'></div>
                <Flex gap={8}>
                    <Flex gap={8} align='center' className='chatbot__msg_resource__action' onClick={() => doAction(0)}>
                        <img className='chatbot__msg_resource__action__icon' src={file02Png}></img>
                        {resource.actions[0].label_text}
                    </Flex>                    
                    <Flex gap={8} align='center' className='chatbot__msg_resource__action' onClick={() => show(resource)}>
                        <img className='chatbot__msg_resource__action__icon' src={fileSearch02Png}></img>
                        {resource.actions[1].label_text}
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    
    return (
        <>
            <Flex vertical gap={24} className='chatbot__msg_resourcelist' style={{width: `var(--${props.position}-chat-msg-resourcelist-width)`}}>
                {resources.map(it => resourceRender(it))}
                {modelRender}
            </Flex>
        </>
    )
}

const ChatbotMessageVisaBulletin = (props: PositionProps<ChatbotMessage>) => {

    const [updateTime, setUpdateTime] = useState<string>()
    const [tables, setTables] = useState<VisaBUlletinTableConfig[]>(() => [])
    const {setKey} = useContext(ChatbotMessageBoxContext)
    const {session} = useContext(ChatSessionContext)
    const {scrollToBottom} = useContext(BodyContext)

    useEffect(() => {

        if(!props.stream){
            const payload = props.payload as ChatPayloadVisaBulletin
            const _tables = Object.entries(payload.tables).map(([title, tableConfig])=> {
                const cols = [
                    {
                        label: title,
                        name: "visa_type"
                    },
                    ...tableConfig.nationalities.map(it => {return {label: it, name: it}})
                ]
                const records = tableConfig.dates.map(it => {return {...it.values, visa_type: it.visa_type}})
                return {
                    cols, records
                }
            // return <VisaBUlletinTable cols={cols} records={records}></VisaBUlletinTable>
            })
            setTables(_tables)
            setUpdateTime(payload.update_time)
            session && setKey({sessionId: session?.id, msgId: props.id, feedback: props.feedback})
            scrollToBottom()
        }else{
            props.stream?.((msg) => {
                if(msg.data_category === "whole_message"){
                    const messageSession = (msg.content as ChatbotWholeMessage)
                    const messages = (msg.content as ChatbotWholeMessage).messages
                    // console.log("messages: %o", messages)
                    for(let message of messages){
                        const payload = message.payload as ChatPayloadVisaBulletin
                        const _tables = Object.entries(payload.tables).map(([title, tableConfig])=> {
                            const cols = [
                                {
                                    label: title,
                                    name: "visa_type"
                                },
                                ...tableConfig.nationalities.map(it => {return {label: it, name: it}})
                            ]
                            const records = tableConfig.dates.map(it => {return {...it.values, visa_type: it.visa_type}})
                            return {
                                cols, records
                            }
                            // return <VisaBUlletinTable cols={cols} records={records}></VisaBUlletinTable>
                        })
                        setTables(_tables)
                        setUpdateTime(payload.update_time)
                        setKey({sessionId: messageSession.chat_session_id, msgId: message.id})
                    }
                    scrollToBottom()
                }
                
            })
        }

    }, [])

    return (
        <>
            <Flex vertical gap={24} className='chatbot__msg_visabulletin' style={{width: `var(--${props.position}-chat-msg-bulletin-width)`}}>
                <div className='chatbot__msg_visabulletin__updateTime'>Update time: {updateTime}</div>
                {tables.map(table => <VisaBUlletinTable cols={table.cols} records={table.records}></VisaBUlletinTable>)}
            </Flex>
        </>
    )
}

interface VisaBUlletinTableConfig{
    cols: {label: string, name: string}[]
    records: Record<string, any>[]
}

const VisaBUlletinTable = (props: VisaBUlletinTableConfig) => {

    return (
        <table className='rb__table chatbot__msg_visabulletin__table'>
            <thead>
                <tr>
                    {props.cols.map(col => <th>{col.label}</th>)}
                </tr>
            </thead>
            <tbody>
                    {props.records.map(record => (
                        <tr>
                            {props.cols.map((col, idx) => {
                                if(idx == 0) return <th>{record[col.name]}</th>
                                return <td>{record[col.name]}</td>
                            })}
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}

const ChatbotContentFooter = (props: PositionProps<{}>) => {

    const {followUps, sendMessage, sending, cleanFollowUps} = useContext(ChatbotContext)
    const [input, setInput] = useState<string>()
    const navigate = useNavigate()

    const doSubmit = () => {
        Env.DEBUG && console.log("Do subtmit")
        if(input){
            sendMessage({
                query: input
            })
            setInput("")
        }
    }

    const validate = () => {
        return !sending && (input && input.trim() !== "");
    }

    const doFollowUp = (idx: number) => {
        const followUp = followUps[idx]
        if(followUp.action === "prompt"){
            sendMessage({
                query: followUp.prompt 
            })
        }else if(followUp.action === "gotoprofile"){
            navigate("/workbench/clientmanagement/client/home/0/0")
        }
    }

    return (
        <form onSubmit={(e) => {doSubmit(); e.stopPropagation();e.preventDefault()}}>
            <div className='chatbot__content__footer'>
                <Flex gap={8} vertical justify="flex-end" className='chatbot__input' style={{width: `var(--${props.position}-chat-width)`}}>
                    <Flex gap={8} justify='flex-end' className='chatbot__input__tips'>
                        {followUps.map((followUp, idx) => (
                            <div key={idx} className='chatbot__input__tip' onClick={() => doFollowUp(idx)}>
                                {followUp.text}
                            </div>
                        ))}
                    </Flex>
                    <Flex gap={8}>
                        <input 
                            style={{flex: 1}} 
                            className='chatbot__input__input' 
                            placeholder='Send a message'
                            onChange={(e) => {setInput(e.target.value)}}
                            onKeyDown={e => {if(e.key === 'Enter') doSubmit()}}
                            value={input}
                        ></input>
                        <div 
                            className={['chatbot__input__btn', validate()?'':'chatbot__input__btn_disabled'].join(" ")}
                        >
                            <img className='chatbot__input__btn__icon' src={InputBtnPng} onClick={doSubmit}></img>
                        </div>
                    </Flex>
                    <div className='chatbot__input__warn'>Information generated by Leazy.ai may not be 100% correct and updated. Please ensure all content generated is reviewed by a licensed lawyer before official use.</div>
                </Flex>
            </div>
        </form>
        
    )
}