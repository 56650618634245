
import { useContext, useEffect } from "react";
import styles from  "./ChatScreen.module.scss";
import { ChatScreenContext } from "../context/ChatScreenContext";
import { Flex } from "antd";
import { ChatMessageDTO, RankAnalysePayload } from "../../api/Chat.api";
import Env from "../../../Env";
import LoadingIcon from "../../components/icon/LoadingIcon";

export default () => {

    const {messages, loading} = useContext(ChatScreenContext)


    useEffect(() => {
        Env.DEBUG && console.log("messages changed: ", messages)
        if(messages){
            setTimeout(() => {
                const screenDom = document.querySelector("#chat-screen")
                screenDom?.scrollTo({top: screenDom.scrollHeight, behavior: "smooth"})
                // Env.DEBUG && console.log("scroll height: ", document.querySelector("#chat-screen")?.scrollHeight)
            }, 100);
        }
    }, [messages])

    const messageRender = (message: ChatMessageDTO) => {
        switch(message.type){
            case "rank_analysis":
                return <RankAnalyse message={message}/>
            default:
                return <TextMessage message={message}/>
        }
    }

    return (
        <div className={styles['chat-screen']} id="chat-screen">
            {
                loading
                ?
                <LoadingIcon/>
                :
                <Flex vertical gap={32}>
                    {messages.map(message=> messageRender(message))}
                </Flex>
            }
        </div>
    )
}


const TextMessage = (props: {message: ChatMessageDTO}) => {

    return (
        <div className={styles['chat-message_text']}>
            <div dangerouslySetInnerHTML={{__html: props.message.payload as string}}></div>
        </div>
    )

}

const RankAnalyse = (props: {message: ChatMessageDTO}) => {

    const rankAnalyse = (props.message.payload as RankAnalysePayload)
    const backgroundData = rankAnalyse.background

    const backgroundRender = () => {

        return (
            <table className={styles["rank-analyse__background"]}>
                {
                    Object.entries(backgroundData).map(([key, value]) => {
                        return (
                            <tr>
                                <td>{key}</td>
                                <td>{value}</td>
                            </tr>
                        )
                    })
                }
            </table>
        )

    }

    const analyseRender = () => {

        const cols = [{
            label: "VisaType"
        }]
        Object.keys(rankAnalyse.analysis_result).forEach(key => {
            cols.push({label: key})
        })
        const props = [
            {
                label: "Number of Published Papers",
                name: "paper_amount"
            },
            {
                label: "Number of Citations Received",
                name: "citation_amount"
            },
            {
                label: "Number of Peer Reviews",
                name: "review_amount"
            },
            {
                label: "Number of Patents Filed/Granted",
                name: "patent_amount"
            },
            {
                label: "Number of Awards and Honors",
                name: "award_amount"
            }
        ]

        const getItemValue = (type: String, name: string) => {
            return <>{rankAnalyse.analysis_result[`${type}`].measurements[`${name}`].performance}</>
        } 

        return (
            <table className={styles["rank-analyse__result"]}>
            {
                <tr>
                    {
                        cols.map(col => (
                            <td>{col.label}</td>
                        ))
                    }
                </tr>
            }
            {
                <>
                    {
                        props.map(prop => (
                            <tr>
                                <td>{prop.label}</td>
                                {
                                    Object.keys(rankAnalyse.analysis_result).map(key => {
                                        return (
                                            <td>{getItemValue(key, prop.name)}</td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                </>
            }
        </table>     
        )
    }

    return (
        <Flex vertical className={styles['rank-analyse']} gap={24}>
            <div className={styles['chat-message_text']}>
                {rankAnalyse.description}
            </div>
            {
                backgroundRender()
            }
            {
                analyseRender()
            }
        </Flex>
    )
}