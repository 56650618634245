


import { Checkbox, Col, ConfigProvider, Flex, Row, Upload, UploadProps } from "antd"
import styles from "./FormFillingForm.module.scss"
import Icon from "../components/icon/Icon"
import logOutSvg from "./assets/images/log-out-04.svg"
import { CSSProperties, PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import checkCircleSvg from "./assets/images/check-circle.svg"
import formFillingJson from "./assets/data/formfilling.json"
import FormFillingApi, { DocumentType, FormFillingTaskDTO, ProofDoc, TaskStatus } from "../api/FormFilling.api"
import Env from "../../Env"
import ReviewClientInformation from "./ReviewClientInformation"
import ReviewLawyerInformation from "./ReviewLawyerInformation"
import { FormFillingFormContext, convertToStep, useFormFillingFormContext } from "./FormFillingFormContext"
import SelectYourForms from "./SelectYourForms"
import UploadSourceDocuments from "./UploadSourceDocuments"
import DownloadYourForms from "./DownloadYourForms"
import ProtectPage from "../components/layout/ProtectPage"

const {
    form_types
} = formFillingJson


export default (props: {onClose?: (forceRefresh?: boolean) => void, initial?: FormFillingTaskDTO}) => {

    const context = useFormFillingFormContext()
    const {
        step,
        setStep,
        setData
    } = context

    const {
        initial
    } = props

    useEffect(() => {
        if(initial){
            FormFillingApi.detail(initial.case_id, initial.id).then(res => {
                Env.DEBUG && console.log("task detail: ", res);
                const currentStep = convertToStep(res.status)
                setStep(currentStep)
                setData(res)
            })
        }
    }, [])

    const chooseContent = () => {
        switch(step){
            case 0:
                return <SelectYourForms />
            case 1:
                return <UploadSourceDocuments  />
            case 2:
                return <ReviewClientInformation/>
            case 3:
                return <ReviewLawyerInformation/>
            case 4:
                return <DownloadYourForms onFinish={(forceRefresh) => {
                    props.onClose?.(forceRefresh)
                }}/>
            default:
                return <SelectYourForms/>
        }
    }

    return (
        <FormFillingFormContext.Provider value={context}>
            <ProtectPage className={styles["form"]}>
                <Flex vertical style={{height: "100%"}}>
                    <Flex gap={32} align="center" className={styles["form__header"]}>
                        <Icon size={24} src={logOutSvg} onClick={() => props.onClose?.()}></Icon>
                        AI Form Filling
                    </Flex>       
                    <Flex style={{flex: 1, minHeight: 1}}>
                        <div className={styles["form__wizard"]}>
                            <Wizard/>
                        </div>
                        <div className={styles["form__content"]} style={{flex: 1}}>
                            {chooseContent()}
                        </div>
                    </Flex>     
                </Flex>
            </ProtectPage>
        </FormFillingFormContext.Provider>
    )
}

const Wizard = () => {

    const {step, setStep, data} = useContext(FormFillingFormContext)

    const stepCfgs = [
        {
            label: "Select your forms",
            step: 0
        },
        {
            label: "Upload source documents",
            step: 1
        },
        {
            label: "Review client’s information",
            step: 2
        },
        {
            label: "Review lawyer's information",
            step: 3
        },
        {
            label: "Download your forms",
            step: 4
        }
    ]

    const disabled = (current: number) => {
        return current > convertToStep(data?.status)
    }

    return (
        <div className={styles['wizard']}>
            <div className={styles['wizard__title']}>My Progress</div>
            <div className={styles['wizard__subtitle']}>AI Form Filling</div>
            <Flex vertical gap={4} className={styles['wizard__list']}>
                {
                    stepCfgs.map((cfg, idx) => (
                        <Flex 
                            align="center"
                            className={[
                                styles['wizard__list__item'], 
                                cfg.step == step?styles['wizard__list__item_activate']:'',
                                cfg.step < convertToStep(data?.status)?styles['wizard__list__item_finish']:'',
                                disabled(cfg.step)?styles['wizard__list__item_disabled']:''
                            ].join(" ")} onClick={() => {if(!disabled(cfg.step)){setStep(idx)}}}>
                            {cfg.label}
                            <div className={styles['wizard__list__item__icon']}>
                                {
                                    cfg.step < convertToStep(data?.status)
                                    &&
                                    <Icon src={checkCircleSvg} size={20}></Icon>
                                }
                            </div>
                        </Flex>
                    ))
                }
            </Flex>
        </div>
    )

}


export const StepForm = (props: PropsWithChildren<{title: string, subtitle: string}>) => {
    
    return (
        <Flex vertical gap={32} className={styles['stepform']}>
            <Flex vertical gap={24} className={styles['stepform__header']}>
                AI Form Filling
                <Flex vertical gap={4}>
                    <div className={styles["stepform__title"]}>{props.title}</div>
                    <div className={styles["stepform__subtitle"]}>{props.subtitle}</div>
                </Flex>
            </Flex>
            <div className={styles['stepform__body']}>
                {props.children}
            </div>
        </Flex>
    )
}

