import { CSSProperties, ReactNode } from "react"

import styles from "./Icon.module.scss";

type DivType = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export interface IconProps{
    size: number
    padding?: number
    src: string
    className?: string
    onClick?: DivType['onClick']
    hoverable?: boolean
    height?: number
}

export default (props: IconProps) => {
    
    const imgStyles: CSSProperties = {
        width: `${props.size}px`,
        height: `${props.height??props.size}px`
    }

    const boxStyles: CSSProperties = {
        display: "inline-block",
        padding: `${props.padding??0}px`,
        lineHeight: 0,
        cursor: "pointer"
    }

    return (
        <div style={boxStyles} className={[props.className, props.hoverable?styles['icon']: ''].join(" ")} onClick={props.onClick}>
            <img src={props.src} style={imgStyles}></img>
        </div>
    )

}