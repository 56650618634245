

export interface HttpResponse<T>{
    code: "200" | string
    data: T
    message: string
    status: string
}

export const checkCode = <T>(response: HttpResponse<T>) => {
    if(response.code !== "200") throw new Error(response.code??response.message??"服务端请求错误")
    
}