
import { Flex, Row } from "antd";
import styles from "./Footer.module.scss";

import logoPng from "../asests/logo@3x.png"
import Icon from "../../components/icon/Icon";

export default () => {


    return (
        <Flex vertical gap={40} className={styles['footer']}>
            <Flex gap={6} align="center" className={styles['footer__logo']}>
                <Icon size={40} padding={0} src={logoPng}></Icon>
                <div className={styles['footer__logo__text']}>Leazy.ai</div>
            </Flex>
            <Flex gap={40}>
                <Flex vertical gap={8} className={styles['footer__address']}>
                    <div className={styles['footer__address__label']}>Address</div>
                    <div className={styles['footer__address__content']}>
                        Roundblock Technology LLC 3400 Cottage Way, Ste G2 #14254 Sacramento, California 95825
                    </div>
                </Flex>
                <Flex vertical gap={8} className={styles['footer__email']}>
                    <div className={styles['footer__email__label']}>Email</div>
                    <div className={styles['footer__email__content']}>
                        info@roundblock.io
                    </div>
                </Flex>
            </Flex>
            <Flex gap={57} className={styles['footer__copyright']}>
                © 2023 Roundblock. All rights reserved.
                Privacy
            </Flex>
        </Flex>
    )
}