import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface AwardDTO{
  id: string
  awardName: string
  issueAuthority: string
  authorRanking:string
  year: string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query GetAwards($caseFilingTaskId: ID!) {
    getAwards(caseFilingTaskId: $caseFilingTaskId) {
      id
      awardName
      issueAuthority
      authorRanking
      year
      clientProfileInfoId
      userId
    }
  }
  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getAwards'] as AwardDTO[]

}

const update = async (taskId: string, request: {created?: AwardDTO[], updated?: AwardDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: AwardDTO) => {
  const operation = `
  mutation CreateAwards($awardInputs: [AwardInput!]!, $caseFilingTaskId: ID!) {
    createAwards(awardInputs: $awardInputs, caseFilingTaskId: $caseFilingTaskId) {
      id
      awardName
      issueAuthority
      authorRanking
      year
      clientProfileInfoId
      userId
    }
  }
  `
  const variables = {
    "awardInputs": [{
      "awardName": request.awardName,
      "issueAuthority": request.issueAuthority,
      "authorRanking": request.authorRanking,
      "year": request.year,
    }],
    "caseFilingTaskId": taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables,
      caseFilingTaskId: taskId
    }
)).data.data['createAwards'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeleteAward($awardId: ID!) {
    deleteAward(awardId: $awardId)
  }
  `
  const variables = {
    awardId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: AwardDTO) => {

  const operation = `
  mutation EditAward($awardInput: AwardInput!, $awardId: ID!) {
    editAward(awardInput: $awardInput, awardId: $awardId) {
      id
      awardName
      issueAuthority
      authorRanking
      year
      clientProfileInfoId
      userId
    }
  }
  `

  const variables = {
    "awardInput": {
      "awardName": request.awardName,
      "issueAuthority": request.issueAuthority,
      "authorRanking": request.authorRanking,
      "year": request.year,
    },
    "awardId": request.id
  }

  await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
)

}

export default {
    list,
    update,
    create,
    edit,
    remove
}