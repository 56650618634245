
import { Flex } from "antd"
import { StepForm } from "../CaseFillingForm"
import styles from "./UploadResume.module.scss"
import Form from "../../components/form/Form"
import DocumentUpload from "../components/DocumentUpload"
import FormItemLabel from "../components/FormItemLabel"
import { useFormInstance } from "../../components/form/FormContext"
import FormValidators from "../../components/form/FormValidators"
import CaseFillingApi, { InfoSource, ResumeInfoSource } from "../../api/CaseFilling.api"
import { useContext, useEffect, useState } from "react"
import { ClientCaseContext } from "../../clientmanagement/context/ClientCaseContext"
import { CaseFilingSteps, CaseFillingFormContext, getActivateStep } from "../context/CaseFillingFormContext"
import { CaseFilingNotificationContext, UploadResumeNotificationRender } from "../context/CaseFilingNotificationContext"
import { useMask } from "../../components/mask/Mask"
import { animated, useSpring, useSpringValue } from '@react-spring/web'
import userModal from "../../chatbot/components/antd/userModal"
import { ConfirmModal, DeleteModal } from "../../components/modal/Modal"
import Env from "../../../Env"



export default () => {

    return (
        <StepForm
            title="Upload resume"
            subtitle="Please kindly upload applicant’s resume using the “upload button” provided here. Please ensure that the resume is current and contains all essential details about the applicant, including their basic information, educational and work history, awards received, publications, patents, and other relevant information."
            required
        >
            <UploadResume/>
        </StepForm>
    )

}


const UploadResume = () => {

    const formInstance = useFormInstance({autoValidate: true})
    const {clientCase} = useContext(ClientCaseContext)
    const {record, getInfoSource, init, update, setStep, isFinish} = useContext(CaseFillingFormContext)
    const {basicProfileExtractionSucceeded, cleanBasicProfileExtractionSucceeded, setBroadcastMessage} = useContext(CaseFilingNotificationContext)
    const {render: maskRender, show, close} = useMask()

    useEffect(() => {

        if(record){
            const resume = getInfoSource("RESUME") as ResumeInfoSource
            const scholar = getInfoSource("GOOGLE_SCHOLAR_LINK")
            const initialData: Record<string, any> = {}
            if(resume){
                initialData['resume'] = {
                    "file_id": resume.file_id,
                    "name": resume.file_name,
                    "updated_at": resume.updated_at,
                    "size": resume.file_size
                }
            }
            if(scholar){
                initialData['google_scholar_link'] = scholar.source_data
            }
            formInstance.setInitial(initialData)
        }

    }, [record])

    useEffect(() => {
        if(basicProfileExtractionSucceeded){
            Env.DEBUG && console.log("notification basicProfileExtractionSucceeded: ", basicProfileExtractionSucceeded)
            const taskId = record?.id as string
            const caseId = record?.case_id as string
            (async () => {
                const _record = await CaseFillingApi.detail(caseId, taskId);
                if(_record.resume_extraction_status === "SUCCEEDED"){
                    // clearInterval(checkSchedule)
                    update(_record)
                    setStep(CaseFilingSteps.FieldOfApplication)
                    cleanBasicProfileExtractionSucceeded()
                    close()
                }
            })()
        }
    }, [basicProfileExtractionSucceeded])

    const doSubmitForm = async (form: any) => {
        show(<ConfirmModal 
            title="Are you sure you want to re-upload your resume?" 
            content="Attention: You are about to overwrite the information you've already filled in. This action cannot be undone." 
            onCancel={close} 
            onConfirm={() => {
                close();
                doSubmit(form);
            }}
        />)
    }

    const doSubmit = async (form: any) => {
        show(loadingRender)
        const caseId = clientCase?.case_id as string
        const request = {
            "file_id": form['resume']['file_id'],
            "google_scholar_link": form['google_scholar_link']
        }
        const taskId = record?.id
        if(!taskId){
            return CaseFillingApi.create(caseId, request).then(async (res) => {
                const newRecord = await CaseFillingApi.detail(caseId, res?.id as string)
                init(newRecord)
            })
        }else{
            return CaseFillingApi.update(caseId, taskId, request).then(async (res) => {
                const newRecord = await CaseFillingApi.detail(caseId, res?.id as string)
                update(newRecord)
            })
        }
        
    }

    const loadingRender = (
        <Flex align="center" justify="center" style={{"width": "100%"}}>
            <Flex vertical align="center" gap={10} className={styles['loading']}>
                <LoadingIcon/>
                <Flex vertical align="center" gap={4} className={styles['']}>
                    <div className={styles['loading__title']}>
                    Extracting in progress
                    </div>
                    <div className={styles['loading__content']}>
                        Your file has been successfully uploaded and is now being processed. The relevant information is being automatically filled into the sheet for your convenience.
                    </div>
                </Flex>
            </Flex>
        </Flex>
    )

    return (
        <Form.Form instance={formInstance} showError={false} onSubmit={(form) => {record?doSubmitForm(form):doSubmit(form)}}>
            <UploadResumeNotificationRender/>
            <Flex vertical gap={48}>
                <Flex vertical gap={32}>
                    <Form.Item 
                        nowrap 
                        label=""  
                        name="resume" 
                        required 
                        rules={[{validate: FormValidators.required, msg: "Resume is required"}]}
                    >
                        <DocumentUpload/>
                    </Form.Item>
                    <Form.Item
                        name="google_scholar_link"
                        label={
                            <FormItemLabel
                                title="Google scholar link"
                                subTitle="Please insert your google scholar author page URL."
                            ></FormItemLabel>
                        }
                    >
                        <Form.Input placeholder="www.abc.com" readOnly={isFinish(CaseFilingSteps.ReviewAndSubmit)} />
                    </Form.Item>
                </Flex>
                <Form.Item nowrap label="" name="">
                    <Flex justify="flex-end">
                        <Form.Submit width={185} disabled={isFinish(CaseFilingSteps.ReviewAndSubmit)}>Continue</Form.Submit>
                    </Flex>
                </Form.Item>
            </Flex>
            {maskRender}
        </Form.Form>
        
    )
}


const LoadingIcon = () => {

    const props = useSpring({ 
        from: {percent: 0},
        to: {percent: 99},
        config: {duration: 120000}
     });

    const springs = useSpring({
        from: { strokeDasharray: "0px 170px" },
        to: { strokeDasharray: "169px 1px" },
        config: {duration: 120000}
    })

    useEffect(() => {
        
    }, [])

    return (
        <Flex align="center" justify="center" className={styles['loading-icon']}>
            <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60}>
                <circle 
                    cx={30}
                    cy={30}
                    r={27}
                    stroke="#EAECF0" 
                    stroke-width="6" 
                    fill="none"
                />
                <animated.circle 
                    cx={30}
                    cy={30}
                    r={27}
                    transform="rotate(-90 30 30)"
                    stroke="#63ABA2" 
                    stroke-width="6" 
                    // strokeDasharray={"140px 30px"}
                    fill="none"
                    {...springs}
                />
            </svg>
            <div className={styles['loading-icon__label']}>
                <animated.span children={props.percent.to(it => it.toFixed(0))} />%
            </div>
        </Flex>
    )
}


