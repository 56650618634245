import { createContext, useState } from "react";
import { CaseDTO, CheckStatusMetadata, CaseTool, ClientDTO, VisaTypeMetadata, QuickEvaluationMetadata } from "../../api/Client.api";
import Env from "../../../Env";


export interface ClientCaseContextType{
    client?: ClientDTO
    
    cases?: CaseDTO[]
    loadingCases?: boolean
    setCases: (cases: CaseDTO[]) => void
    setLoadingCases: (loadingCases: boolean) => void

    clientCase?: CaseDTO
    setCase: (client: ClientDTO, clientCase: CaseDTO) => void
    updateClient: (client: ClientDTO) => void
    setTools: (tools: CaseTool[]) => void
    checkStatus?: CaseTool<CheckStatusMetadata>
    visaBulletin?: CaseTool<VisaTypeMetadata>
    quickEvaluation?: CaseTool<QuickEvaluationMetadata>

    setCheckStatusMetadata: (dto: CheckStatusMetadata) => void
    setVisaBulletinMetadata: (dto: VisaTypeMetadata) => void
    setQuickEvaluation: (dto: QuickEvaluationMetadata) => void
}

export const ClientCaseContext = createContext<ClientCaseContextType>({
    client: undefined,
    clientCase: undefined,
    setCase: function (client: ClientDTO, clientCase: CaseDTO): void {
        throw new Error("Function not implemented.");
    },
    setTools: function (tools: CaseTool<any>[]): void {
        throw new Error("Function not implemented.");
    },
    setCases: function (cases: CaseDTO[]): void {
        throw new Error("Function not implemented.");
    },
    setLoadingCases: function (loadingCases: boolean): void {
        throw new Error("Function not implemented.");
    },
    setCheckStatusMetadata: function (dto: CheckStatusMetadata): void {
        throw new Error("Function not implemented.");
    },
    setVisaBulletinMetadata: function (dto: VisaTypeMetadata): void {
        throw new Error("Function not implemented.");
    },
    updateClient: function (client: ClientDTO): void {
        throw new Error("Function not implemented.");
    },
    quickEvaluation: undefined,
    setQuickEvaluation: function (dto: QuickEvaluationMetadata): void {
        throw new Error("Function not implemented.");
    }
})


export const useClientCaseContext = (): ClientCaseContextType => {

    const [client, setClient] = useState<ClientDTO>()
    const [cases, setCases] = useState<CaseDTO[]>()
    const [loadingCases, setLoadingCases] = useState<boolean>()
    
    const [clientCase, setClientCase] = useState<CaseDTO>()
    const [checkStatus, setCheckStatus] = useState<CaseTool<CheckStatusMetadata>>()
    const [visaBulletin, setVisaBulletin] = useState<CaseTool<VisaTypeMetadata>>()
    const [quickEvaluation, setQuickEvaluation] = useState<CaseTool<QuickEvaluationMetadata>>()
    

    const setCase = (client: ClientDTO, clientCase: CaseDTO) => {
        setClient(client)
        setClientCase(clientCase)
    }

    const setTools = (tools: CaseTool[]) => {
        setCheckStatus(undefined)
        setVisaBulletin(undefined)
        setQuickEvaluation(undefined)
        Env.DEBUG && console.log("tools: ", tools)
        tools?.forEach(tool => {
            switch(tool.type){
                case "case_status":
                    setCheckStatus(tool)
                    break
                case "visa_bulletin":
                    setVisaBulletin(tool)
                    break
                case "quick_evaluation":
                    setQuickEvaluation(tool)
                    break
            }
        })
    }

    const setCheckStatusMetadata = (dto: CheckStatusMetadata) => {
        setCheckStatus((current) => {
            if(current){
                return {
                    ...current,
                    metadata: dto
                }
            }
            return current
        })
        //TODO chishijie
    }

    const setVisaBulletinMetadata = (dto: VisaTypeMetadata) => {
        setVisaBulletin((current) => {
            if(current){
                return {
                    ...current,
                    metadata: dto
                }
            }
            return current
        })
    }

    const _setQuickEvaluation = (dto: QuickEvaluationMetadata) => {
        setQuickEvaluation((current) => {
            if(current){
                return {
                    ...current,
                    metadata: dto
                }
            }
            return current
        })
    }

    const updateClient = (client: ClientDTO) => {
        setClient(client)
    }

    return {
        client,
        clientCase,
        setCase,
        setTools,
        checkStatus,
        visaBulletin,
        cases,
        setCases,
        loadingCases,
        setLoadingCases,
        setCheckStatusMetadata,
        setVisaBulletinMetadata,
        updateClient,
        quickEvaluation,
        setQuickEvaluation: _setQuickEvaluation
    }

}