import { createContext } from "react"


export interface ChatbotTipType{
    label: string
}

export type ChatbotMessageAuthor = "ai"|"human"|"system"
export type ChatbotMessageType = "welcome"|"gotoprofile"|"loading"|"text"|"qa"|"visa_bulletin"|"resource_list"|"document_link"
export type ChatbotContentType = "llm_chunk"|"whole_message"|"json"
export type ChatbotStream = (callback: (msg: ChatbotStreamMessage) => void, interval?: number) => void
export type ChatSessionType = "focused" | "normal"
export type ChatMessageFollowupAction = "prompt" | "gotoprofile"
export interface ChatMessageFollowup{
    action: ChatMessageFollowupAction,
    prompt: string
    text: string
}
export interface ChatMessageReference{
    index: number
    link: string
    title: string
}


export interface ChatbotMessage{
    id?: string
    text?: string
    author: ChatbotMessageAuthor
    type: ChatbotMessageType
    contentType?: ChatbotContentType
    content?: string
    stream?: ChatbotStream
    payload?: ChatbotPayload
    resources?: ChatbotResource[]
    created_at?: number
    feedback?: "undecided"|"up"|"down"
    link?: string
    follow_ups?: ChatMessageFollowup[]
}
export type ChatbotQuery = ChatbotQueryText | ChatbotQueryFocused
export interface ChatbotQueryText{
    query: string
    chat_type?: ChatSessionType
}
export interface ChatbotQueryFocused{
    chat_type?: ChatSessionType
    resource_id: number
    resource_type: string
}

export interface ChatbotContextType{
    followUps: ChatMessageFollowup[]   
    messages: ChatbotMessage[] 
    sending: boolean
    sendMessage: (message: ChatbotQuery) => void
    cleanFollowUps: () => void
    appendMessage: (message: ChatbotMessage) => void
}
export interface ChatbotWholeMessage{
    messages: ChatbotMessage[]
    chat_session_id: string
    chat_session:{chat_type: ChatSessionType}
}
export interface ChatSession{
    chat_type: ChatSessionType, 
    id: string, 
    title?: string
}

export interface ChatbotStreamMessage{
    message_type: ChatbotMessageType
    data_category: "payload"|"whole_message"|"title_generation"
    field: string
    content_type:  ChatbotContentType
    content: string | ChatMessageReference[] | ChatbotWholeMessage
    payload: ChatbotPayload
    chat_session?: ChatSession
}

export type ChatbotPayload = string | ChatbotPayloadQa | ChatPayloadVisaBulletin
export interface ChatbotPayloadQa{
    answer: string
    related_topics: string[]
    references?: ChatMessageReference[]
}
export interface ChatPayloadVisaBulletin{
    tables: Record<string, ChatPayloadVisaBulletinTable>
    update_time: string
}
export interface ChatPayloadVisaBulletinTable{
    nationalities: string[]
    dates: {'visa_type': string, 'values': Record<string, string>}[]
}
export interface ChatbotResource{
    actions: ChatbotResourceAction[]
    resource_id: number
    resource_type: string //"case"
    title: string
}
export interface ChatbotResourceAction{
    action_type: "prompt" | "secondary_chat"
    label_text: string
    prompt: string
}

export const ChatbotContext = createContext<ChatbotContextType>({
    followUps: [],
    messages: [],
    sending: false,
    sendMessage(message) {
        throw new Error("unimplement")
    },
    cleanFollowUps(){
        throw new Error("unimplement")
    },
    appendMessage(){
        throw new Error("unimplement")
    }
})