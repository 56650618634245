import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface PublicationReviewDTO{
  id: string
  type: string
  journalConferenceName: string
  startYear:string
  endYear: string
  counts: string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query GetReviews($caseFilingTaskId: ID!) {
    getReviews(caseFilingTaskId: $caseFilingTaskId) {
      id
      type
      journalConferenceName
      startYear
      endYear
      counts
      clientProfileInfoId
      userId
    }
  }

  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getReviews'] as PublicationReviewDTO[]

}

const update = async (taskId: string, request: {created?: PublicationReviewDTO[], updated?: PublicationReviewDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: PublicationReviewDTO) => {
  const operation = `
  mutation CreateReviews($reviewInputs: [ReviewInput!]!, $caseFilingTaskId: ID!) {
    createReviews(reviewInputs: $reviewInputs, caseFilingTaskId: $caseFilingTaskId) {
      id
      type
      journalConferenceName
      startYear
      endYear
      counts
      clientProfileInfoId
      userId
    }
  }
  `
  const variables = {
    "reviewInputs": [{
      "type": request.type,
      "journalConferenceName": request.journalConferenceName,
      "startYear": request.startYear,
      "endYear": request.endYear,
      "counts": request.counts,
    }],
    "caseFilingTaskId": taskId
  }

  return (
    await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables,
        caseFilingTaskId: taskId
      }
  )
  ).data.data['createReviews'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeleteReview($reviewId: ID!) {
    deleteReview(reviewId: $reviewId)
  }
  `
  const variables = {
    reviewId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: PublicationReviewDTO) => {

  const operation = `
  mutation EditReview($reviewInput: ReviewInput!, $reviewId: ID!) {
    editReview(reviewInput: $reviewInput, reviewId: $reviewId) {
      id
      type
      journalConferenceName
      startYear
      endYear
      counts
      clientProfileInfoId
      userId
    }
  }
  `

  const variables = {
    "reviewInput": {
      "type": request.type,
      "journalConferenceName": request.journalConferenceName,
      "startYear": request.startYear,
      "endYear": request.endYear,
      "counts": request.counts,
    },
    "reviewId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}