import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface GraduateDTO{
  academicBackgroundId: string
  endYear: string
  gpa: string
  id: string
  rank: string
  startYear: string
  universityName: string
  departmentName: string
  userId: string
  degreeType: string
}


const update = async (taskId: string, request: {created?: GraduateDTO[], updated?: GraduateDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: GraduateDTO): Promise<GraduateDTO> => {
  const operation = `
  mutation Mutation($graduateBackgroundInputs: [GraduateBackgroundInput!]!, $caseFilingTaskId: ID!) {
  createGraduateBackground(graduateBackgroundInputs: $graduateBackgroundInputs, caseFilingTaskId: $caseFilingTaskId) {
    id
    degreeType
    universityName
    departmentName
    gpa
    rank
    academicBackgroundId
    userId
    startYear
    endYear
  }
}
  `
  const variables = {
    "graduateBackgroundInputs": [{
      "degreeType": request.degreeType,
      "universityName": request.universityName,
      "departmentName": request.departmentName,
      "gpa": request.gpa,
      "rank": request.rank,
      "startYear": request.startYear,
      "endYear": request.endYear,
    }],
    "caseFilingTaskId": taskId
  }

  return (await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables,
        caseFilingTaskId: taskId
      }
  )).data.data['createGraduateBackground'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation DeleteGraduateBackground($graduateId: ID!) {
    deleteGraduateBackground(graduateId: $graduateId)
  }
  `
  const variables = {
    graduateId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: GraduateDTO) => {

  const operation = `
  mutation EditGraduateBackground($graduateInput: GraduateBackgroundInput!, $graduateId: ID!) {
    editGraduateBackground(graduateInput: $graduateInput, graduateId: $graduateId) {
      id
      degreeType
      universityName
      departmentName
      gpa
      rank
      academicBackgroundId
      userId
      startYear
      endYear
    }
  }
  `

  const variables = {
    "graduateInput": {
      "degreeType": request.degreeType,
      "universityName": request.universityName,
      "departmentName": request.departmentName,
      "gpa": request.gpa,
      "rank": request.rank,
      "startYear": request.startYear,
      "endYear": request.endYear,
    },
    "graduateId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    update,
    edit,
    create,
    remove
}