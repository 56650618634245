import { Col, Flex, Row } from "antd"
import { StepForm } from "./FormFillingForm"
import Form from "../components/form/Form"
import styles from "./ReviewClientInformation.module.scss"
import { useFormInstance } from "../components/form/FormContext"
import { useContext, useEffect } from "react"
import FormFillingApi, { ClientInfoAddressDTO, FormFillingTaskDTO } from "../api/FormFilling.api"
import { FormFillingTaskContext } from "./context/FormFillingTaskContext"
import Env from "../../Env"
import { FormFillingFormContext, convertToStep } from "./FormFillingFormContext"


export default () => {

    const formInstance = useFormInstance()
    const {data: fillingForm, setData, setStep} = useContext(FormFillingFormContext)
    const onSubmit = (formData: any) => {
        const case_id = fillingForm?.case_id as string
        const task_id = fillingForm?.id as string

        //数据合并
        let address_not_in_us: ClientInfoAddressDTO = {
            street_address: formData['address_not_in_us.street_address'],
            additional_address: formData['address_not_in_us.additional_address'],
            city: formData['address_not_in_us.city'],
            state: formData['address_not_in_us.state'],
            country: formData['address_not_in_us.country'],
            zipcode: formData['address_not_in_us.zipcode']
        }
        let address_in_us: ClientInfoAddressDTO = {
            street_address: formData['address_in_us.street_address'],
            additional_address: formData['address_in_us.additional_address'],
            city: formData['address_in_us.city'],
            state: formData['address_in_us.state'],
            country: formData['address_in_us.country'],
            zipcode: formData['address_in_us.zipcode']
        }
        formData["address_in_us"] = address_in_us
        formData["address_not_in_us"] = address_not_in_us

        return FormFillingApi.updateClientInfo(case_id, task_id, formData).then(res => {
            Env.DEBUG && console.log("update client info, res: ", res);
            FormFillingApi.confirmClientInfo(case_id, task_id).then(_ => {
                Env.DEBUG && console.log("confirm client info, res: ");
                setData({
                    ...fillingForm as FormFillingTaskDTO,
                    client_info: res,
                    status: "CHOOSE_LAWYER_PROFILE"
                })
                setStep(convertToStep("CHOOSE_LAWYER_PROFILE"))
            })
        })
    }

    useEffect(() => {

        const initial: {[key: string]: any} = fillingForm?.client_info??{}
        const clientInfo = fillingForm?.client_info
        if(clientInfo){
            Env.DEBUG && console.log("clientInfo: ", clientInfo)
            const address_not_in_us = clientInfo.address_not_in_us
            if(address_not_in_us){
                Object.entries(address_not_in_us).forEach(([key, value]) => {
                    initial[`address_not_in_us.${key}`] = value
                })
            }
            const address_in_us = clientInfo.address_in_us
            if(address_in_us){
                Object.entries(address_in_us).forEach(([key, value]) => {
                    initial[`address_in_us.${key}`] = value
                })
            }
        }

        
        formInstance.setInitial(initial)
        
    }, [])

    return (
        <StepForm
            title="Review client’s information"
            subtitle="Please review the client information extracted and make any necessary correction before proceeding."
        >
            <Form.Form
                instance={formInstance}
                onSubmit={onSubmit}
            >
                <Flex vertical gap={64}>
                    <Flex vertical gap={32}>
                        <div className={styles['formgroup__title']}>Personal Information</div>
                        <Row gutter={[46, 0]}>
                            <Col span={12}>
                                <Form.Item label="First name" name="first_name">
                                    <Form.Input placeholder="First name"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Last name" name="last_name">
                                    <Form.Input placeholder="Last name"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Middle name" name="middle_name">
                                    <Form.Input placeholder="Middle name"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Date of birth" name="date_of_birth">
                                    <Form.Input placeholder="Date of birth"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Gender" name="gender">
                                    <Form.Select options={[{label: "Male", value: "M"}, {label: "Female", value: "F"}]}></Form.Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Country of nationality" name="country_of_nationality">
                                    <Form.Input placeholder="Country of nationality"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Country of birth" name="country_of_birth">
                                    <Form.Input placeholder="Country of birth"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="State / Province of birth" name="state_of_birth">
                                    <Form.Input placeholder="Select state/ province of birth"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="City / Town of birth" name="city_of_birth">
                                    <Form.Input placeholder="Enter city / town of birth"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Alien registration number" name="alien_registration_number">
                                    <Form.Input placeholder="Enter your alien registration number"></Form.Input>
                                </Form.Item>
                            </Col>
                        </Row>  
                    </Flex> 
                    <Flex vertical gap={32}>
                        <Flex vertical gap={6}>
                            <div className={styles['formgroup__title']}>Address within US</div>  
                            <div className={styles['formgroup__subtitle']}>Mailing Address or Residence Address</div>  
                        </Flex>
                        <Row gutter={[46, 0]}>
                            <Col span={24}>
                                <Form.Item label="Address line 1(Street address)" name="address_in_us.street_address">
                                    <Form.Input placeholder="Enter the street address of your residence"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Address line 2(Apt, suite, floor etc)" name="address_in_us.additional_address">
                                    <Form.Input placeholder="Apt, suite, etc. (optional)"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="City" name="address_in_us.city">
                                    <Form.Input placeholder="Enter the city of residence address"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="State" name="address_in_us.state">
                                    <Form.Input placeholder="Select the state of residence"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Zip code" name="address_in_us.zipcode">
                                    <Form.Input placeholder="123456"></Form.Input>
                                </Form.Item>
                            </Col>
                        </Row> 
                    </Flex>   
                    <Flex vertical gap={32}>
                        <Flex vertical gap={6}>
                            <div className={styles['formgroup__title']}>Address not within US</div>  
                            <div className={styles['formgroup__subtitle']}>Mailing Address or Residence Address</div>  
                        </Flex>
                        <Row gutter={[46, 0]}>
                            <Col span={24}>
                                <Form.Item label="Address line 1(Street address) " name="address_not_in_us.street_address">
                                    <Form.Input placeholder="Enter the street address of your residence"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Address line 2(Apt, suite, floor etc)" name="address_not_in_us.additional_address">
                                    <Form.Input placeholder="Enter the street address of your residence"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="City" name="address_not_in_us.city">
                                    <Form.Input placeholder="Enter the city of residence address"></Form.Input>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label="Country" name="address_not_in_us.country">
                                    <Form.Input placeholder="Select country"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="State / Province" name="address_not_in_us.state">
                                    <Form.Input placeholder="Select state / province"></Form.Input>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Zip code" name="address_not_in_us.zipcode">
                                    <Form.Input placeholder="123456"></Form.Input>
                                </Form.Item>
                            </Col>
                        </Row> 
                    </Flex>               
                </Flex>
                <Flex className={styles['formgroup__button']} justify="flex-end" style={{paddingTop: 48}}>
                    <Form.Submit width={185}>Continue</Form.Submit>
                </Flex>

            </Form.Form>
        </StepForm>
    )
}