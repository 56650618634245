

import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"


export interface MediaCoverageDTO{
  id: string
  mediaName: string
  year: string
  link:string
  clientProfileInfoId: string
  userId: string
}

const list = async (taskId: string) => {

  const operation = `
  query Query($caseFilingTaskId: ID!) {
  getMediaCoverages(caseFilingTaskId: $caseFilingTaskId) {
    id
    mediaName
    year
    link
    clientProfileInfoId
    userId
  }
}  
  `
  const variables = {
    caseFilingTaskId: taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
  )).data?.['data']?.['getMediaCoverages'] as MediaCoverageDTO[]

}

const update = async (taskId: string, request: {created?: MediaCoverageDTO[], updated?: MediaCoverageDTO[], deleted?: string[]}) => {
  if(request.created){
    for(let item of request.created){
      await create(taskId, item)
    }
  }
  if(request.deleted){
    for(let item of request.deleted){
      await remove(item)
    }
  }
  
  if(request.updated){
    for(let item of request.updated){
      await edit(item)
    }
  }
}

const create = async (taskId: string, request: MediaCoverageDTO) => {
  const operation = `
  mutation CreateMediaCoverages($mediaCoverageInputs: [MediaCoverageInput!]!, $caseFilingTaskId: ID!) {
    createMediaCoverages(mediaCoverageInputs: $mediaCoverageInputs, caseFilingTaskId: $caseFilingTaskId) {
        id
        mediaName
        year
        link
        clientProfileInfoId
        userId
    }
  }
  `
  const variables = {
    "mediaCoverageInputs": [{
      "mediaName": request.mediaName,
      "year": request.year,
      "link": request.link,
    }],
    "caseFilingTaskId": taskId
  }

  return (
    await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables,
        caseFilingTaskId: taskId
      }
  )
  ).data.data['createMediaCoverages'][0]

}

const remove = async (id: string) => {
  const operation = `
  mutation Mutation($mediaCoverageId: ID!) {
    deleteMediaCoverage(mediaCoverageId: $mediaCoverageId)
  }
  `
  const variables = {
    mediaCoverageId: id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const edit = async (request: MediaCoverageDTO) => {

  const operation = `
  mutation EditMediaCoverage($mediaCoverageInput: MediaCoverageInput!, $mediaCoverageId: ID!) {
  editMediaCoverage(mediaCoverageInput: $mediaCoverageInput, mediaCoverageId: $mediaCoverageId) {
    id
    mediaName
    year
    link
    clientProfileInfoId
    userId
  }
}
  `

  const variables = {
    "mediaCoverageInput": {
      "mediaName": request.mediaName,
      "year": request.year,
      "link": request.link,
    },
    "mediaCoverageId": request.id
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

export default {
    list,
    update,
    create,
    edit,
    remove
}