import { SelectProps } from "antd"


import formData from "../assets/data/visa_bulletin_dropdown.json"
import Env from "../../../Env";

interface NationalityOption{
    label: string
    value: string
    types: {[key: string]: string}
}

let nationalities: NationalityOption[] = [];
(() => {
    
    let raw: any[] = formData
    let _form: {[key: string]: NationalityOption} = {}
    for(const record of raw){
        const nationality = record['region']
        const visaType = record['visa_subcategory']
        if(!_form[nationality]){
            _form[nationality] = {label: nationality, value: nationality, types: {}}
        }
        _form[nationality]['types'][visaType] = visaType
    }
    nationalities = Object.entries(_form).sort((a, b) => a[0].localeCompare(b[0])).map(it => it[1])
    Env.DEBUG && console.log("nationalities: ", nationalities)
})();

const visaTypes = (nationality: string) => {
    const types = Object.entries(nationalities?.find(it => it.value === nationality)?.types??{})
    return types.map(it => {return {label: it[1], value: it[0]}})
}


export default {
    nationalities,
    visaTypes
}