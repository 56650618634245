
import { Col, Flex, Row, message } from "antd"
import Screen from "../components/Screen"

import emailSvg from "../login/assets/images/mail-02.svg";
import { useContext, useState } from "react";
import Link from "../login/components/Link";
import Icon from "../components/icon/Icon";
import validators from "../components/form/FormValidators"
import { useNavigate } from "react-router-dom";
import SignupApi from "./Signup.api";
import { RegisterParams as RegisterParams, SignupContext } from "./context/SignupContext";

import styles from "./Singup.module.scss"

const {
    required,
    email,
    password
} = validators


export default () => {

    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const {register} = useContext(SignupContext)

    const resend = () => {

        if(loading) return;
        if(register?.email){
            setLoading(true)
            messageApi.loading({content: "Resending"})
            SignupApi.resendVerifyEmail(register.email).then(() => {
                messageApi.destroy()
                messageApi.success({content: "Resend successful", duration: 3})                
            }).catch(() => messageApi.destroy())
        }
    }


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['signup']}>

                    <Flex align="center" vertical  gap={24}>
                        <div className={styles["signup__icon"]}>
                            <Icon src={emailSvg} size={28}></Icon>
                        </div>
                        <Flex vertical align="center" gap={12}>
                            <div className={styles['signup__title']}>Verify your email</div>
                            <Flex vertical align="center" className={styles['signup__subtitle']}>
                                <div>To use Leazy.ai, click the verification button in the email we sent to {register?.email}. This helps keep your account secure.</div>
                                <div>No email in your inbox or spam folder? <Link onClick={resend}>Let’s resend it</Link>.</div>
                            </Flex>
                        </Flex>
                    </Flex>
                    
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}