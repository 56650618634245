const isDeepEqual = (object1: {[key: string]: any} = {}, object2: {[key: string]: any} = {}) => {

    const objKeys1 = Object.keys(object1);
    const objKeys2 = Object.keys(object2);
  
    if (objKeys1.length !== objKeys2.length) return false;
  
    for (var key of objKeys1) {
      const value1 = object1[key];
      const value2 = object2[key];
  
      const isObjects = isObject(value1) && isObject(value2);
  
      if ((isObjects && !isDeepEqual(value1, value2)) ||
        (!isObjects && value1 !== value2)
      ) {
        return false;
      }
    }
    return true;
  };
  
  const isObject = (object: {[key: string]: any} = {}) => {
    return object != null && typeof object === "object";
  };

  const isEmpty = (object: {[key: string]: any} = {}) => {
    return Object.keys(object).length == 0
  }

export default {
    isDeepEqual,
    isEmpty
}
