import { ConfigProvider, Flex, Row, Upload, UploadProps } from "antd"
import styles from "./UploadSourceDocuments.module.scss"
import Icon from "../components/icon/Icon"
import logOutSvg from "./assets/images/log-out-04.svg"
import { CSSProperties, PropsWithChildren, createContext, useContext, useEffect, useState } from "react"
import Form, {  } from "../components/form/Form"
import { FormContext, FormItemContext, useFormInstance } from "../components/form/FormContext"
import FormFillingApi, { DocumentType, FormFillingTaskDTO, ProofDoc, TaskStatus } from "../api/FormFilling.api"
import { ClientCaseContext } from "../clientmanagement/context/ClientCaseContext"
import Env from "../../Env"
import uploadSvg from "./assets/images/upload-cloud-02.svg"
import { DraggerProps } from "antd/es/upload"
import axios from "axios"
import fileSvg from "./assets/images/file-04.svg"
import trashSvg from "./assets/images/trash-01.svg"
import { format } from "../libs/bytes"
import checkSvg from "./assets/images/check.svg"
import fileUploadedSvg from "./assets/images/file-04-uploaded.svg"
import { toHumanDateTime } from "../libs/datetime"
import { FormFillingFormContext, convertToStep} from "./FormFillingFormContext"
import { StepForm } from "./FormFillingForm"


export default () => {

    const formInstance = useFormInstance()
    const {data, setData, setStep} = useContext(FormFillingFormContext)
    const {
        values
    } = formInstance

    const doSubmit = async (form: any) => {
        //TODO chishijie do some work
        // return FormFillingApi.create(clientCase?.case_id as string, form).then((res) => {
        //     Env.DEBUG && console.log("create formFillingTask: ", res);
        // })
        const caseId = data?.case_id as string
        const taskId = data?.id as string
        // FormFillingApi.updateDocument(caseId, taskId, )
        return FormFillingApi.parseDocs(caseId, taskId).then(res => {
            Env.DEBUG && console.log("parse docs result: ", res)       
            setData({
                ...data as FormFillingTaskDTO,
                status: "REVIEW_CLIENT_INFO",
                client_info: res
            }) 
            setStep(convertToStep("REVIEW_CLIENT_INFO"))
        })
    }

    useEffect(() => {
        if(data){
            let _data: {[key: string]: any} = data
            if(data.proof_docs){
                data.proof_docs.forEach(doc => {
                    _data[doc.category] = doc
                })
            }
            formInstance.setInitial(_data)
        }
    }, [data])

    const itemLabelRender = (title: string, subTitle: string) => {
        return (
            <Flex vertical gap={4}>
                <div className={styles['upload-source-documents__item__title']}>{title}</div>
                <div className={styles['upload-source-documents__item__subtitle']}>{subTitle}</div>
            </Flex>
        )
    }

    const hasFileUpload = () => {
        return values['ID'] || values['BIRTH_CERTIFICATE'] || values['VISA'] || values['ADMISSION']
    }


    return (
        <StepForm
            title={"Upload source documents"}
            subtitle={"Please upload the following documents required for the final case filing."}
        >
            <Form.Form
                instance={formInstance}
                onSubmit={doSubmit}
                showError={false}
            >
                <Form.Item 
                    label={
                        itemLabelRender(
                            "1. Government-issued identity document", 
                            "Government-issued identity document with photograph (Not a Passport), can be driver license, state ID"
                        )
                    }
                    name="ID" 
                >
                    <DocumentUpload type="ID" />
                </Form.Item>
                <Form.Item 
                    label={
                        itemLabelRender(
                            "2. Birth certificate", 
                            "An official document issued by your government that records your birth details including date, place, and parentage."
                        )
                    }
                    name="BIRTH_CERTIFICATE" 
                >
                    <DocumentUpload type="BIRTH_CERTIFICATE"/>
                </Form.Item>
                <Form.Item 
                    label={
                        itemLabelRender(
                            "3. Passport page with nonimmigrant visa", 
                            "A copy of the passport page that contains your current nonimmigrant visa, showing your eligibility to enter or stay."
                        )
                    }
                    name="VISA" 
                >
                    <DocumentUpload type="VISA"/>
                </Form.Item>
                <Form.Item 
                    label={
                        itemLabelRender(
                            "3. Form I-94, or Passport page with admission/parole stamp", 
                            "Evidence of your most recent entry into the country, either the I-94 form or the stamp in your passport."
                        )
                    }
                    name="ADMISSION"
                >
                    <DocumentUpload type="ADMISSION"/>
                </Form.Item>
                <Form.Item nowrap label="" name="">
                    <Flex justify="flex-end">
                        <Form.Submit width={185} disabled={!hasFileUpload()}>Continue</Form.Submit>
                    </Flex>
                </Form.Item>
            </Form.Form>
        </StepForm>
    )
}

interface UploadingFile{
    precent: number
    filename: string
    size: string
    status: "done"|"uploading"|""
}

const DocumentUpload = (props: {type: DocumentType}) => {

    const {
        Dragger
    } = Upload

    const {client} = useContext(ClientCaseContext)
    const {name} = useContext(FormItemContext)
    const {values, setValues} = useContext(FormContext)
    const [fileId, setFileId] = useState<string>()
    const {data: fillingForm} = useContext(FormFillingFormContext)
    const [uploadingFile, setUploadingFile] = useState<UploadingFile>()
    

    const customRequest: DraggerProps['customRequest'] = ({
            action,
            data,
            file,
            filename,
            headers,
            onError,
            onProgress,
            onSuccess,
            withCredentials
        }) => {

            const _file = file as File
            const name = _file.name
            const type = _file.type

            setUploadingFile({
                filename: _file.name,
                precent: 0,
                size: format(_file.size),
                status: ""
            })

            return FormFillingApi.getUploadToken(client?.client_id as string, {
                name, 
                type,
                metadata: {}
            }).then(token => {
                Env.DEBUG && console.log("getToken: ", token)
                const {
                    file_id,
                    upload_args
                } = token
                const {
                url,
                fields
                } = upload_args
                
                setFileId(file_id)
                const formData = new FormData()
                Object.entries(fields).forEach(([key, value]) => {
                    formData.append(key, value)
                })
                formData.append("file", _file)
                
                const config= {
                    "headers": {
                        "content-type": 'multipart/form-data;'
                    },
                }
                return axios.post(url, formData, {...config, onUploadProgress: (e) => onProgress?.({...e})}).then((res: any) => {
                    Env.DEBUG && console.log("uploadSuccess: ", res)
                    onSuccess?.(file_id)
                }).catch((err: Error) => {
                    console.error(err)
                    onError?.(err)
                })

            })
    }

    const onChange: DraggerProps['onChange'] = (uploadFile) => {
        Env.DEBUG && console.log("onChange", uploadFile)
        if(uploadFile.event){
            const percent = Math.floor(parseFloat((uploadFile.event as any)?.progress??0) * 100)
            setUploadingFile({
                filename: uploadFile.file.name as string,
                precent: Math.min(percent, 99),
                size: format(uploadFile.file.size??0),
                status: "uploading"
            })
        }
        if(uploadFile.file.status == "done"){
            Env.DEBUG && console.log("onUploaded, fileId: ", fileId)
            fillingForm && fileId && FormFillingApi.updateDocument(
                fillingForm.case_id, 
                fillingForm.id, 
                {file_category: props.type, file_id: fileId}
            ).then((res) => {
                Env.DEBUG && console.log("upload document, res: ", res)
                setValues(name, res)
                setUploadingFile(undefined)
            })
        }
    }

    const onDelete = () => {
        const docId = (values[name] as ProofDoc)?.id
        setValues(name, undefined)
        Env.DEBUG && console.log("docId: ", docId, "fillingForm: ", fillingForm);
        fillingForm && docId && FormFillingApi.deleteDocument(
            fillingForm.case_id, 
            fillingForm.id, 
            docId
        ).then(() => {
            Env.DEBUG && console.log("delete document, docId: ", docId)
        })
    }

    const draggerRender = () => {
        return <ConfigProvider
                theme={{
                    token:{
                        colorFillAlter: "#fff",
                        padding: 0,
                    }
                }}
            >
                <Dragger 
                    style={{border: 0}}
                    multiple={false}
                    customRequest={customRequest}
                    onChange={onChange}
                >
                    <Flex vertical gap={12} align="center" className={styles['document_upload__dragger']}>
                        <Icon src={uploadSvg} size={40} padding={0}></Icon>
                        <Flex vertical gap={4} align="center">
                            <Flex gap={4} align="center"> 
                                <a className={styles['document_upload__dragger__a']}>Click to upload</a>
                                or drag and drop
                            </Flex>
                            <div className={styles['document_upload__dragger__tips']}>PDF, DOC, DOCX or PNG (max. 10mb)</div>
                        </Flex>
                    </Flex>
                </Dragger>
            </ConfigProvider>

    }

    const uploadingRender = () => {
        return (
            <div className={styles["document-upload__uploading__float"]}>
                <Flex gap={16} align="flex-start" className={styles["document-upload__uploading"]}>
                    <Icon src={fileSvg} size={28}></Icon>
                    <Flex vertical gap={4} style={{flex: 1}}>
                        <Flex vertical>
                            <Flex justify="space-between" align="center">
                                <div className={styles['document-upload__uploading__filename']}>{uploadingFile?.filename}</div>
                                <Icon src={uploadingFile?.status !== "done" ? trashSvg: checkSvg} size={20} ></Icon>
                            </Flex>
                            {/* <div className={styles['document-upload__uploading__filesize']}>{uploadingFile?.size}</div> */}
                        </Flex>
                        <Progress percent={uploadingFile?.precent??0}/>
                    </Flex>
                </Flex>
            </div>
        )        
    }

    const uploadedRender = () => {

        if(values[name]){
            const doc = values[name]
            if(doc){
                return (
                    <div className={styles["document-upload__uploaded__float"]}>
                        <Flex gap={16} align="flex-start" className={styles["document-upload__uploaded"]}>
                            <Icon src={fileUploadedSvg} size={28} hoverable ></Icon>
                            <Flex gap={16} style={{flex: 1}}>
                                <Flex align="center" justify="space-between" style={{flex: 1}}>
                                    <Flex vertical>
                                        <div className={styles['document-upload__uploaded__filename']}>{doc.name}</div>
                                        <div className={styles['document-upload__uploaded__filesize']}>{format(doc.size??0)}</div>
                                    </Flex>
                                    <div className={styles['document-upload__uploaded__updatedAt']}>Last update: {toHumanDateTime(doc.updated_at)}</div>
                                </Flex>
                                <Icon src={trashSvg} size={20} padding={8} hoverable onClick={onDelete}></Icon>
                            </Flex>
                        </Flex>
                    </div>
                )

            }
            return <></>
        }

    }
    
    const uploaded = () => {
        if(values[name]){
            return true
        }
    }

    return (
        <div className={[styles['document-upload'], (values[name]||uploadingFile)?styles['document-upload_fill']:''].join(" ")}>
            {
                draggerRender()
            }
            {
                uploadingFile &&
                uploadingRender()
            }
            {
                uploaded() &&
                uploadedRender()
            }
        </div>
    )
    
}

const Progress = (props: {percent: number}) => {

    const style:CSSProperties = {
        maxWidth: `calc(100% * ${props.percent * 0.01})`
    }

    return (
        <Flex gap={12} className="progress" align="center">
            <div className={styles["progress__bar"]}>
                <div 
                    className={styles["progress__bar__scroller"]}
                    style={style}
                ></div>
            </div>
            <div className={styles["progress__label"]}>{props.percent}%</div>
        </Flex>
    )
}