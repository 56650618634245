
import { Col, Flex, Row } from "antd";
import styles from "./ClientProfileForm.module.scss";
import Form from "../components/form/Form";
import Button from "../components/Button";
import FormValidators from "../components/form/FormValidators";
import { useFormInstance } from "../components/form/FormContext";
import { ClientDTO } from "../api/Client.api";

export interface ClientProfileFormProps{
    onConfirm: (form: any) => Promise<void> | void
    onCancel: () => void
    initial?: ClientDTO
    type: "create"|"edit"
}

export default (props: ClientProfileFormProps) => {

    const formInstance = useFormInstance()

    //props.onConfirm
    return (
        <div className={styles["form"]} style={{margin: "auto"}}>
            <Form.Form 
                    instance={formInstance} 
                    onSubmit={props.onConfirm}
                    initial={props.initial}
                >
                    <Flex vertical gap={24}>
                        <div className={styles["form__header"]}>
                            {props.type == "create"?"Create a New Client Profile":"Edit Profile"}
                        </div>
                        <div className={styles["form__body"]}>
                            <Row gutter={[16, 8]}>
                                    <Col span={12}>
                                        <Form.Item 
                                            label="Client first name" 
                                            name="first_name" 
                                            rules={[{validate: FormValidators.required, msg: "First name"}]}
                                            required
                                        >
                                            <Form.Input placeholder="First name"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item 
                                            label="Client last name" 
                                            name="last_name"
                                            rules={[{validate: FormValidators.required, msg: "Last name"}]}
                                            required
                                        >
                                            <Form.Input placeholder="Last name"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item 
                                            label="Client email" 
                                            name="email_address"
                                            rules={[{validate: FormValidators.required, msg: "example@gmail.com"}, {validate: FormValidators.email, msg: "example@gmail.com"}]}
                                            required
                                        >
                                            <Form.Input placeholder="example@gmail.com"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                        </div>
                    </Flex>
                    <Flex gap={12} justify="stretch" className={styles["form__footer"]}>
                        <Button.WhiteBg width={"100%"} padding={[10, 18]} onClick={props.onCancel}>Cancel</Button.WhiteBg>
                        <Form.Submit width={"100%"} padding={[10, 18]}>Save</Form.Submit>
                    </Flex>
                </Form.Form>
                
            </div>   
        
    )
    

}