import { CSSProperties, PropsWithChildren, useEffect, useState } from "react"

import styles from "./Button.module.scss";
import Env from "../../Env";
import { Flex } from "antd";
import LoadingIcon from "./icon/LoadingIcon";

export type ButtonProps = {
    width?: number|string
    padding?: number[]
    disabled?: boolean
    scheme?: "blackbg" | 'whitebg' | 'primary' | 'redBg' | 'transparentBg'
    focus?: boolean
    mini?: boolean
    loading?: boolean
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const BlackBg = (props: PropsWithChildren<ButtonProps>) => {
    return <_Button {...props} scheme="blackbg"></_Button>
}

const WhiteBg = (props: PropsWithChildren<ButtonProps>) => {
    return <_Button {...props} scheme="whitebg"></_Button>
}

const Primary = (props: PropsWithChildren<ButtonProps>) => {
    return <_Button {...props} scheme="primary"></_Button>
}

const RedBg = (props: PropsWithChildren<ButtonProps>) => {
    return <_Button {...props} scheme="redBg"></_Button>
}

const TransparentBg = (props: PropsWithChildren<ButtonProps>) => {
    return <_Button {...props} scheme="transparentBg"></_Button>
}


const _Button = (props: PropsWithChildren<ButtonProps>) => {

    const {
        scheme = "primary",
        focus,
        mini
    } = props

    const [disabled, setDisabled] = useState<boolean>(() => false)

    const [customStyles] = useState<CSSProperties>(() => {

        const _styles: CSSProperties = {...props.style}
        if(props.width){
            _styles['width'] = props.width
        }
        if(props.padding){
            _styles['padding'] = props.padding.map(it => `${it}px`).join(" ")
        }

        return _styles
    })

    useEffect(() => {
        // Env.DEBUG && console.log("button disabled: ", props.disabled);
        setDisabled(props.disabled??false)
    }, [props.disabled])

    return (
        <button 
            className={[
                styles["button"], 
                mini?styles[`button_mini`]:'', 
                styles[`button_${scheme}`], 
                focus?styles[`button_${scheme}_focus`]:'', 
                disabled?styles[`button_${scheme}_disabled`]:""
            ].join(" ")} 
            {...props}
            style={customStyles}
            onSubmit={(e) => {
                props.onSubmit?.(e)
                e.stopPropagation()
                e.preventDefault()
            }}
            onClick={(e) => {
                Env.DEBUG && console.log("button on click")
                props.onClick?.(e)
                e.stopPropagation()
                e.preventDefault()
            }}
        >
            {
                props.loading
                ?
                <Flex gap={8} align="center" justify="center">
                    <LoadingIcon/>
                    Loading
                </Flex>
                :
                props.children
            }
        </button>
    )
}

export default {
    BlackBg,
    WhiteBg,
    Primary,
    RedBg,
    TransparentBg
}