import React, { useState, useEffect } from "react";
import img1 from "./assets/images/icon1.png";
import img2 from "./assets/images/icon2.png";
import img3 from "./assets/images/icon3.png";
import img4 from "./assets/images/icon4.png";

const LoadingAnimation = () => {
  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const imgs = [img1, img2, img3, img4];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIdx((prevIdx) => (prevIdx + 1) % imgs.length);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [imgs.length]);

  return (
    <div>
      <img src={imgs[currentImageIdx]} alt="loading" style={{width: 56, height: 56}} />
    </div>
  );
};

export default LoadingAnimation;
