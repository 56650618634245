import { PropsWithChildren, useContext, useEffect, useState } from "react"
import { UserContext } from "./context/UserContext"
import loginApi, { UserDTO } from "../api/login.api"
import useToken from "../libs/useToken"
import { Navigate, useNavigate, useNavigation } from "react-router-dom"


export default (props: PropsWithChildren<{}>) => {

    const {getToken} = useToken()
    const [user, setUser] = useState<UserDTO | undefined>()
    const navigate = useNavigate()

    // const setUser = (user: UserDTO | undefined) => {
    //     setUser(user)
    // }

    useEffect(() => {
        getToken() && loginApi.me().then(setUser).catch((e) => {
            navigate("/login")
        })
    }, [])

    if(getToken()){
        return (
            <UserContext.Provider value={{user, setUser}}>
                {props.children}
            </UserContext.Provider>
        )
    }

    return (<Navigate to={"/login"}></Navigate>)

    
    
}