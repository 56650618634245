import { Flex, Modal } from "antd"
import { PropsWithChildren, useEffect, useRef, useState } from "react"

import "./userModel.scss";
import closePng from "./assets/images/close@3x.png"

export default (props: PropsWithChildren<Parameters<typeof Modal>[0]>) => {

    const [open, setOpen] = useState<boolean>(() => false)

    useEffect(() => {
        let keydownHandle = (e: KeyboardEvent) => {
            if(e.key === "Escape"){
                close()
                e.stopPropagation()
                e.preventDefault()
            }
        }
        document.addEventListener("keydown", keydownHandle)
        return () => {
            document.removeEventListener("keydown", keydownHandle)
        }
    }, [])


    const show = () => {
        setOpen(true)
    }

    const close = () => {
        setOpen(false)
    }


    const render = (
        <div 
            className={["rb__modal-wrapper", open?"rb__modal-wrapper_visible":""].join(" ")} >
            <div className={["rb__modal__mask", open?"rb__modal__mask_visible": ""].join(" ")}></div>
            <div className="rb__modal">
                <Flex align="center" justify="flex-end" className="rb__modal__header">
                    <img src={closePng} className="rb__modal__header__close" onClick={close}></img>
                </Flex>
                <div className="rb__modal__body">
                    {props.children}
                </div>
            </div>
        </div>
    )

    return {
        render,
        show,
        close,
        opening: open
    }
}


export const RBConfirm = (props: PropsWithChildren<{show: boolean}>) => {

    const [open, setOpen] = useState<boolean>(() => false)

    useEffect(() => {
        setOpen(props.show)
    }, [props.show])

    return (
        <div 
            className={["rb__confirm-wrapper", open?"rb__confirm-wrapper_visible":""].join(" ")} >
            <div className={["rb__confirm__mask", open?"rb__confirm__mask_visible": ""].join(" ")}></div>
            <Flex align="center" justify="center" className="rb__confirm">
                {props.children}
            </Flex>
        </div>
    )

}