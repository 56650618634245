import axios from "axios"
import { HttpResponse, checkCode } from "../../libs/http"
import { GraduateDTO } from "./GraduatesGraphsql.api"

export interface UndergraduateDTO{
    academicBackgroundId: string
    id: string
    endYear: string
    gpa: string
    rank: string
    startYear: string
    universityName: string
    userId: string
}


export interface AcademicBackgroundDTO{
  id: string
  highestDegree: string
  graduate: GraduateDTO[]
  undergraduate: UndergraduateDTO[]
}



const academicBackground = async (taskId: string) => {

    const query = `
    query Query(
        $caseFilingTaskId: ID!
        $getGraduateBackgroundsCaseFilingTaskId2: ID!
        $getAcademicBackgroundCaseFilingTaskId2: ID!
      ) {
        getUndergraduateBackgrounds(caseFilingTaskId: $caseFilingTaskId) {
          academicBackgroundId
          endYear
          gpa
          id
          rank
          startYear
          universityName
          userId
        }
        getGraduateBackgrounds(
          caseFilingTaskId: $getGraduateBackgroundsCaseFilingTaskId2
        ) {
          academicBackgroundId
          degreeType
          departmentName
          endYear
          gpa
          id
          rank
          startYear
          universityName
          userId
        }
        getAcademicBackground(
          caseFilingTaskId: $getAcademicBackgroundCaseFilingTaskId2
        ) {
          id
          highestDegree
        }
      }
    `
    const variables = {
        caseFilingTaskId: taskId,
        getGraduateBackgroundsCaseFilingTaskId2: taskId,
        getAcademicBackgroundCaseFilingTaskId2: taskId
    }

    const res = (await axios.post(
        `/api/cases/case_filing/graphql`,
        {
            query,
            variables
        }
    )).data as HttpResponse<any>

    const data = res.data
    const undergraduates = data['getUndergraduateBackgrounds'] as UndergraduateDTO[]
    const graduates = data['getGraduateBackgrounds'] as GraduateDTO[]
    const background = data['getAcademicBackground'] as AcademicBackgroundDTO
    return {
        undergraduates,
        graduates,
        background
    }
}

const updateUndergraduates = async (taskId: string, request: {highestDegree: string, created?: UndergraduateDTO[], updated?: UndergraduateDTO[], deleted?: string[]}) => {
    if(request.created){
      for(let item of request.created){
        await createUndergraduate(taskId, item)
      }
    }
    if(request.deleted){
      for(let item of request.deleted){
        await deleteUndergraduate(item)
      }
    }
    
    if(request.updated){
      for(let item of request.updated){
        await editUndergraduate(item)
      }
    }

    //Update highestDegree
    await editHighestDegree(taskId, request.highestDegree)
}

const editHighestDegree = async (taskId: string, highestDegree: string) => {

  const operation = `
  mutation Mutation($academicBackgroundInput: AcademicBackgroundInput!, $caseFilingTaskId: ID!) {
    editAcademicBackground(academicBackgroundInput: $academicBackgroundInput, caseFilingTaskId: $caseFilingTaskId) {
      highestDegree
    }
  }
  `
  const variables = {  
    "academicBackgroundInput": {
      highestDegree
    },
    "caseFilingTaskId": taskId
  }

  await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables,
      caseFilingTaskId: taskId
    }
)
}

const createUndergraduate = async (taskId: string, request: UndergraduateDTO): Promise<UndergraduateDTO> => {
  const operation = `
  mutation CreateUndergraduateBackground($undergraduateBackgroundInputs: [UndergraduateBackgroundInput!]!, $caseFilingTaskId: ID!) {
    createUndergraduateBackground(undergraduateBackgroundInputs: $undergraduateBackgroundInputs, caseFilingTaskId: $caseFilingTaskId) {
      id
      endYear
      gpa
      rank
      startYear
      universityName
    }
  }
  `
  const variables = {
    "undergraduateBackgroundInputs": [{
      "endYear": request.endYear,
      "gpa": request.gpa,
      "rank": request.rank,
      "startYear": request.startYear,
      "universityName": request.universityName 
    }],
    "caseFilingTaskId": taskId
  }

  return (await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables,
      caseFilingTaskId: taskId
    }
)).data.data['createUndergraduateBackground'][0]

}

const deleteUndergraduate = async (undergraduateId: string) => {
  const operation = `
  mutation DeleteUndergraduateBackground($undergraduateId: ID!) {
    deleteUndergraduateBackground(undergraduateId: $undergraduateId)
  }
  `
  const variables = {
    "undergraduateId": undergraduateId
  }

  await axios.post(
      `/api/cases/case_filing/graphql`,
      {
        query: operation,
        variables
      }
  )

}

const editUndergraduate = async (request: UndergraduateDTO) => {

  const operation = `
  mutation EditUndergraduateBackground(
    $undergraduateInput: UndergraduateBackgroundInput!
    $undergraduateId: ID!
  ) {
    editUndergraduateBackground(
      undergraduateInput: $undergraduateInput
      undergraduateId: $undergraduateId
    ) {
      id
      endYear
      gpa
      rank
      startYear
      universityName
    }
  }
  `

  const variables = {
    "undergraduateInput": {
          "endYear": request.endYear,
          "gpa": request.gpa,
          "rank": request.rank,
          "startYear": request.startYear,
          "universityName": request.universityName  
          },
    "undergraduateId": request.id
  }

  await axios.post(
    `/api/cases/case_filing/graphql`,
    {
      query: operation,
      variables
    }
)

}



export default {
    academicBackground,
    updateUndergraduates,
    createUndergraduate,
    deleteUndergraduate,
    editUndergraduate
}