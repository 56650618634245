import { Flex } from "antd";
import { ClientDTO } from "../../api/Client.api";

import styles from "./TableClientName.module.scss";

export default (props: {client: ClientDTO}) => {

    const {
        client
    } = props

    const getBannerText = () => {
        return client.first_name.slice(0, 1).toUpperCase() + client.last_name.slice(0, 1).toUpperCase()
    }



    return (
        <Flex align="center" gap={12}>
            <div className={styles['table__fullname__banner']}>{getBannerText()}</div>
            <div className={styles['table__fullname__label']}>{client.first_name} {client.last_name}</div>
        </Flex>
    )

}