import { CSSProperties, PropsWithChildren } from "react"

import styles from "./Dropdown.module.scss";
import { Flex } from "antd";

export interface DropdownProps{
    width?: number|string
}

export interface DropdownItemProps{
    width?: number|string
    onClick?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>['onClick']
}

export default (props: PropsWithChildren<DropdownProps>) => {

    const _styles: CSSProperties = {
        width: props.width
    }

    return (
        <div className={styles['dropdown']} style={_styles}>
            <Flex vertical gap={8}>
                {props.children}
            </Flex>
        </div>
    )

}


export const DropdownItem = (props: PropsWithChildren<DropdownItemProps>) => {

    const _styles: CSSProperties = {
        width: props.width
    }

    return (
        <div className={styles['dropdown__item']} style={_styles} onClick={props.onClick}>
            <Flex gap={8}>{props.children}</Flex>
        </div>
    )

}