import dayjs from "dayjs"
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);


export const toHumanDate = (dt: string) => {
    return dayjs(dt).format("ll")
}

export const toHumanDateTime = (dt: string) => {
    return dayjs(dt).format("lll")
}