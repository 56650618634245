import { Flex } from "antd"
import { StepForm } from "../CaseFillingForm"
import styles from "./EssentialSteps.module.scss"
import { useContext } from "react"
import { CaseFilingSteps, CaseFillingFormContext } from "../context/CaseFillingFormContext"
import Button from "../../components/Button"


const steps = [
    {
        step: 1,
        title: "Evidence Analysis",
        subTitle: "After you complete your client's background information input, we will help identify their strengths as evidences to craft personalized recommendation and petition letters effectively.",
        gotoStep: 1,
    },
    {
        step: 2,
        title: "Make Decision on Case Type",
        subTitle: "After reviewing the evidence we have collected, please decide on the case type (EB1A or NIW). "
    },
    {
        step: 3,
        title: "Recommendation Letter",
        subTitle: "We will identify potential recommenders for your client. You can then select some of them, and we will also help in generating a tailored recommendation letter."
    },
    {
        step: 4,
        title: "Generate Petition Letter",
        subTitle: "In this section, you can review the evidence we have collected for your client. Based on this evidence and the signed recommendation letters you provided, we will help craft a petition letter."
    }
]


export default () => {

    const {setStep} = useContext(CaseFillingFormContext)
    

    const gotoStep = () => {
        setStep(CaseFilingSteps.UploadResume)
    }


    return (
        <StepForm
            title="Essential steps"
            subtitle="Please carefully read the information below to ensure you understand each step. If you have any questions, don't hesitate to contact us. You can reach us by sending an email to info@roundblock.io."
        >
            <Flex vertical gap={48}>
                <Flex vertical gap={16} className="essential-steps">
                    {
                        steps.map((it, idx) => <Step key={idx} idx={idx}/>)
                    }
                </Flex>
                <Flex justify="flex-end">
                    <Button.Primary width={185} onClick={gotoStep}>Continue</Button.Primary>
                </Flex>
            </Flex>
        </StepForm>
    )
}

const Step = (props: {idx: number}) => {

    const {setStep} = useContext(CaseFillingFormContext)
    const stepData = steps[props.idx]
    

    return (
        <Flex vertical className={styles['essential-steps__item']} gap={8} >
            <Flex vertical gap={4}>
                <div className={styles['essential-steps__item__idx']}>
                    Step {stepData.step}
                </div>
                <div className={styles['essential-steps__item__title']}>
                    {stepData.title}
                </div>
            </Flex>
            <div className={styles['essential-steps__item__subtitle']}>
                {stepData.subTitle}
            </div>
        </Flex>
    )
}