import { PropsWithChildren } from "react"

import styles from "./Link.module.scss";

export type LinkProps = {

} & React.DetailedHTMLProps<React.LinkHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>

export default (props: LinkProps) => {

    const _props = {...props, children: undefined}

    //{props.children}
    return (
        <a {..._props} className={[styles['link'], props.className].join(" ")}>{props.children}</a>
    )
}