
import { Col, Flex, Row, message } from "antd"
import Screen from "../components/Screen"

import { useContext, useEffect, useState } from "react";
import Icon from "../components/icon/Icon";
import { useNavigate } from "react-router-dom";
import { RegisterParams as RegisterParams, SignupContext } from "./context/SignupContext";

import styles from "./Singup.module.scss";
import key01Svg from "../login/assets/images/key-01.svg";


export default () => {

    const [messageApi, messageRender] = message.useMessage()
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const {register} = useContext(SignupContext)

    useEffect(() => {
        const token = new URL(window.location.href).searchParams.get("token")
        if(!token) throw new Error("token is empty")
    }, [])


    return (
        <Screen>
            <Flex justify="center">
                <Flex vertical gap={32} className={styles['signup']}>

                    <Flex align="center" vertical gap={24}>
                        <div className={styles["signup__icon"]}>
                            <Icon src={key01Svg} size={28}></Icon>
                        </div>
                        <Flex vertical align="center" gap={12}>
                            <div className={styles['signup__title']}>Added to the Queue!</div>
                            <div className={styles['signup__subtitle']}>
                                You are now on our waitlist. We’ll reach out within the next 24 hours regarding your access to our product. Please keep an eye on your email. Thank you!
                            </div>
                        </Flex>
                    </Flex>
                    
                </Flex>
            </Flex>
            {messageRender}
        </Screen>
    )


}