
import axios from "axios";
import { HttpResponse, checkCode } from "../libs/http";


export interface GoogleLoginRequest{
    google_token: string
}
export type LoginRequest = GoogleLoginRequest
export interface UserDTO{
    email: string
    first_name: string
    last_name: string
    user_id: string
} 

/**
 * api: /api/account/login
 * @returns
 */
export const login = async (params: LoginRequest): Promise<HttpResponse<UserDTO>> => {
  const defaultParams = {
    email: "",
    password: "",
    google_token: "",
    remember_me: 0,
  };
  params = { ...defaultParams, ...params };
  const response = (await axios.post(`/api/account/login`, params)).data as HttpResponse<UserDTO>;
  // checkCode(response)
  return response;
};

/**
 * api: /api/account/logout
 * @returns {Promise}
 */
export const logout = async () => {
  const response = await axios.post(`/api/account/logout`, {
    withCredentials: true,
  });
  return response.data;
};

/**
 * 
 * @returns 
 */
export const me = async () => {
  const axiosRes = (await axios.get(`/api/users/me`))
  if(axiosRes.status !== 200){
    throw new Error("" + axiosRes.status)
  }
  const response = axiosRes.data as HttpResponse<UserDTO>;
  checkCode(response)
  return response.data;

}

export default {
  login,
  logout,
  me
}